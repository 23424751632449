import './HeaderBanner.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholderImg from '../../assets/media_banner.jpg';



interface HeaderProps {
    image?: string,
    title: string,
}

export const HeaderBanner = (props: HeaderProps) => {
    return <>
        <div className="my-cnt" style={{'border': 'none'}}>
            <LazyLoadImage 
                src={props.image} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                // width={'100%'} height={'350px'}
            />
            <div className='o-head'>
                <h3>{props.title}</h3>
            </div>
        </div> 
    </>;
}