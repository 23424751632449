import { ChangeEvent, useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getProjMultimediaById } from '../../lib/FindHelpers';
import { ProjectTypeFilter } from '../../components/filters/ProjectTypeFilter';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import ReactPaginate from 'react-paginate';
import ProjectItems from '../../components/pagination/ProjectItems';
import NoDataNotice from '../../components/NoDataNotice';

const CategoryProjects = () => {
    const {id} = useParams();
    const [category, setCategory] = useState<Category>();
    const [projects, setProjects] = useState<Project[]>([]);
    // const [projectDets, setProjectDets] = useState<ProjectWithImage[]>([]);
    const [projectTypes, setProjectTypes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const fetchCategories = () => {
            const requests = [
                `Project/category/${id}`,
                `Category/${id}`,
                `ProjectMultimedia/all`,
            ].map(url => http.get(url));

            axios.all(requests)
                .then(
                    axios.spread((projs, cat, pmulti) => {
                        let pTypes: string[] = []
                        projs.data.map((e: Project, x:number) => {                            
                            if (! (pTypes.includes(e.projectType)) ) {
                                pTypes.push(e.projectType)
                            }
                        })
                        setProjects(projs.data);
                        setProjectTypes(pTypes)
                        setCategory(cat.data)
                        
                        setIsLoading(false)
                    })
                )
                .catch(err => console.log(err))

        }
        fetchCategories();
    }, [])

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = projects.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(projects.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % projects.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <ProjectItems currentItems={currentItems}/>
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;

        let data: {'categories': string[],  'projectTypes'?: string[], 'searchColumns': string[]} = {'categories': [category?.name!], 'searchColumns': ['category']}
        if (selectedOption !== '') {
            data['projectTypes'] = [selectedOption]
            data.searchColumns.push('projecttype')
        }
        http.post(`Search/project/multiple_params`, data)
            .then((res: any) =>  {
                setProjects(res.data);
            })
            .catch((error:any) => {console.log(error);});
    };

    return (
        <div>
            <div>&nbsp;</div>
            <ProjectTypeFilter projTypes={projectTypes} handler={handleSelectChange} />
            <div className="row" style={{padding: '20px 0px'}}>

                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>

                    {projects.length > 0 ? (
                        <PaginatedItems itemsPerPage={20} />                        
                    ): (<><NoDataNotice item='Projects'/></>)}
                </>)}
            </div>
        </div>
    );
}
export default CategoryProjects;