import { useEffect, useState } from 'react';
// import '../components/UtilStyles.css';
import './RegionAll.css';
import http from '../../lib/Http';
import axios from 'axios';
import LoadingNotice from '../../components/LoadingNotice';

const SingleCard = ({item}:{item:any}) => {
    return (
        <div className="col-md-4" style={{ padding: '15px 15px'}}>
            <a href={`region/${item.regionId}`}>
                <div className='reg-content' style={{border: '2px #444444 solid'}}>
                    <div className="reg-content-overlay"></div>
                    <img src={item.thumbnailUrl} alt='...' className='reg-content-image'/>
                    <div className='text-ctnr' style={{padding: '5px', backgroundColor: '#444444 !important' }}>
                        <h3 className="reg-title font-wc-semi" style={{textDecoration: 'none !important'}}>{item.name}</h3>
                        <p className="reg-desc">{item.description2}</p>
                    </div>
                </div>
            </a>
        </div>
    )
}

const RegionAll = () => {
    const [regions, setRegions] = useState<Region[]>([])
            
    useEffect(() => {
        // requests
        const requests = [
            `Region/all`,
        ].map((url) => http.get(url));

        const fetchCategories = () => {
            // request
            axios.all(requests).then(
                axios.spread((theme, mets) => {
                    setRegions(theme.data);
                })
            )
            .catch((error:any) => console.log(error));
        }

        fetchCategories();
    }, [])

    return (
        <>        
        <div className='container'>
            <div className="row mr-auto ml-auto text-center" style={{padding: '20px 0'}}>            
                {regions.length <= 0 ? (
                    <LoadingNotice item='Regions' />
                ): (regions.length > 0 && (
                    <>
                    <div className='row'>
                    {
                        regions
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((item, idx) => (
                            <>
                            <SingleCard item={item} key={`sct-${idx}`}/>
                            </>
                        ))                        
                    }
                    </div>
                    </>)
                )}
            </div>            
        </div>
    </>
    );
}

export default RegionAll;