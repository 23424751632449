import '../../components/UtilStyles.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../lib/Http';
import MetricCard from '../../components/cards/MetricCard';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import { SectionHeaderMinUC } from '../../components/Utils';
import NoDataNotice from '../../components/NoDataNotice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { checkMetricExists } from '../../lib/Funcs';

const SectorMetrics = () => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [metricsOrdered, setMetricsOrdered] = useState<Record<string, any>>({});

    const [items, setItems] = useState<any[]>([]);
    const [isLoadingPg, setIsLoadingPg] = useState(false);
    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [dataLen, setDataLen] = useState(0);
    const [hasNext, setHasNext] = useState(true);
 
    const fetchData = async () => {
        setIsLoadingPg(true);
        setError(null);
        try {
            const response = await http.get(`Paged/MetricLink/theme/${id}?Page=${page}&ItemsPerPage=10`,);
            const data = await response.data ;
            // console.log('RT Data', data)
            let newData = [...items, ...data]
            setDataLen(newData.length)

            let ordered_arr: Record<string, any> = {};
            newData.forEach((e: any) => {
                const key = e.metricType?.name;                   
                if (!ordered_arr[key]) ordered_arr[key] = [];
                if (!checkMetricExists(ordered_arr[key], e.metric.metricId)) ordered_arr[key].push(e.metric);
                // console.log('check array', ordered_arr[key])
                // console.log('check bool', e.metric.metricId, checkMetricExists(ordered_arr[key], e.metric.metricId))
                // ordered_arr[key].push(e.metric);
            });
            setMetricsOrdered(ordered_arr)
            setItems(newData)

            // setItems((prevItems: any[]) => prevItems.concat(data));
            // setItems((prevItems: any[]) => [...prevItems, ...data]);
            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            setIsLoadingPg(false);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>

                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                <InfiniteScroll
                    dataLength={dataLen}
                    next={fetchData}
                    hasMore={hasNext} // Replace with a condition based on your data source
                    loader={<p></p>}
                    endMessage={<p></p>}
                    style={{overflowX: 'hidden', padding: '2px'}}
                >
                    <div className="row">
                        {Object.keys(metricsOrdered).length > 0 ? (
                            Object.keys(metricsOrdered).map((key) => (
                                <>
                                    <div className="row" style={{padding: '20px 10px'}}>
                                        <SectionHeaderMinUC title={key}  />
                                    </div>
                                    <div className="row">
                                        {metricsOrdered[key].map((child:any, index:number) => (
                                            <div className="col-md-4" style={{ paddingBottom: '15px'}} key={`p1-g-${index}`}>
                                                <MetricCard item={child} />
                                            </div>
                                        ))}
                                    </div>
                                </>                        
                            ))
                        ) : (<><NoDataNotice item='Metrics'/></>)}
                    </div>
                </InfiniteScroll>
                </>)}
            </div>

        </div>        
    </>;
}
export default SectorMetrics;