import { useEffect, useState } from 'react';
// import '../components/UtilStyles.css';
import './MinstryAllNew.css';
import http from '../../lib/Http';
import axios from 'axios';
import LoadingNotice from '../../components/LoadingNotice';

const SingleCard = ({item}:{item:any}) => {
    return (
        <div className="col-md-4" style={{ padding: '15px 15px'}}>
            <a href={`ministry/${item?.ministryId}`}>
                <div className='reg-content' style={{border: '2px #444444 solid'}}>
                    <div className="reg-content-overlay"></div>
                    <img src={item.thumbnailUrl} alt='...' className='reg-content-image'/>
                    
                    <div className='text-ctnr' style={{padding: '5px', backgroundColor: '#444444 !important' }}>
                        <h3 className="reg-title font-wc-semi" style={{textDecoration: 'none !important'}}>{item.name}</h3>
                        <p className="reg-desc">{item.description}</p>
                    </div>
                </div>
            </a>
        </div>
    )
}

const MinistryAll = () => {
    const [ministries, setMinistries] = useState<Ministry[]>([])
            
    useEffect(() => {
        // requests
        const requests = [
            `Ministry/all`,
        ].map((url) => http.get(url));

        const fetchData = () => {
            axios.all(requests).then(
                axios.spread((mins) => {
                    setMinistries(mins.data);
                    // console.log('ministries', mins.data)
                })
            )
            .catch((error:any) => console.log(error));
        }
        fetchData();
    }, [])

    return (
        <>        
        <div className='container'>
            <div className="row mr-auto ml-auto text-center" style={{padding: '20px 0'}}>            
                {ministries.length <= 0 ? (
                    <LoadingNotice item='Ministries' />
                ): (ministries.length > 0 && (
                    <>
                    <div className='row'>
                    {
                        ministries
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((item, idx) => (
                            // <>
                            // <SingleCard item={item} key={`sct-${idx}`}/>
                            // </>
                            <div className="col-md-6" style={{ padding: '15px 15px', textAlign: 'left'}}>
                                <a href={`ministry/${item?.ministryId}`} style={{color: '#444', textDecoration: 'none'}}>
                                    <img src={item.thumbnailUrl} style={{width: '60px', height:'60px', overflow:'hidden', padding:'5px'}} className="rounded float-start1" alt="..."/>
                                    &nbsp;<span style={{fontSize: '24px'}}>{item.name}</span>
                                </a>
                            </div>
                        ))                        
                    }
                    </div>
                    </>)
                )}
            </div>            
        </div>
    </>
    );
}

export default MinistryAll;