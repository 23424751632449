import { ChangeEvent } from "react"

export const ProjectTypeFilter = ({projTypes, handler}: {projTypes: string[], handler: (event: ChangeEvent<HTMLSelectElement>) => void}) => {
    return (<>
        {/* <div className="row">
            <div>&nbsp;</div> */}
            <div className="col-md-6">                    
                <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon3" style={{height: '100%'}}>Filter by Project Type</span>
                    <select className="form-control" id='searchColumn' onChange={handler} style={{border: '1px solid #888'}}>
                        <option value='' defaultValue={''}>--- Select Project Type ---</option>
                        {projTypes.sort((a: string, b: string) => (a < b ? -1 : 1)).map((opt, idx) => (
                            <option value={opt} key={`opt-${idx}`}>{opt}</option>
                        ))}
                    </select>
                </div>
            </div>
        {/* </div> */}
    </>)
}