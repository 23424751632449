import './HeaderBannerCat.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholderImg from '../../assets/media_banner.jpg';

interface HeaderProps {
    id: string,
    type?: string,
    image?: string,
    title: string,
    desc?: string,
    metric?: Metric,
}

export const HeaderBannerCat = (props: HeaderProps) => {
    return <>
        {/* <div className="cat-ctnr" style={{backgroundColor: 'rgba(2,2,2, 0.3)', padding: '5px 7px', borderRadius: '5px'}}> */}
        <div className="cat-ctnr">
            <div className='ban-overlay'></div>
            <LazyLoadImage 
                src={props.image} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
            />
            <div className='cat-header'>
                {/* <div className='ban-overlay'> */}
                {/* <div style={{backgroundColor: 'rgba(2,2,2, 0.3)', padding: '5px 7px', borderRadius: '5px'}}> */}
                    <h3>{props.title}</h3>
                    <p>{props?.desc}</p>
                    <div className='metric' style={{}}>{props?.metric && (
                            <p color='#ff0000'>{props?.metric.measure} {(props?.metric.unitOfMeasure === 'NA')? '': props?.metric.unitOfMeasure} {props?.metric.description} {props?.metric.year}</p>                
                        )}
                    </div>
                {/* </div> */}
            </div>
        </div> 
    </>;
}