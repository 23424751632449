// import { HeaderBanner } from '../components/HeaderBanner';
import banner from '../../assets/banners/about_banner.png'; //
import placeholderImg from '../../assets/media_banner.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './AcceptPhoneno.css';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

type FormValues = {
    phoneNumber: string;
};

const AcceptPhoneno = () => {
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        
        (document.getElementById('frmDelPhone') as HTMLFormElement).reset()
        navigate('/account/acceptotp');
            // http.post(`ContactUs/add`, data)
            //         .then((res: any) =>  {
            //             (document.getElementById('frmDelPhone') as HTMLFormElement).reset()
                        
            //         })
            //         .catch((error:any) => console.log(error));
        
        // timeout notification
        // setTimeout(() => {
        //     setErrorMessage(undefined);
        //     setMsgType(false);
        // }, 5000);
    };

    return <>
        <div className="my-cnt" style={{'border': 'none'}}>
            <LazyLoadImage 
                src={banner} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                className='abt-img'
            />
            <div className='o-head'>
                <h3>{''}</h3>
            </div>
        </div>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                <div style={{padding: '50px 0px 10px 10px'}}>
                    <h3 style={{textTransform: 'uppercase', textAlign: 'center'}}>Delete User Account</h3>
                </div>
                <div style={{paddingBottom: '30px'}}>&nbsp;</div>                
            </div>
            <div className="row" style={{padding: '20px 0'}}>
                <div className="col-md-4"></div>                    
                    <div className="d-flex align-items-center1 justify-content-center">
                        <form id="frmDelPhone" className="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <input className="form-control" placeholder="Enter your phone number" 
                                {...register("phoneNumber", { required: true, pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i })}  
                            />
                            {errors.phoneNumber?.type === "required" && <span className='form-text text-danger'>This field is required</span>}
                            {errors.phoneNumber?.type === "pattern" && <span className='form-text text-warning'>Invalid telephone number format</span>}
                            <button className="btn-sub btn btn-outline-success">Submit</button>
                        </form>
                    </div>
                    <div style={{paddingBottom: '90px'}}>&nbsp;</div>
                <div className="col-md-4"></div>
            </div>
        </div>
    </>;
}
export default AcceptPhoneno;