import React, { useEffect, useState } from "react";
import { HeaderBanner } from "../components/banners/HeaderBanner";
import { Outlet } from "react-router-dom";
import banner from "../assets/banners/media_banner.png";
import http from "../lib/Http";
import axios from "axios";

export interface Props {
    headerName: string,
}

const links: LinkProps[] = [
    {name: 'Photos', url: '/media/photos'},
    {name: 'Videos', url: '/media/videos'},
    {name: 'Audios', url: '/media/audios'},
]

const MediaMain: React.FC<Props> = (props) => {
    const [events, setEvents] = useState<EventData[]>([])

    const requests = [
        '/Event/all',
    ].map(url => http.get(url));
    useEffect(() => {
        axios.all(requests)
        .then(            
            axios.spread((evs, arts, ) => {
                setEvents(evs.data);
            })
        )
        .catch(err => console.log(err));
    }, [])

    return (
        <>
        <HeaderBanner image={banner} title={props.headerName} />
        {/* <NavbarMini navs={links}/> */}

        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                <div style={{padding: '20px 0', backgroundColor: '#fcfcfc'}}>
                    <Outlet />
                </div>
            </div>
        </div>
        </>
    )
}

export default MediaMain;