import ReactPlayer from "react-player";

export default function AudioItems({ currentItems }: {currentItems: Audio[]}) {
    return (
      <>
        <div className='row'>
        {
            (currentItems.length > 0) && currentItems.map((item, idx) => (
                <>
                <div className='col-md-4'>
                    <div style={{boxShadow: '2px 5px 7px #444444', marginBottom: '35px'}}>
                        <ReactPlayer
                            url={item.audioUrl}
                            height={'230px'}
                            width={'100%'}
                            controls={true}
                        />
                    </div>
                </div>
                </>
            ))
        }            
        </div>
      </>
    );
}