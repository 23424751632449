
const LoadingNotice = ({item}: {item: string}) => {
    return (
        <>
        <div className="alert alert-default">
            <p style={{fontSize: '20px'}}>{`Loading ${item}, Please wait ...`}</p>
        </div>
        </>
    )
}

export default LoadingNotice;