import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PhotoCard } from '../../components/cards/PhotoCard';
import { getProjectMultimediaPhoto } from '../../lib/Funcs';
import NoDataNotice from '../../components/NoDataNotice';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

const MmdaPhotos = () => {

    const [photos, setPhotos] = useState<PhotoLink[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const {id} = useParams();
    
    useEffect(() => {
        http.get<Mmda>(`Mmda/${id}`,)
            .then((res) => {
                // console.log('pro search', res.data);
                let data = {'mmda': res.data?.name+" "+res.data.type, 'searchColumns': ['mmda']}
                let photoList: PhotoLink[] = []
                http.post(`Search/project/multiple_params`, data)
                    .then(nres => {
                        nres.data.map((item: Project) => {
                            let imgList: any[] = getProjectMultimediaPhoto(item?.projectMultimedia!)
                            imgList.map((img: any) => {
                                photoList.push({
                                    photo: {'thumbnailUrl': img.url, name: img.name, photoId: '', description: img.description, isApproved: true}, 
                                    photoLinkId:'', photoId: '', isOnHomePage:false
                                })
                            })

                        })
                        
                        setPhotos(photoList)
                        setIsLoading(false)
                    })
                    .catch((error:any) => {console.log(error);});
            });
    }, [])

    return <>
        <div className="row" style={{padding: '20px 0'}}>

        {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
                {photos.length > 0 ?  (                        
                photos.map((item, index) => (
                    <div className="col-md-4">
                        <PhotoCard item={item.photo} />
                    </div>
                ))
            ): (<><NoDataNotice item='Photos'/></>)}
        </>)}



            {/* {photos.length > 0 ?  (                        
                photos.map((item, index) => (
                    <div className="col-md-4">
                        <PhotoCard item={item.photo} />
                    </div>
                ))
            ): (<><NoDataNotice item='Photos'/></>)} */}
        </div>
    </>;
}

export default MmdaPhotos;