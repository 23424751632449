import { useState, CSSProperties } from 'react';
import "./AdvanceSearch.css";
import http from '../../lib/Http';
import { SubmitHandler, useForm } from 'react-hook-form';
import ProjectCard from '../../components/cards/ProjectCard';
import { SectionHeaderBlack } from '../../components/Utils';
// import { placeholderImg } from '@dhaiwat10/react-link-preview';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ArticleCard } from '../../components/cards/ArticleCard';
import { InfographicCard } from '../../components/cards/InfographicCard';
import { VideoCard } from '../../components/cards/VideoCard';
import { CarouselControlButton } from '../../components/carousel/CarouselParts';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import { getProjectMultimediaPhoto } from '../../lib/Funcs';

type FormValues = {
    searchParameter: string;
};

const AdvanceSearch = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    //const [metrics, setMetrics] = useState<Metric[]>([]);
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [infographs, setInfographs] = useState<Infographic[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const [articles, setArticles] = useState<Article[]>([]);
    const [videos, setVideos] = useState<Video[]>([]);
    
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();
    // const [searchResult, setSearchResult] = useState<any[]>([]);
  
    const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
        setIsLoading(true);
        console.log(data)
        http.post(`Search/all`, data)
            .then((res: any) =>  {
                setSectors(res.data.themes)
                setCategories(res.data.categories);
                setProjects(res.data.projects);
                setPhotos(res.data.photos);
                setArticles(res.data.articles)
                setInfographs(res.data.infographics)
                setVideos(res.data.videos)
                setIsLoading(false);
            })
            .catch((error:any) => {console.log(error); setIsLoading(false);});
    };
   
    return (
        <div>
            <div className='row'>
                <div className='card'>
                    <div className='card-header'>
                        <h3>Site Search</h3>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon3" style={{height: '100%'}}>Search term</span>
                                        <input type="text" {...register("searchParameter", { required: true })} className="form-control" id="email"  style={{border: '1px solid #888'}}/>
                                    </div>
                                    {errors.searchParameter?.type === "required" && <span className='form-text'>This field is required</span>}
                                </div>
                                <div className="col-md-4" style={{}}>
                                    <button style={{marginTop: '0px'}} type="submit" className='btn btn-primary'><i className="fa-solid fa-search">&nbsp;</i>Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="container-fluid">

                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>                
                </>) : (<>
                    <div className="row sect-row">            
                        {sectors.length > 0 && (
                            <>
                            <SectionHeaderBlack txt={`Themes`} link={``} />
                            {
                                sectors.map((item, idx) => (
                                    <>
                                    <ThemeCard item={item} key={`sct-${idx}`}/>
                                    </>
                                ))                        
                            }                    
                            </>)
                        }
                    </div>          

                    <div className="row sect-row" style={{border: 'none'}}>            
                        {categories.length > 0 && (
                            <>
                            <SectionHeaderBlack txt={`Categories`} link={``} />
                            {
                                categories.map((item, index) => (
                                    <CategorySearchCard item={item} key={`ccu-${index}`} />
                                ))
                            }
                            </>
                        )}
                    </div>

                    {projects.length > 0 && (   
                        <div className="row sect-row">
                            <SectionHeaderBlack txt={`Projects`} link={``} />
                            {
                                projects.map((item, index) => (
                                <div className="col-md-6" style={{ padding: '10px 5px 10px 5px' }} key={`pg-${index}`}>
                                    <ProjectCard item={item} image={getProjectMultimediaPhoto(item.projectMultimedia)[0]?.url} />
                                </div>
                                ))
                            }
                        </div>
                    )}
                    
                    {photos.length > 0 && (<>
                    <div className="row sect-row" style={{border: 'none'}}>
                        <SectionHeaderBlack txt={`Photos`} link={``} />
                        {<>
                            <div id="cat1-carousel" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    {photos.length > 0 && (photos.map((i, idx) => (
                                        <button type="button" data-bs-target="#cat1-carousel" data-bs-slide-to={`${idx}`} className={idx === 0 ?"active" : ""} aria-current="true" key={`d-btn-${idx}`}></button>
                                        )
                                    ))}
                                </div>
                                <div className="carousel-inner">                    
                                    {   photos.length <= 0 ? (
                                        <div className="alert alert-info">
                                            <LazyLoadImage 
                                                src={'item.thumbnailUrl'} 
                                                // placeholderSrc={placeholderImg}
                                                effect='blur'
                                                alt="..."
                                                className={"c-img"}
                                            />
                                        </div>
                                    ): (                        
                                        photos.map((item, index) => (
                                            <div key={`gc-${index}`} className={index === 0 ? "carousel-item active" : "carousel-item"} >
                                                <LazyLoadImage 
                                                    src={item?.thumbnailUrl} 
                                                    // placeholderSrc={placeholderImg}
                                                    effect='blur'
                                                    alt="..."
                                                    className={"c-img"}
                                                />                               
                                                <div className="carousel-caption d-none d-md-block d-flex">
                                                    <div>
                                                        <h3 className='font-wc-semi'>{item?.name}</h3>
                                                        <p>{item?.description}</p>
                                                        <div>&nbsp;</div>
                                                    </div>
                                                    <div>&nbsp;</div>
                                                </div>
                                            </div>    
                                        ))
                                    )}
                                </div>
                                <CarouselControlButton carsId='#cat1-carousel' />
                            </div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                        </>
                        }
                        <div>&nbsp;</div>
                    </div>
                    </>
                    )}

                    {articles.length > 0 && (   
                        <div className="row sect-row">
                            <>
                            <SectionHeaderBlack txt={`Articles`} link={``} /> 
                            {articles.map((item, index) => (
                                <div className="col-md-4" key={`psk-${index}`} style={{paddingBottom: '25px'}}>
                                    <ArticleCard item={item} />
                                </div>
                            ))}
                            </>
                        </div>
                    )}

                    {infographs.length > 0 && (
                        <div className="row sect-row">
                            <>
                            <SectionHeaderBlack  txt={`Infographics`} link={``}  />                        
                            {infographs.length > 0 && (                        
                                infographs.map((item, index) => (
                                    <div className="col-md-4" style={{border: 'none', paddingBottom: '25px'}} key={`ic-${index}`}>
                                        <InfographicCard item={item} />
                                    </div>
                                ))
                            )}                        
                            </>
                        </div>
                    )}

                    {videos.length > 0 && (
                        <div className="row sect-row" style={{border: 'none'}}>
                            <SectionHeaderBlack  txt={`Videos`} link={``}  />  
                            {videos.map((item, index) => (
                                <div className="col-md-4" key={`vc-${index}`} style={{ paddingBottom: '10px' }}>
                                    <VideoCard item={item} height='270px' width='100%' />
                                </div>
                            ))}
                        </div>
                    )}

                </>)}
           </div>
        </div>    
    );
}

const pStyle: CSSProperties = {
    height: '110px',
    overflowX: 'auto',
    overflowY: 'hidden',
}

const ThemeCard = ({item}:{item:any}) => {
    return (
        <>
        <div className="col-md-12" style={{border: 'none'}}>
            <div className='row' style={pStyle}>
                <div className='col-sm-3 col-md-2 col-lg-2'>
                    <div style={{height: '110px'}}>
                        <img src={item.displayImageUrl} alt='...' className='theme-content-image-srch'/>
                    </div>
                </div>
                <div className='col-sm-9 col-md-10 col-lg-10' style={{ padding: '0px', margin: '0px' }}>
                    <a href={`sector/${item.themeId}`} style={{textDecoration: 'none', color: '#444444'}}>
                        <h3 className="font-wc-semi">{item.name}</h3>
                        <p style={{color: '#888'}}>{item.description}</p>
                    </a>
                </div>
            </div>
        </div>
        </>
    )
}

const CategorySearchCard = ({item}:{item:any}) => {
    return (
        <>
        <div className="col-md-12" style={{border: 'none', padding: '5px 0px' }}>
            <div className='row' style={pStyle}>
                <div className='col-sm-3 col-md-2 col-lg-2'>
                    <div style={{height: '110px'}}>
                        <img src={item.thumbnailUrl} alt='...' className='theme-content-image-srch'/>
                    </div>
                </div>
                <div className='col-sm-8 col-md-9 col-lg-9' style={{ padding: '0px', paddingLeft: '7px', margin: '0px', backgroundColor: '#f6f6f7' }}>
                    <div className='row'>
                        <div className='col-11'>
                            <a href={`/category/${item.categoryId}`} style={{textDecoration: 'none', color: '#444444'}}>
                                <h3 className="font-wc-semi">{item.name}</h3>
                                <p style={{color: '#888'}}>{item.description}</p>
                            </a>
                        </div>
                        <div className='col-1'>
                            <a href={`/category/${item.categoryId}`} 
                                style={{
                                    textDecoration: 'none', 
                                    fontSize: '30px',  
                                    color: '#e03172', 
                                    position: 'relative', 
                                    top: '25%', 
                                    height: '100px'
                                }}
                            >&gt;</a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AdvanceSearch;