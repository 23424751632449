import { PhotoCard } from "../cards/PhotoCard";

export default function PhotoItems({ currentItems }: {currentItems: Photo[]}) {
    return (
      <>
        <div className='row'>
        {            
            (currentItems.length > 0) && currentItems.map((item, idx) => (
                <>
                {item != null && (<>
                    <div className="col-md-4" style={{padding: '15px'}} key={`pht${idx}`}>
                        <PhotoCard item={item} key={`iim-${idx}`} />
                    </div>                
                </>)}
                </>
            ))
        }
        </div>
      </>
    );
}