import { CSSProperties } from "react";

const truncText = (txt: string, len: number) =>  {
    let nt = (txt.length > len) ? txt.slice(0, len) + '...' : txt;
    return nt;
}

const SectionHeader = ({txt, link}: {txt: string, link?: string}) => {
    const sectionHdr: CSSProperties = {
        fontFamily: 'WhitneyCondensedMedium',
        color: '#ff0000',
        textTransform: 'uppercase',
        fontSize: '22px',
        padding: '0px',
        paddingTop: '20px',
        textDecoration: 'none'
    }
    
    const sectionSep: CSSProperties = {
        background: 'linear-gradient(to right, green 40%, gold 55%)',
        width: '100%',
        height: '2px',
        marginBottom: '10px',
    }

    return (
        <>
        <a href={link ?? '#'} style={sectionHdr}>{txt}</a>
        <div style={sectionSep}></div>
        </>
    )
}

const sectionHdrBlack: CSSProperties = {
    fontFamily: 'WhitneyCondensedMedium',
    color: '#000000',
    textTransform: 'uppercase',
    fontSize: '22px',
    padding: '0px',
    paddingTop: '20px',
    textDecoration: 'none'
}

const sectionSep: CSSProperties = {
    background: 'linear-gradient(to right, green 40%, gold 55%)',
    width: '100%',
    height: '2px',
    marginBottom: '10px',
}

const SectionHeaderBlack = ({txt, link}: {txt: string, link?: string}) => {
    return (
        <>
        <div className="row" style={{padding: '10px 10px 0px 10px', margin: '0 auto'}}>
            <a href={link ?? '#'} style={sectionHdrBlack}>{txt}</a>
            <div style={sectionSep}></div>
        </div>
        </>
    )
}

const SectionHeaderMin = ({title}: {title: string}) => {
    return (
        <div className='row section-hdr'>
            <h5 style={{fontSize: '25px', paddingTop: '15px !important'}}>{title}</h5>
            <div className='bg2'></div>
        </div>
    )
}

const SectionHeaderMinUC = ({title}: {title: string}) => {
    return (
        <div className='row section-hdr'>
            <h5 style={{fontSize: '25px', paddingTop: '15px !important', textTransform: 'uppercase'}}>{title}</h5>
            <div className='bg2'></div>
        </div>
    )
}

const removeCommas = (txt: string) => {
    return txt !== null ? txt.replace(/,/g, '') : '';
}

const DataCardSmall = ({icon, label, val}: {icon: string, label: string, val: string}) => {
    return (<>
        <div className="card metric-st card-shadow col-12 mb-2" style={{paddingBottom: '20px'}}>
            <div className="card-header" style={{border: 'none'}}><i className={`fas fa-${icon}`}></i></div>
            <div className="card-body" style={{textAlign: 'center', padding: '5px'}}>
            </div>
            <div className="card-footer" style={{border: 'none', backgroundColor: '#fff'}}>
                <span style={{fontSize: '27px'}}>{(val != null) ? val : 'N/A'}</span><br/>
                <span style={{color: '#999', fontSize: '20px' }}>{label}</span>
            </div>
        </div>
    </>)
}

export { SectionHeader, SectionHeaderMin, SectionHeaderBlack, removeCommas, truncText, DataCardSmall, SectionHeaderMinUC }