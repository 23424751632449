import { HeaderBanner } from '../../components/banners/HeaderBanner';
import { useEffect, useState } from 'react';
import http from '../../lib/Http';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Line, Pie, Bar } from 'react-chartjs-2'
import { InfographicCard } from '../../components/cards/InfographicCard';
import { extractChartType, } from '../../lib/Funcs';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

const RegionalMetricSingle = () => {
    const {id} = useParams();
    const [childMetrics, setChildMetrics] = useState<RegionalChildMetric[]>([])

    const [metricLink, setMetricLink] = useState<MetricLink>()
    const [metricLinks, setMetricLinks] = useState<MetricLink[]>([])
    const [metricInfos, setMetricInfos] = useState<MetricInfographic[]>([])
    const [lineData, setLineData] = useState<number[]>([])
    const [lineLabels, setLineLabels] = useState<string[]>([])
    const [dataLabel, setDataLabel] = useState<string>()

    const [header, setHeader] = useState<any | null>('');
    const [headerImg, setHeaderImg] = useState<any | null>('');
    const [metricUnit, setMetricUnit] = useState<string>('');
    const [useNameDim, setUseNameDim] = useState<boolean>(false);
    const [sources, setSources] = useState<SourceLink[]>([]); 
    const [sourceCount, setSourceCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [chartDisplay, setChartDisplay] = useState('None');
    const [bgColors, setBgColors] = useState<string[]>([]);

    function getComputedLabel(cm: RegionalChildMetric, useName: boolean = false): string {
        return (useName) ? ""+cm.name : ""+cm.year;
    }

    function generateGraphData(mdata: RegionalChildMetric[], useName: boolean = false): any[] {
        let ldata: number[] = []
        let llabel: string[] = []
        mdata.sort((a, b) => a.year > b.year ? 1 : -1).map((cm: RegionalChildMetric) => {
            let label = getComputedLabel(cm, useName)
            ldata.push( Number(cm.measure.replaceAll(/,/g, '') ))
            llabel.push(`${label}`)
        })
        return [llabel, ldata]
    }
    
    useEffect(() => {
        const requests = [
            `RegionalChildMetric/parent/RegionalChild/${id}`,
            // MetricInfographicsLink/regionalmetricmaster/10'
            // `MetricInfographicsLink/region/${id}`,
            // `MetricInfographicsLink/metric/${id}`,
            `RegionalMasterMetric/${id}`,
            `RegionalMasterMetricSource/RegionalMasterMetric/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((chMetrics, masterMet, sources) => {
                setChildMetrics(chMetrics.data)
                if (masterMet.data) {
                    setDataLabel(`${masterMet.data?.name}`)
                    const metData: MetricLink = {
                        isMain: false,
                        metric: masterMet.data,
                        themeId: '0',
                        metricLinkId: '',
                        metricId: '100'
                    }                    
                    setMetricLink(metData);
                } 
                setMetricLinks(chMetrics.data)
                setMetricUnit(masterMet.data?.unitOfMeasure)

                http.get(`MetricInfographicsLink/regionalmetricmaster/${masterMet.data.regionalMasterMetricId}`)
                    .then((res: any) =>  {
                        setMetricInfos(res.data)
                    })
            .catch((error:any) => console.log(error));
                
                let usename_check = (masterMet.data?.dimension != null && masterMet.data?.dimension.includes('Name'))
                setUseNameDim(usename_check)
                let graphData = generateGraphData(chMetrics.data, usename_check);
                setLineLabels(graphData[0]);
                setLineData(graphData[1]);
                setSourceCount(sources.data.length)

                const dType = extractChartType(chMetrics.data[0])
                setChartDisplay(dType)

                let bkColors: string[] = [];
                chMetrics.data.map((item: any) => {
                    let randomColor = Math.floor(Math.random()*16777215).toString(16);
                    bkColors.push("#" + randomColor)
                })
                setBgColors(bkColors);
                
                setIsLoading(false)
            })
        )
        .catch((error:any) => console.log(error));
    }, [])

    const data = {
        labels: lineLabels,
        datasets: [
            {
                label: dataLabel,
                data: lineData,
                fill: false,
                // backgroundColor: 'rgba(0,255,0,0.2)',
                backgroundColor: bgColors,
                borderColor: '#4caf50',
            },
        ],
    }

    const options = {
        plugins: {
          legend: {
            display: false
          }
        }
    }

    return <>
        {/* <HeaderBanner image={headerImg} title={header} /> */}
        <div className="container" style={{backgroundColor: '#fcfcfc', paddingTop: '25px'}}>
        {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
            <div className='row'>
                <div className='col-8'>
                    <div className="row" style={{padding: '20px 0'}}>
                        <h3 style={{fontWeight: '700'}}>{metricLink?.metric?.name}</h3>
                        <p style={{color: '#444', fontSize: '16px'}}>{metricLink?.metric.measure} {(metricLink?.metric.unitOfMeasure === 'NA') ? '':metricLink?.metric.unitOfMeasure} {metricLink?.metric.description} {metricLink?.metric.year}</p>
                        <p style={{color: '#888', fontSize: '16px'}}>{metricLink?.metric.remarks}</p>
                    </div>
                    <div className="row" style={{padding: '10px 0'}}>
                        <div className="row">
                            <div className='col-md-8'>
                                <h4 style={{fontSize: '18px', color: '#0000ff'}}>Source:&nbsp;{(metricLink?.metric.sources) ? metricLink?.metric.sources : 'Source'}</h4>
                            </div>
                            <div className='col-md-4'>
                                {sourceCount > 0 && (
                                    <a href={`/region/metrics/source/${id}`} style={{fontSize: '18px', color: '#0000ff', textDecoration: 'none'}}><i className='fa fa-arrow-circle-o-right'>&nbsp;</i>View Underlining Data</a>
                                )}
                            </div>
                        </div>

                        {metricLinks.length > 0 && (
                            <>
                            <table className='table table-striped table-bordered'>
                                <colgroup>
                                    <col style={{width: '40%'}} />
                                    <col style={{width: '60%'}} />
                                </colgroup>
                                <thead>
                                    <tr className="table-default" style={{backgroundColor: '#58b1e8', color: '#ffffff'}}>
                                        <th>{(useNameDim) ? 'Name' : 'Year'}</th>
                                        <th>Metric&nbsp;{metricUnit != '' && metricUnit != 'N/A'  && metricUnit != 'NA' ? `(${metricUnit})`: ''}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {childMetrics.sort((a, b) => a.year > b.year ? 1 : -1).map((e, idx) => (<>
                                    <tr key={`ktr-${idx}`}>
                                        <td>{getComputedLabel(e, useNameDim)}</td>
                                        <td>{e.measure}</td>
                                    </tr>
                                </>))}                                
                                </tbody>    
                            </table>
                            </>
                        )}
                    </div>
                    
                    <div className="row" style={{padding: '20px 0', maxHeight: '670px'}}>
                        {chartDisplay != 'Table' && chartDisplay != 'None' ? (<>
                            <h3 style={{fontSize: '18px', color: 'red', fontWeight: '700'}}>{metricLink?.metric?.name} Chart {metricLink?.metric?.year}</h3>
                        </>) : (<></>)
                        }
                        {chartDisplay == 'Pie' ? (<>
                            <Pie data={data} options={options} />
                        </>)
                        : (chartDisplay == 'Bar') ? (<>
                            <Bar data={data} options={options} />
                        </>) 
                        : (chartDisplay == 'Line') ? (<><Line data={data} options={options} /></>)
                        : (<></>)
                        }
                    </div>
                    <div style={{clear: 'both'}}>&nbsp;</div>
                </div>
                <div className='col-md-4'>
                    <div className='row' style={{paddingTop: '10px'}}>
                        <h3 style={{fontSize: '18px', color: 'red', fontWeight: '700'}}>Related Infographics</h3>
                        <div>
                            {metricInfos.length > 0 && (                        
                                metricInfos.map((item, index) => (
                                    <div className="row" style={{paddingTop: (index === 0)? '36px':'0'}} key={index}>
                                        <InfographicCard item={item.infographic} key={`ice-${index}`} />
                                    </div>
                                ))
                            )}
                        </div> 
                    </div>                   
                </div>

                {/* <div className='col-md-4'>
                    <div className='row' style={{paddingTop: '10px'}}>
                        <h3 style={{fontSize: '18px', color: 'red', fontWeight: '700'}}>Related Infographics</h3>
                        <div>
                            {metricInfos.length > 0 && (                        
                                metricInfos.map((item, index) => (
                                    <div className="row" style={{paddingTop: (index === 0)? '36px':'0'}} key={index}>
                                        <InfographicCard item={item.infographic} key={`icf-${index}`} />
                                    </div>
                                ))
                            )}
                        </div> 
                    </div>                   
                </div> */}
            </div>
            </>)}
        </div>
    </>;
}

export default RegionalMetricSingle;