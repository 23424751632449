import React from "react";

export interface Props {
    contentPanel: React.ReactElement;
}

const Layout: React.FC<Props> = (props) => {
    return (
        <div>
            {props.contentPanel}
        </div>
    )
}

export default Layout;