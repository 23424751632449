// import { HeaderBanner } from '../components/HeaderBanner';
import banner from '../../assets/banners/about_banner.png'; //
import placeholderImg from '../../assets/media_banner.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './AcceptOtp.css';
import { useForm, SubmitHandler } from "react-hook-form";
import { ChangeEvent } from 'react';
// import React, { useState, useRef } from 'react';
// import useEventListener from './useEventListener';


type FormValues = {
    phoneNumber: string;
};

const AcceptOtp = () => {

    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        
        (document.getElementById('frmDelPhone') as HTMLFormElement).reset()
            // http.post(`ContactUs/add`, data)
            //         .then((res: any) =>  {
            //             (document.getElementById('frmDelPhone') as HTMLFormElement).reset()
                        
            //         })
            //         .catch((error:any) => console.log(error));
        
        // timeout notification
        // setTimeout(() => {
        //     setErrorMessage(undefined);
        //     setMsgType(false);
        // }, 5000);
    };


    const inputs = document.getElementById("inputs");
    // inputs.on

    // useEventListener('click', handleClick, buttonRef.current);

    const inputOnChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const target = ev.target;
        const val = target.value;
        const intVal = parseInt(target.value, 0);
     
        if (isNaN(intVal)) {
            target.value = "";
            return;
        }
     
        if (val != "") {
            const next = target.nextElementSibling;
            if (next instanceof HTMLElement) {
                next.focus();
            }
        }

    };
 
    // React.ChangeEvent<HTMLInputElement>
    // (inputs as HTMLFormElement).addEventListener("input", handleOnChange)

    // (inputs as HTMLFormElement).addEventListener("input", function (e: ChangeEvent<HTMLInputElement>) {
    //     const target = e.target;
    //     const val = target.value;
    //     const intVal = parseInt(target.value, 0);
    //     // const val = target.value;
     
    //     if (isNaN(intVal)) {
    //         target.value = "";
    //         return;
    //     }
     
    //     if (val != "") {
    //         const next = target.nextElementSibling;
    //         if (next instanceof HTMLElement) {
    //             next.focus();
    //         }
    //     }
    // })


// inputs.addEventListener("keyup", function (e) {
//     const target = e.target;
//     const key = e.key.toLowerCase();
 
//     if (key == "backspace" || key == "delete") {
//         target.value = "";
//         const prev = target.previousElementSibling;
//         if (prev) {
//             prev.focus();
//         }
//         return;
//     }
// });


    return <>
        <div className="my-cnt" style={{'border': 'none'}}>
            <LazyLoadImage 
                src={banner} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                className='abt-img'
            />
            <div className='o-head'>
                <h3>{''}</h3>
            </div>
        </div>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            
            <div className="row" style={{padding: '20px 0'}}>
                <div className="col-md-4"></div>
                <div className="col-md-4" style={{textAlign: 'center', border:'1px solid black', alignContent: 'center', alignItems: 'center'}}>
                    <div style={{paddingBottom: '20px'}}>&nbsp;</div>
                    <h3>Enter OTP to continue</h3>
                    <div className="d-flex align-items-center1 justify-content-center">
                        <form id="frmEnterOtp" className="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <div className='inputs-ctnr'>
                                <div id="inputs" className="inputs">
                                    <input className="input" type="text"
                                        inputMode="numeric" maxLength={1} onChange={inputOnChange} />
                                    <input className="input" type="text"
                                        inputMode="numeric" maxLength={1} onChange={inputOnChange} />
                                    <input className="input" type="text"
                                        inputMode="numeric" maxLength={1} onChange={inputOnChange} />
                                    <input className="input" type="text"
                                        inputMode="numeric" maxLength={1} onChange={inputOnChange} />
                                </div>
                            </div>
                            <div>&nbsp;</div>
                            <button className="btn-sub btn btn-outline-success">Submit</button>
                        </form>
                    </div>
                    <div style={{paddingBottom: '90px'}}>&nbsp;</div>
                </div>
                <div className="col-md-4"></div>
            </div>
        </div>
    </>;
}
export default AcceptOtp;