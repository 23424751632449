import React, { useContext, createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

// Define the type for the context value
interface AuthContextType {
  token: string,
  isLoggedIn: boolean;
  // login: () => void;
  login: (credentials: LoginCredentials) => void;
  logout: () => void;
}

interface UserState {
  username?: string;
  password?: string;
}

// Create the context with the defined type
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define props interface for AuthProvider component
interface AuthProviderProps {
  children: ReactNode;
}

// AuthProvider component
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] =  useState(false);
  const [user, setUser] =  useState<UserState>({username: '', password: ''});
  const navigate = useNavigate();

  const [token, setToken] = useState(localStorage.getItem("site_token") || "");

  const login = (credentials: LoginCredentials) => {
    console.log('req data', credentials)
    let res = {
      data: {
        user: {
          username: 'sumaila',
          password: 'sumaila'
        },
        role: 'user',
      },
      token: 'ksahxoqudwo8ysjHSjpq90818811',
    }

    if (credentials.username === "admin" && credentials.password === "all0ver") {
      setIsLoggedIn(true);
      setUser(res.data.user);
      setToken(res.token);
      localStorage.setItem("site_token", res.token);
      navigate("/alpha");
      return;
    } else {
      console.log("Invalid username or password");
    }
  };
  const logout = () => {
    setIsLoggedIn(false);
    setUser({username: '', password: ''});
    setToken("");
    localStorage.removeItem("site_token");
    navigate("/login");
  };

  // Provide the context value
  return <AuthContext.Provider value={{ token, isLoggedIn, login, logout }}>{children}</AuthContext.Provider>;
};

// Export AuthProvider component
export default AuthProvider;

// Custom hook to access the context value
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
    // navigate("/login");
  }
  return context;
};