import React from "react";

export const HeaderTop = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-dark1" style={{backgroundColor: '#444444', fontSize: '13px'}}>
                <div className="container-fluid">
                    <div className="w-100 d-flex justify-content-">
                        <div className="navbar-nav ms-auto mb-2 mb-lg-0"></div>
                        <div className="text-white">
                            <span className="text-white me-2 htop-anchor">(+233)&nbsp;53&nbsp;796&nbsp;0618&nbsp;</span>
                            {/* <span className="text-white me-2 htop-anchor">(+233)&nbsp;20&nbsp;399&nbsp;8906&nbsp;</span>0537960618 */}
                            <a href="contactus" className="text-white text-decoration-none me-2 htop-anchor">|&nbsp;&nbsp;<i className="fa-solid fa-message">&nbsp;</i>Contact Us&nbsp;</a>
                            <a href="mailto:support@performancetracker.gov.gh" className="text-white text-decoration-none me-2 htop-anchor">|&nbsp;&nbsp;<i className="fa-solid fa-envelope">&nbsp;</i>support@performancetracker.gov.gh&nbsp;</a>
                            <a href="#" className="text-white text-decoration-none me-2 htop-anchor">|&nbsp;&nbsp;&nbsp;<i className="fa-solid fa-map-marker"></i>&nbsp;Ghana&nbsp;</a>
                            {/* social media icons */}
                            <a href={"https://www.instagram.com/performance_tracker2.0?igsh=MWN5eDBtcTV0M2I1NA%3D%3D&utm_source=qr"} target="_blank" rel="noreferrer" className="text-white text-decoration-none me-2 htop-anchor">&nbsp;|&nbsp;&nbsp;&nbsp;<i className="fa-brands fa-instagram">&nbsp;</i></a>
                            <a href="https://www.facebook.com/performancetracker.gov.gh" target="_blank" rel="noreferrer" className="text-white text-decoration-none me-2 htop-anchor">&nbsp;<i className="fa-brands fa-facebook"></i></a>
                            <a href="https://twitter.com/PerfTrackergh" target="_blank" rel="noreferrer" className="text-white text-decoration-none me-2 htop-anchor">&nbsp;<i className="fa-brands fa-x-twitter"></i></a>
                            {/* <a href="https://twitter.com/success_tracker/" target="_blank" className="text-white text-decoration-none me-2 htop-anchor">&nbsp;<i className="fa-brands fa-twitter"></i></a> */}
                            <a href="https://wa.me/233537960618" target="_blank" rel="noreferrer" className="text-white text-decoration-none me-2 htop-anchor">&nbsp;<i className="fa-brands fa-whatsapp"></i></a>
                            <a href={'https://www.youtube.com/channel/UCbLt_t7kRAF6q8ApO2kat-g'} target="_blank" rel="noreferrer" className="text-white text-decoration-none me-2 htop-anchor">&nbsp;<i className="fa-brands fa-youtube"></i></a>
                            {/* <a href={'https://www.youtube.com/@PerformanceTracker2.0'} target="_blank" rel="noreferrer" className="text-white text-decoration-none me-2 htop-anchor">&nbsp;<i className="fa-brands fa-youtube"></i></a> */}
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}