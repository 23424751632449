import '../../components/UtilStyles.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../lib/Http';
import { InfographicCard } from '../../components/cards/InfographicCard';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import NoDataNotice from '../../components/NoDataNotice';

const MmdaReports = () => {
    const [reports, setReports] = useState<InfographicLink[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const {id} = useParams();
    
    useEffect(() => {
        http
              .get<InfographicLink[]>(`InfographicLink/mmda/${id}`)
              .then((res) => {
                setReports(res.data);
                setIsLoading(false)
              });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div>&nbsp;</div>
                {isLoading ? (<>
                        <div className='row'>
                            <LoadingSpinner />
                        </div>
                    </>) : (<>
                    {reports.length > 0 ? (  
                        <div className='row'>
                        {                    
                            reports.map((item, index) => (
                                <div className="col-md-4" style={{border: 'none', paddingBottom: '25px'}} key={`ic-${index}`}> 
                                    <InfographicCard item={item.infographic} />
                                </div>
                            ))
                        }
                        </div>
                    ): (<><NoDataNotice item='Infographics'/></>)}
                </>)}
        </div>        
    </>;
}
export default MmdaReports;