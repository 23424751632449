import { useState, useEffect, ChangeEvent } from 'react';
import '../../components/UtilStyles.css';
// import  'react-multiple-select-dropdown-lite/dist/index.css'
import http from '../../lib/Http';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ProjectSearchCard } from '../../components/cards/ProjectSearchCard';
// import MultiSelect from  'react-multiple-select-dropdown-lite';ss
// import Select from "react-select";
import axios from 'axios';
import { MultiSelect } from 'react-multi-select-component';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import { getOptionDataAttribute } from '../../lib/Funcs';
import ProjectSearchItems from '../../components/pagination/ProjectSearchItems';
import ReactPaginate from 'react-paginate';

type mOpt = {
    label: string,
    value: string,
}

type FormValues = {
    searchColumns: string[];
    themes: string[];
    categories: string[];
    region: string;
    ministry: string;
    mmda: string;
    projectTypes: string[];
};

const ProjectSearch = () => {
    
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormValues>();
    const [searchResult, setSearchResult] = useState<any[]>([]);
    const [categories, setCategories] = useState<mOpt[]>([]);
    const [themes, setThemes] = useState<mOpt[]>([]);
    const [mmdas, setMmdas] = useState<Mmda[]>([]);
    const [regions, setRegions] = useState<Region[]>([]);
    const [ministries, setMinistries] = useState<string[]>([]);
    const [projectTypes, setProjectTypes] = useState<mOpt[]>([]);
    // const [errorMessage, setErrorMessage] = useState();

    const [selectedThemes, setSelectedThemes] = useState<mOpt[]>([]);
    const [selectedCats, setSelectedCats] = useState<mOpt[]>([]);
    const [selectedPTypes, setSelectedPTypes] = useState<mOpt[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedMmdaType, setSelectedMmdaType] = useState<string>('');

    useEffect(() => {
        const requests = [
            `Themes/all`, 
            `Category/all`,
            `Mmda/all`,
            'Project/project_types',
            'Region/all',
            'Ministry/all',
        ].map(url => http.get(url));

        axios.all(requests)
            .then(
                axios.spread((themes: any, cats: any, mmdas: any, ptypes: any, regs: any, mins: any) => {
                    let catOpts: mOpt[] = [];
                    let themeOpts: mOpt[] = [];
                    let projectOpts: mOpt[] = [];
                    // setSearchResult(res1.data);
                    cats.data.map((e: Category) => {
                        catOpts.push({label: e.name, value: e.name})
                    });
                    setCategories(catOpts)

                    themes.data.map((e: Category) => {
                        themeOpts.push({label: e.name, value: e.name})
                    });
                    setThemes(themeOpts);

                    ptypes.data
                        .sort((a: string, b: string) => (a < b ? -1 : 1))
                        .map((e: string) => {
                            if(e != '') projectOpts.push({label: e, value: e})
                        });
                    setProjectTypes(projectOpts);

                    setMmdas(mmdas.data.sort((a: any, b: any) => (a.name < b.name ? -1 : 1)));
                    setRegions(regs.data.sort((a: any, b: any) => (a.name < b.name ? -1 : 1)));

                    let regTmps: string[] = [];
                    mins.data
                        .sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
                        .map((e: Ministry) => {
                            regTmps.push(e.name)
                        });
                    setMinistries(regTmps);
                })
            )
            .catch(err => console.log('Error', err))

    }, []);

    const handleRegionSelected = (event: ChangeEvent<HTMLSelectElement>) => {
        const regionId = getOptionDataAttribute(event, 'regionid');
        const URL = regionId != null ? `Mmda/region/${regionId}` : 'Mmda/all';

        http.get(URL)
            .then((res: any) =>  {
                setMmdas(res.data.sort((a: any, b: any) => (a.name < b.name ? -1 : 1)));
            })
            .catch((error:any) => {console.log(error);});
    }

    const handleMmdaSelected = (event: ChangeEvent<HTMLSelectElement>) => {
        const MmdaType = getOptionDataAttribute(event, 'type');
        setSelectedMmdaType(MmdaType!)
    }

    const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
        setIsLoading(true);
        data.searchColumns = [];
        data.projectTypes = [];
        data.categories = selectedCats.map((o) => o.value);
        data.themes = selectedThemes.map((o) => o.value);
        data.projectTypes = selectedPTypes.map((o) => o.value);
        data.mmda = data.mmda != '' ? data.mmda +' '+ selectedMmdaType: '';

        if(data.themes.length > 0) {
            data.searchColumns.push('theme');
        }
        if(data.categories.length > 0) {
            data.searchColumns.push('category');
        }
        if(data.projectTypes.length > 0) {
            data.searchColumns.push('projecttype');
        }
        if(data.region != '') {
            data.searchColumns.push('region');
        }
        if(data.ministry != '') {
            data.searchColumns.push('ministry');
        }
        if(data.mmda != '') {
            data.searchColumns.push('mmda');
        }
        
        http.post(`Search/project/multiple_params`, data)
            .then((res: any) =>  {
                setSearchResult(res.data);
                setIsLoading(false);
            })
            .catch((error:any) => {console.log((error)); setIsLoading(false)});
    };

    function PaginatedItems({data, itemsPerPage }: {data: any[], itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = data.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(data.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % data.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <ProjectSearchItems currentItems={currentItems} />            
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }
   
    return (
        <div>
            <div className='container-fluid'>
                <div className="row" style={{padding: '20px 0'}}>
                    <div className='col-md-4'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h5>Filter</h5>
                            <div className="row mb-3">
                                <label htmlFor="themes" className="col-sm-2 col-form-label">Theme</label>
                                <div className="col-sm-9">
                                    <MultiSelect
                                        options={themes}
                                        value={selectedThemes}
                                        onChange={setSelectedThemes}
                                        labelledBy="Select Themes"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="categories" className="col-sm-2 col-form-label">Category</label>
                                <div className="col-sm-9">
                                <MultiSelect
                                    options={categories}
                                    value={selectedCats}
                                    onChange={setSelectedCats}
                                    labelledBy="Select Categories"
                                />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="region" className="col-sm-2 col-form-label">Region</label>
                                <div className="col-sm-9">
                                <select className="form-control" id='mmda' {...register("region")} onChange={handleRegionSelected} style={{border: '1px solid #888'}}>
                                    <option value='' defaultValue={''}>--- Select Region ---</option>
                                    {regions.map((opt, idx) => (
                                        <option value={opt.name} data-regionid={opt.regionId} key={`opt-${idx}`} >{opt.name}</option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="district" className="col-sm-2 col-form-label">MMDA</label>
                                <div className="col-sm-9">
                                <select className="form-control" id='mmda' {...register("mmda")} onChange={handleMmdaSelected} style={{border: '1px solid #888'}}>
                                    <option value='' defaultValue={''}>--- Select MMDA ---</option>
                                    {mmdas.map((opt, idx) => (
                                        <option value={opt.name}  data-type={opt.type} key={`opt-${idx}`}>{opt.name}</option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="ministry" className="col-sm-2 col-form-label">Ministry</label>
                                <div className="col-sm-9">
                                <select className="form-control" id='ministry' {...register("ministry")} style={{border: '1px solid #888'}}>
                                    <option value='' defaultValue={''}>--- Select Ministry ---</option>
                                    {ministries.map((e, idx) => (
                                        <option value={e} key={`op-${idx}`}>{e}</option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            
                            <div className="row mb-3">
                                <label htmlFor="projectType" className="col-sm-2 col-form-label">Project&nbsp;Type</label>
                                <div className="col-sm-9">
                                    <MultiSelect
                                        options={projectTypes}
                                        value={selectedPTypes}
                                        onChange={setSelectedPTypes}
                                        labelledBy="Select Project Types"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="projectType" className="col-sm-2 col-form-label">&nbsp;</label>
                                <div className="col-sm-9">
                                    <button type="submit" className="btn btn-primary"><i className="fa-solid fa-search">&nbsp;</i>Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-8'>

                        {isLoading ? (<>
                            <div className='row'>
                                <LoadingSpinner />
                            </div>
                        </>) : (<>
                            {searchResult.length > 0 && (searchResult.length > 0 && (
                                <>
                                {/* {searchResult.map((item, index) => (
                                    <ProjectSearchCard item={item} key={`pcs-${index}`}/>
                                ))} */}
                                <PaginatedItems data={searchResult} itemsPerPage={30} />
                                </>)
                            )}                        
                        </>)}

                    </div>
                </div> 
            </div>           
        </div>
    );
}

export default ProjectSearch;