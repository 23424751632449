import ReactPlayer from "react-player";

export default function ProjSummaryItems({ currentItems, routePrefix, offSet = 0 }: {currentItems: ProjectSummary[], routePrefix: string, offSet: number}) {
    // console.log('Inner offSet: ' + offSet)
    return (
      <>
        <table className='table table-striped table-bordered'>
            <colgroup>
                <col style={{width: '5%'}} />
                <col style={{width: '65%'}} />
                <col style={{width: '10%'}} />
                <col style={{width: '10%'}} />
                <col style={{width: '10%'}} />
            </colgroup>
            <thead>
                <tr className="table-default" style={{backgroundColor: '#58b1e8', color: '#ffffff'}}>
                    <th>#</th>
                    <th>Project Type</th>
                    <th>Completed</th>
                    <th>Uncompleted</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                
            {(currentItems.length > 0) && currentItems.map((e, idx) => (<>
                <tr key={`ktr-${idx}`}>
                    <td>{offSet + (idx + 1)}</td>
                    <td><a style={{textDecoration: 'none'}} href={`${routePrefix}/projectno/${offSet + (idx + 1)}/projdetail/`}>{e.key}</a></td>
                    <td>{e.completedProjects}</td>
                    <td>{e.unCompletedProjects}</td>
                    <td>{e.totalProjects}</td>
                </tr>                
            </>))}
            </tbody>    
        </table>

        {/* <div className='row'> */}
        {/* {
            (currentItems.length > 0) && currentItems.map((e, idx) => (
                <>
                <tr key={`ktr-${idx}`}>
                    <td>{idx + 1}</td>
                    <td><a style={{textDecoration: 'none'}} href={`/region/${parentId}/projectno/${idx +1}/projdetail/`}>{e.key}</a></td>
                    <td>{e.completedProjects}</td>
                    <td>{e.unCompletedProjects}</td>
                    <td>{e.totalProjects}</td>
                </tr>
                </>
            ))
        }             */}
        {/* </div> */}
      </>
    );
}