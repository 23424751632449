import './MediaAudios.css';
import { ChangeEvent, useEffect, useState } from 'react'
// import { AuthorNDate } from '../components/ItemExtras';
import ReactPlayer from 'react-player';
import http from '../../lib/Http';
// import { ItemDateFluid } from '../../components/ItemExtras';
import { HeaderBanner } from '../../components/banners/HeaderBanner';
// import download_icon from "../../assets/download_icon.png";
import banner from "../../assets/banners/media_banner.png";
import { SectorFilter } from '../../components/filters/SectorFilter';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import AudioItems from '../../components/pagination/AudioItems';

const MediaAudiosDirect = () => {
    const [audios, setAudios] = useState<Audio[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);
    
    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        let mURL = (selectedOption === '') ? 'Audio/approved/all' : `AudioLink/theme/${selectedOption}`;

        http.get(mURL)
            .then((res: any) =>  {
                if(selectedOption === '') {
                    setAudios(res.data);
                } else {
                    let arr: Audio[] = [];
                    res.data.map((e: AudioLink) => arr.push(e.audio));
                    setAudios(arr);
                }
            })
            .catch(err => console.log('Error', err))
    };

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = audios.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(audios.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % audios.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <AudioItems currentItems={currentItems} />
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    useEffect(() => {
        const fetchData = () => {
            const requests = [`Audio/approved/all`, 'Themes/all'].map(url => http.get(url));
        axios.all(requests)
            .then(
                axios.spread((auds, sect, ) => {
                    setAudios(auds.data)
                    setSectors(sect.data)
                })
            )
            .catch(err => console.log('Options error', err));
        }

        fetchData();
    }, [])

    return <>
        <HeaderBanner image={banner} title={'Audios'} />
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                <div style={{padding: '20px 0', backgroundColor: '#fcfcfc'}}>

                    <SectorFilter sectors={sectors} handler={handleSelectChange} />
                    <PaginatedItems itemsPerPage={9} />                    
                </div>
            </div>
        </div>
    </>;
}

export default MediaAudiosDirect;