// import moment from 'moment';
import './ArticleCard.css';
import placeholderImg from "../../assets/ef8849de-da4b-47de-b54e-69f5182b1c54.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const ArticleCard = ({item}: {item: Article}) => {
    return (
        <>
        <div className="card acard" style={{border: 'none'}}>
            <LazyLoadImage 
                src={item.thumbnailUrl ?? ""} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                className={"card-img-top img-fluid"}
            />
            <div className="card-body acard-bd" style={{}}>
                <div style={{ margin: '0', padding: '0px',}}><a className="sub-hdr" href={item.articleUrl} target="_blank" rel="noreferrer">{item.title}</a></div>
            </div>
        </div>
        </>
    )
}