import { VideoCard } from "../cards/VideoCard";

export default function VideoItems({ currentItems }: {currentItems: Video[]}) {
    return (
      <>
        <div className="row" style={{paddingTop: '20px'}}>
        {currentItems.length > 0 && ( currentItems.map((item, index) => (
                <div className="col-md-4" key={index}>
                    <VideoCard item={item} height='270px' />
                </div>
            ))
        )}
        </div>
      </>
    );
}