import { HeaderBanner } from '../components/banners/HeaderBanner';
import { useEffect, useState } from 'react';
import http from '../lib/Http';
import freeshs from "../assets/freeshs1.jpg";
import irrg from "../assets/irrigation2.jpg";
import hosp from "../assets/hospital1.jpg";
// import NoDataNotice from '../components/NoDataNotice';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const sideEvents: {image: string, title: string, detail: string}[] = [
    {image: freeshs, title: 'FREE SHS', detail: 'What\'s the great impart?'},
    {image: hosp, title: 'HOSPITAL PROJECTS', detail: '32 major Hospital project ongoing in Ashanti.'},
    {image: hosp, title: 'HOSPITAL PROJECTS', detail: '32 major Hospital project ongoing in Ashanti.'},
    {image: irrg, title: 'IRRIGATION PROJECTS', detail: 'Life transformig initiatives.'},
    {image: irrg, title: 'IRRIGATION PROJECTS', detail: 'Life transformig initiatives.'},
];

// const sideEvents:{image: string, title: string, detail: string}[]  = [];

const EventSingle = () => {
    const {id} = useParams();
    const [header, setHeader] = useState<any | null>('');
    const [headerImg, setHeaderImg] = useState<any | null>('');

    useEffect(() => {
        const requests = [
            `Event/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((event, res2) => {
                setHeader(event.data?.name)
                // setEvent(event.data);
                setHeaderImg(event.data?.eventUrl)
            })
        )
        .catch((error:any) => console.log(error));
    }, [])

    return <>
        <HeaderBanner image={headerImg} title={header} />
        
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                <div className="col-md-4" style={{backgroundColor: '#ffffff'}}>
                    <div>
                        <div style={{clear: 'both'}}>&nbsp;</div>
                        {sideEvents.length <= 0 ? (
                            <div className="alert alert-danger">
                            <p>Ooops! Sorry no mini events to show...</p>
                            </div>
                        ): (                        
                            sideEvents.map((item, index) => (
                                <div className="row" style={{paddingTop: (index === 0)? '36px':'0'}} key={index}>
                                    <div className="card" style={{padding: '10px 20px', border: '1px dotted navy', }} key={index}>
                                        <img src={item.image} className="card-img-top" alt="..." />
                                        <div className="card-body" style={{ padding: '10px 0' }}>
                                            {/* <h4>{item.title}</h4> */}
                                            <p className="card-text" style={{ color: '#999999' }}>{item.detail}</p>
                                        </div>                        
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>;
}
export default EventSingle;