import { useEffect, useState } from 'react';
import http from '../../lib/Http';
import axios from 'axios';

const ellipseWord = {
    display: 'block',
    whiteSpace: 'nowrap',
    width: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // color: 'red',
    color: '#999999', fontSize: '15px'
}

const ProjectAll = () => {
    const [sectors, setSectors] = useState<Project[]>([])
            
    useEffect(() => {
        // requests
        const requests = [
            `Project/approved/all`,
        ].map((url) => http.get(url));

        const fetchCategories = () => {
            // request
            axios.all(requests).then(
                axios.spread((theme, mets) => {
                    setSectors(theme.data);
                })
            )
            .catch((error:any) => console.log(error));
        }

        fetchCategories();
    }, [])

    return (
        <div>
            <div className="row" style={{padding: '20px 0'}}>            
                {sectors.length > 0 && (
                    <>
                    {
                        sectors.map((item, index) => (
                            <>
                            <div className="col-3" style={{ maxHeight: '260px', overflow: 'hidden'}} key={`pg-${index}`}>
                                <div className="card" style={{padding: '10px 20px', border: 'none'}} key={index}>
                                    {/* <img src={item.} className="card-img-top cnt-img" alt="..." key={`evimg-${index}`}/>
                                    <div className="card-body" style={{ padding: '10px 0' }}>
                                        <a className="card-text" href={`sector/${item.themeId}`}>{item.name}</a>
                                        {item.metricLinks[0] !== undefined && (
                                            <p className='metric'>
                                                {item.metricLinks[0]?.metric.measure} {(item.metricLinks[0]?.metric.unitOfMeasure === 'NA') ? '':item.metricLinks[0]?.metric.unitOfMeasure} {item.metricLinks[0]?.metric.description} in {item.metricLinks[0]?.metric.year}
                                            </p>
                                        )}
                                    </div> */}
                                </div>
                                <div>&nbsp;</div>
                            </div>
                            </>
                        ))
                    }
                    </>
                )}
            </div>            
        </div>
    );
}

export default ProjectAll;