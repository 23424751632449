import { useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import RegionalMasterMetricCard from '../../components/cards/MetricCard4Region';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import { SectionHeaderMinUC } from '../../components/Utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataNotice from '../../components/NoDataNotice';

const RegionMetrics = () => {
    const [metrics, setMetrics] = useState<RegionalMasterMetric[]>([]);
    const {id} = useParams();
    const [metricsOrdered, setMetricsOrdered] = useState<Record<string, any>>({});
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState<any[]>([]);
    const [dataLen, setDataLen] = useState(0);
    // const [isLoading, setIsLoading] = useState(true);
    // const [isLoadingPg, setIsLoadingPg] = useState(false);
    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [hasNext, setHasNext] = useState(true);

    const fetchCategories = () => {
        return http
              .get(`/RegionalMasterMetric/region/${id}`)
              .then((res) => {
                setMetrics(res.data);

                let ordered_arr: Record<string, any> = {};
                res.data.forEach((e: any) => {
                    const key = e.metricType?.name;                   
                    if (!ordered_arr[key]) ordered_arr[key] = [];
                    ordered_arr[key].push(e);
                    // ordered_arr[key].push(e.metric);
                });
                setMetricsOrdered(ordered_arr)
                setIsLoading(false)
              });
    }
    
    const fetchData = async () => {
        // setIsLoadingPg(true);
        setError(null);
      
        try {
            const response = await http.get(`RegionalMasterMetric/region/paged/${id}?Page=${page}&ItemsPerPage=10`,);
            const data = await response.data ;
            
            let newData = [...items, ...data]
            setDataLen(newData.length)
            setItems(newData)

            let ordered_arr: Record<string, any> = {};
            newData.forEach((e: any) => {
                const key = e.metricType?.name;                   
                if (!ordered_arr[key]) ordered_arr[key] = [];
                ordered_arr[key].push(e);
                // ordered_arr[key].push(e.metric);
            });
            console.log('Ordere ID', data, ordered_arr)
            setMetricsOrdered(ordered_arr)
            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
            // setPage(prevPage => (data.length > 0) ? prevPage + 1 : prevPage);
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            // setIsLoadingPg(false);
            setIsLoading(false);
        }
      };

    useEffect(() => {
        fetchData()
        // fetchCategories();
    }, [])

    return (
        <div>
            <div className="row" style={{padding: '20px 0'}}>
                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                    <InfiniteScroll
                        dataLength={dataLen}
                        // dataLength={metricsOrdered.length}
                        next={fetchData}
                        hasMore={hasNext} // Replace with a condition based on your data source
                        loader={<p></p>}
                        endMessage={<p></p>}
                        style={{overflowX: 'hidden', padding: '2px'}}
                    >
                        {/* <div className="row"> */}
                            {Object.keys(metricsOrdered).length > 0 ? (
                                Object.keys(metricsOrdered).map((key) => (
                                    <>
                                        <div className="row" style={{padding: '20px 10px'}}>
                                            <SectionHeaderMinUC title={key}  />
                                        </div>
                                        <div className="row">
                                            {metricsOrdered[key].map((child:any, index:number) => (
                                                <div className="col-md-4" style={{ paddingBottom: '15px'}} key={`p1-g-${index}`}>
                                                    <RegionalMasterMetricCard item={child} link={`/region/metrics/${child.regionalMasterMetricId}`} />
                                                </div>
                                            ))}
                                        </div>
                                    </>                        
                                ))
                            ) : (<><NoDataNotice item='Metrics'/></>)}
                        {/* </div> */}
                    </InfiniteScroll>

                    {/* {metrics.length > 0 && (                        
                        <>
                            {Object.keys(metricsOrdered).length > 0 && (                        
                                Object.keys(metricsOrdered).map((key) => (
                                    <>
                                        <div className="row" style={{padding: '20px 10px'}}>
                                            <SectionHeaderMinUC title={key}  />
                                        </div>
                                        <div className="row">
                                            {metricsOrdered[key].map((child:any, index:number) => (
                                                <div className="col-md-4" style={{ paddingBottom: '15px'}} key={`p1-g-${index}`}>
                                                    <RegionalMasterMetricCard item={child} link={`/region/metrics/${child.regionalMasterMetricId}`} />
                                                </div>
                                            ))}
                                        </div>
                                    </>                        
                                ))
                            )}
                        </>
                    )} */}
                </>)}
            </div>
        </div>
    );
}
export default RegionMetrics;