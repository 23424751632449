import '../../components/UtilStyles.css';                
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Audio } from '../utils/CustomTypes';
import http from '../../lib/Http';
import { ArticleCard } from '../../components/cards/ArticleCard';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

const RegionArticles = () => {
    const [articles, setArticles] = useState<ArticleLink[]>([]);
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        http.get<ArticleLink[]>(`ArticleLink/region/${id}`,)
              .then((res) => {
                setArticles(res.data);
                setIsLoading(false)
            });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc', marginTop: '20px'}}>
            <div className="row" style={{marginBottom: '20px', paddingTop: '10px'}}>

            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>

                {articles.length > 0 && (                        
                    articles.map((item, index) => (
                        <div className="col-md-4" key={`psk-${index}`} style={{paddingBottom: '25px'}}>
                            <ArticleCard item={item.article} />
                        </div>
                    ))
                )}

                </>)}
            </div>
        </div>        
    </>;
}
export default RegionArticles;