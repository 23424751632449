import { useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
// import NoDataNotice from '../../components/NoDataNotice';
import { CategoryCard } from '../../components/cards/CategoryCard';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

const RegionCategories = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    // const [categories, setCategories] = useState<ThemeCategoryLink[]>([]);
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
        
    useEffect(() => {
        const fetchCategories = () => {
            return http
                    .get<Category[]>(`Project/region/category/${id}`,)
                    .then((res) => {
                        // console.log(`region cats | ${id}`, res.data);
                        setCategories(res.data);
                        setIsLoading(false)
                    });
        }

        fetchCategories();
    }, [])

    return (
        <div>
            <div className="row" style={{padding: '20px 0'}}>
                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                    {categories.length > 0 && (                        
                        categories.map((item, index) => (
                            <CategoryCard item={item} key={`cui-${index}`} />
                        ))
                    )}
                </>)}
            </div>
        </div>
    );
}

export default RegionCategories;