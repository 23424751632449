import { useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
// import { GoogleMap, Marker, useJsApiLoader, InfoWindow, } from '@react-google-maps/api';
import OpenMapComponent from '../../components/OpenMapComponent';

const mapStyles = {
  width: '100%',
  height: '100%'
};

const center = {
    lat: 5.5560, // Accra-central latitude
    lng: -0.1969, // Accra-central longitude
};

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const RegionProjectsMap = () => {
    const {id} = useParams();
    const [category, setCategory] = useState<Category>();
    const [projects, setProjects] = useState<Project[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedProj, setSelectedProj] = useState<Project | undefined | null>(null)
    
    useEffect(() => {
        const fetchCategories = () => {
            const requests = [
                `Project/region/${id}`,
            ].map(url => http.get(url));

            axios.all(requests)
                .then(
                    axios.spread((projs, ) => {
                        setProjects(projs.data);                        
                        setIsLoading(false)
                    })
                )
                .catch(err => console.log(err))

        }
        fetchCategories();
    }, [])

    return (
        <div>
            <div>&nbsp;</div>

            {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                    <OpenMapComponent projects={projects} />

                    {/* <div className='row'>
                        <div className='col-md-12'>
                            {isLoaded ? (
                            <>
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={14}
                                    center={center}
                                >
                                    <Marker position={center}  />

                                    {projects.map((proj) => (
                                        <Marker
                                            key={proj.projectId}
                                            onClick={() => onClickMarker(proj.projectId)}
                                            position={{
                                                lat: proj.latitude,
                                                lng: proj.longitude,
                                            }}
                                        />
                                    ))}

                                    {selectedProj ? (
                                        <InfoWindow
                                            position={{
                                            lat: selectedProj.latitude,
                                            lng: selectedProj.longitude
                                            }}
                                            onCloseClick={() => setSelectedProj(null)}
                                        >
                                            <p>
                                                {selectedProj.name}{" "}
                                                {selectedProj.description}{" "}
                                                {selectedProj.location} -{" "}
                                                {selectedProj.mmda}
                                            </p>
                                        </InfoWindow>
                                    ) : null}
                                </GoogleMap>

                            </>) : null}
                        </div>
                    </div> */}
                
                </>)}
                <div>&nbsp;</div>
        </div>
    );
}
export default RegionProjectsMap;