import { useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
// import NoDataNotice from '../../components/NoDataNotice';
import { CategoryCard } from '../../components/cards/CategoryCard';

const SectorCategoriesB4 = () => {
    const [categories, setCategories] = useState<ThemeCategoryLink[]>([]);
    const {id} = useParams();
        
    useEffect(() => {
        const fetchCategories = () => {
            return http
                    .get<ThemeCategoryLink[]>(`ThemeCategoryLink/theme/metric/${id}`)
                    .then((res) => {
                        setCategories(res.data);
                    });
        }

        fetchCategories();
    }, [])

    return (
        <div>
            <div className="row" style={{padding: '20px 0'}}>
                {categories.length > 0 && (                        
                    categories
                    .sort((a, b) => (a.category.name < b.category.name ? -1 : 1))
                    .map((item, index) => (
                        <CategoryCard item={item.category} key={`cui-${index}`} />
                    ))
                )}
            </div>
        </div>
    );
}

export default SectorCategoriesB4;