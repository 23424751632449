import { HeaderBanner } from '../components/banners/HeaderBanner';
import './Download.css';
import { useEffect, useState } from 'react';
import banner from "../assets/banners/download_banner.png";
import pdf_file from "../assets/pdf_file.png";
import download_icon from "../assets/download_icon.png";
import http from '../lib/Http';
import axios from 'axios';

const cardMDet =  {
    padding: '30px 10px', border: 'none', paddingTop: '10px'
}

const DownloadPage = () => {

    const [downloads, setDownloads] = useState<Download[]>([]);
    const requests = [
        '/Download/approved/all', 
    ].map(url => http.get(url));

    useEffect(() => {
        axios.all(requests)
            .then(
                axios.spread((downs) => {
                    setDownloads(downs.data);
                })
            )
            .catch(err => console.log(err));
    }, [])

    // const downloadClick = ({downLink}: {downLink: string}) => {
    const downloadClick = (downLink: string) => {
     
        // using Java Script method to get PDF file
        fetch("SamplePDF.pdf").then((response) => {
            response.blob().then((blob) => {
             
                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);
                     
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = downLink;
                // alink.download = "SamplePDF.pdf";
                alink.click();
            });
        });
    };

    return <>
        <HeaderBanner image={banner} title={'Download'} />
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                {downloads.length > 0 && (
                    downloads.map((item, index) => (
                        <div className="col-md-3" key={`dld-${index}`}>
                            <div className="card" style={cardMDet}>
                                <img src={pdf_file} className="card-img-top img-fluid" alt="..."/>
                                <div className="card-body" style={{ backgroundColor: '#f6f6f7', height: '100%' }}>
                                    <div style={{ margin: '0', padding: '0px', maxHeight: '56px', overflow: 'hidden' }}><a className="sub-hdr" href={item.fileUrl} target="_blank" rel="noreferrer">{item.name}</a></div>
                                    {/* <div style={{ margin: '0', padding: '0px', maxHeight: '56px', overflow: 'hidden' }}><a className="sub-hdr" href={item.fileUrl} onClick={downloadClick(item.fileUrl)} target="_blank" rel="noreferrer">{item.name}</a></div> */}
                                    <div style={{ margin: '0', height: '50px', overflow: 'hidden', }}>
                                        <p className="card-text" style={{color: '#888888', fontSize: '18px'}}>{item.description}</p>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-9'>&nbsp;</div>
                                        <div className='col-md-3' style={{padding: '0px', position: 'relative',}}>
                                            <a href={item.fileUrl} className='btn btn-sm' style={{marginLeft: '0px', position: 'absolute', right: '8px', bottom: '0px',}} target='_blank' rel="noreferrer" download={item.name}>
                                                <img src={download_icon} alt="..." style={{padding: '2px'}} />
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div style={{clear: 'both'}}>&nbsp;</div> */}
                                </div>                                    
                            </div>
                        </div>
                    ))
                )}                
            </div>
        </div>
    </>;
}

export default DownloadPage;