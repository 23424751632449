import CountUp from 'react-countup';
import { removeCommas } from "../Utils"
// import ReactVisibilitySensor from 'react-visibility-sensor';


export const MetricCountUp = ({measure}:{measure: string}) => {
    return (<>
        {/* <CountUp start={0} end={parseInt( removeCommas(measure))} delay={1}> */}
        <CountUp start={0} end={parseInt( removeCommas(measure))} delay={0}>
            {({ countUpRef, start }) => (
                // <ReactVisibilitySensor onChange={start}>
                    <span id="counter" style={{fontSize: '70px', marginBottom: '10px' }} ref={countUpRef} />
                // </ReactVisibilitySensor>
            )}
        </CountUp>
    </>)
}