import React, { useEffect, useState } from "react";
// import { HeaderBanner } from "../components/banners/HeaderBanner";
import { NavbarMini } from "../components/NavbarMini";
import { Outlet, useParams } from "react-router-dom";
import '../components/UtilStyles.css';
import http from "../lib/Http";
import axios from "axios";
// import { HeaderBannerMore } from "../components/banners/HeaderBannerMore";
// import { HeaderBannerSector } from "../components/banners/HeaderBannerSector";
import { HeaderBannerCat } from "../components/banners/HeaderBannerCat";
import { getBannerMetricLink } from "../lib/Funcs"

const CategoryMain: React.FC = () => {
    
    const {id} = useParams();

    const links: LinkProps[] = [
        {name: 'Metrics', url: `/category/${id}/metrics`},
        {name: 'Projects', url: `/category/${id}/projects`},
        {name: 'Photos', url: `/category/${id}/photos`},
        {name: 'Videos', url: `/category/${id}/videos`},
        {name: 'Audios', url: `/category/${id}/audios`},
        {name: 'Infographics', url: `/category/${id}/reports`},
        {name: 'Articles', url: `/category/${id}/articles`},
        {name: 'Project Summary', url: `/category/${id}/projsummary`},
        {name: 'Map', url: `/category/${id}/projectsmap`},
    ]

    const [category, setCategory] = useState<Category>();
    const [header, setHeader] = useState<any | null>('');
    const [headerImg, setHeaderImg] = useState<any | null>('');
    const [mainMetric, setMainMetric] = useState<MetricLink>();

    useEffect(() => {            
        const requests = [
            `Category/${id}`,
            `MetricLink/category/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((res1, mets) => {
                setHeader(res1.data?.name)
                setHeaderImg(res1.data?.thumbnailUrl)
                let mMetric = getBannerMetricLink(mets.data)
                setMainMetric(mMetric! ?? null)
                setCategory(res1.data);
            })
        )
        .catch((error:any) => console.log(error));
    }, []);
    
    return (
        <>
        <HeaderBannerCat id={category?.categoryId!} image={headerImg} title={header} desc={category?.description} metric={mainMetric?.metric} />
        <NavbarMini navs={links}/>
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{backgroundColor: '#fcfcfc'}}>
                        <Outlet />
                    </div>
                </div>
            </div>
            
        </div>
        </>
    )
}

export default CategoryMain;