// import {ArcElement, Chart as ChartJS, Tooltip, Legend, BubbleDataPoint, Plugin, Point, ChartTypeRegistry} from 'chart.js';
// import { Chart as ChartJS, } from 'chart.js';
import 'chart.js/auto';
import {Doughnut} from 'react-chartjs-2';

function getColor(value:number) {
    if (value > 0 && value < 20) {
        return 'red'
    }
    if (value > 20 && value <= 50) {
        return 'olive'
    }
    if (value > 50 && value <= 75) {
        return 'olivedrab'
    }
    return 'green'
}

export const ProjectDoughnut = ({percentageCompleted, w = '90px', h = '90px', showLg = false}: {percentageCompleted: string, w?: string, h?:string, showLg?:boolean}) => {
    
    percentageCompleted = (percentageCompleted != null) ? percentageCompleted : '0';
    
    const plugins = {
        legend: {
            display: false,
            labels: {
                color: 'rgb(255, 99, 132)'
            }
        }
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout:"60%",
        legend: {
          display: false,
        },
        layout: {
          padding: 10,
        },
        centerText: {
            display: true,
            text: `90%`
        },
        tooltips: {
            enabled: false
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: 'rgb(255, 99, 132)'
                }
            },
            tooltip: {
                enabled: false
            }
        }
    };

    return <>
        <div style={{backgroundColor: '#fcfcfc', padding: '0px !important', margin: '0px !important', border: 'none'}}>
            <div style={{padding: '0px'}}>
                <Doughnut data={{
                        datasets: [{
                            label: 'Poll',
                            data: [(100 - +percentageCompleted), percentageCompleted],
                            backgroundColor: ['#999', getColor(+percentageCompleted)],
                            borderColor: ['white', 'white']
                        }]
                    }} 
                    options={options}
                    width={w} 
                    height={h}
                />
            </div>
        </div>
    </>;
};