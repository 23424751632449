// import moment from 'moment';
// import './ArticleCard.css';
import ReactPlayer from 'react-player';

export const VideoCard = ({item, width = '100%', height = '240px'}: {item: Video, width?: string, height?: string}) => {
    return (
        <>
        <div className="card" style={{ border: 'none'}}>
            <ReactPlayer url={item.videoUrl} height={height} width={width}/>
            <div className="card-body">
                <p className="card-text" style={{ color: '#999999' }}>{item.description}</p>
            </div>
        </div>
        </>
    )
}