import { useEffect, useState } from 'react';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import { SectionHeaderMin } from '../../components/Utils';
import ProjSummaryItems from '../../components/pagination/ProjSummaryItems';
import ReactPaginate from 'react-paginate';

const AllProjectSummary = () => {
    const {id} = useParams();
    const [projSummary, setProjSummary] = useState<ProjectSummary[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const requests = [
            `/Project/summary/projects/all`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((pSum, ) => {                
                setProjSummary(pSum.data)                
                setIsLoading(false)
            })
        )
        .catch((error:any) => console.log(error));
    }, [])

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = projSummary.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(projSummary.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % projSummary.length;
        //   console.log('New offset', newOffset)
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <ProjSummaryItems currentItems={currentItems} routePrefix={``} offSet={itemOffset}/>
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc', paddingTop:'25px' }}>
            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
                {projSummary.length > 0 && (
                <>
                    <div className="row">
                        <SectionHeaderMin title={`All Projects Summary`}  />
                    </div>
                    <div className="row">
                        <PaginatedItems itemsPerPage={20} />
                    </div>
                </>
                )}
            </>)}
        </div>
        <div style={{clear: 'both'}}>&nbsp;</div>
        <div style={{clear: 'both'}}>&nbsp;</div>
    </>;
}

export default AllProjectSummary;