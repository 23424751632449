import { ItemDate } from '../../components/ItemExtras';
import ReactPlayer from 'react-player';
import http from '../../lib/Http';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { HeaderBanner } from '../../components/banners/HeaderBanner';
import banner from "../../assets/banners/media_banner.png";

const VideoSingleDirect = () => {
    const [video, setVideo] = useState<Video>();
    var {id} = useParams();

    useEffect(() => {
        http.get<Video>(`/Video/${id}`)
            .then((res) => {
                setVideo(res.data);
            });
    }, [])

    return (<>
        <HeaderBanner image={banner} title={`Videos/${video?.name}`} />
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{paddingTop: '20px'}}>
            {video && (
                <div className="col-md-12">
                    <ItemDate date={video.createdAt} />

                    <div className="card cd-pad">
                        <ReactPlayer url={video.videoUrl} width={'100%'} height={'450px'}/>
                        <div className="card-body" style={{ padding: '10px 0' }}>
                            <p className="card-text" style={{ color: '#999999' }}>{video.description}</p>
                        </div>                        
                    </div>
                </div>
            )}
            </div>
        </div>
    </>);
}

export default VideoSingleDirect;