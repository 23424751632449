import '../../components/UtilStyles.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Audio } from '../utils/CustomTypes';
import http from '../../lib/Http';
import ReactPlayer from 'react-player';
import NoDataNotice from '../../components/NoDataNotice';

const MmdaAudios = () => {
    const [audios, setAudios] = useState<any[]>([]);
    const {id} = useParams();
    
    useEffect(() => {
        http
              .get<any[]>(`AudioLink/mmda/${id}`)
              .then((res) => {
                setAudios(res.data);
              });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div style={{clear: 'both'}}>&nbsp;</div>
            <div className='row'>
            {
                (audios.length > 0) ? audios.map((item, idx) => (
                    <>
                    <div className='col-md-4' key={`ck-${idx}`}>
                        <div style={{boxShadow: '2px 5px 7px #444444', marginBottom: '35px'}}>
                            <ReactPlayer
                                url={item.audio.audioUrl}
                                // url="https://soundcloud.com/glennmorrison/beethoven-moonlight-sonata"
                                // url="https://soundcloud.com/opoku-isaac/nhyiraba-kojo-turn-around-ft-d?si=e13ec70ffeda46d7a96ca15b51e584ca&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                                height={'240px'}
                                width={'100%'}
                                controls={true}
                            />
                        </div>
                    </div>
                    </>
                )) : (<><NoDataNotice item='Audios' /></>)
            }            
            </div>
        </div>        
    </>;
}
export default MmdaAudios;