import { getProjectMultimediaPhoto } from "../../lib/Funcs";
import ProjectCard from "../cards/ProjectCard";

export default function ProjectItems({ currentItems }: {currentItems: any[]}) {
    return (
        <div className="row">
        {            
            (currentItems.length > 0) && currentItems.map((item, idx) => (
                <>
                {item != null && (<>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{paddingBottom: '17px'}} key={`pg-${idx}`}>
                        <ProjectCard item={item} image={getProjectMultimediaPhoto(item?.projectMultimedia!)[0]?.url} />
                    </div>
                </>)}
                </>
            ))
        }
        </div>
    );
}

// {projects.length > 0 && (                        
//     projects.map((item, index) => (
//         <div className="col-6 col-md-6" style={{paddingBottom: '17px'}} key={`pg-${index}`}>
//             <ProjectCard item={item} image={getProjectMultimediaPhoto(item?.projectMultimedia!)[0]?.url} />
//         </div>
//     ))
// )}