import './MediaVideos.css';
import { ChangeEvent } from 'react';
import http from '../../lib/Http';
import {useEffect, useState} from 'react';
import { SectorFilter } from '../../components/filters/SectorFilter';
import axios from 'axios';
import banner from "../../assets/banners/media_banner.png";
import { HeaderBanner } from '../../components/banners/HeaderBanner';
import ReactPaginate from 'react-paginate';
import VideoItems from '../../components/pagination/VideoItems';

const MediaVideosDirect = () => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = videos.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(videos.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % videos.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <VideoItems currentItems={currentItems} />
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    useEffect(() => {
        const requests = [`Video/approved/all`, 'Themes/all'].map(url => http.get(url));
        axios.all(requests)
            .then(
                axios.spread((vids, sect, ) => {
                    setVideos(vids.data)
                    setSectors(sect.data)
                })
            )
            .catch(err => console.log('Options error', err));
    }, [])

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        let mURL = (selectedOption === '') ? 'Video/approved/all' : `VideoLink/theme/${selectedOption}`;

        http.get(mURL)
            .then((res: any) =>  {
                if(selectedOption === '') {
                    setVideos(res.data);
                } else {
                    let arr: Video[] = [];
                    res.data.map((e: VideoLink) => arr.push(e.video));
                    setVideos(arr);
                }
            })
            .catch(err => console.log('Error', err))
    };

    return <>

    <HeaderBanner image={banner} title={'Videos'} />
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                <div style={{padding: '20px 0', backgroundColor: '#fcfcfc'}}>
            
            <SectorFilter sectors={sectors} handler={handleSelectChange} />
            <PaginatedItems itemsPerPage={9} />
        </div>
        </div>
        </div>
    </>;
}

export default MediaVideosDirect;