import { ChangeEvent, useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
// import NoDataNotice from '../../components/NoDataNotice';
import ProjectCard from '../../components/cards/ProjectCard';
// import { CategoryFilter } from '../../components/filters/CategoryFilter';
import axios from 'axios';
import { extractProjectTypes, getProjectMultimediaPhoto } from '../../lib/Funcs';
import { ProjectTypeFilter } from '../../components/filters/ProjectTypeFilter';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import ProjectItems from '../../components/pagination/ProjectItems';
import ReactPaginate from 'react-paginate';

const RegionProjects = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [region, setRegion] = useState<Region>();
    const [projectTypes, setProjectTypes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const {id} = useParams();
    
    const requests = [
        `Project/region/category/${id}`,
        `Project/region/${id}`,
        `Region/${id}`,
    ].map((url) => http.get(url));

    useEffect(() => {

        axios.all(requests).then(
            axios.spread((cats, projs, reg ) => {
                setRegion(reg.data);
                setCategories(cats.data);
                setProjects(projs.data);
                // console.log('projs', projs.data)

                let ptypes = extractProjectTypes(projs.data)
                setProjectTypes(ptypes);
                setIsLoading(false)
            })
        )
        .catch(err => console.log(err))
    }, [])

    const handleCategoryChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        let mURL = (selectedOption === '') ? `Project/region/${id}` : `Project/category/${selectedOption}`;
        
        http.get(mURL)        
            .then((res: any) =>  {
                let cProjects: Project[] = []
                res.data.map((e: any) => {
                    const tmp = region?.name.split(' ')
                    let regName = ((tmp?.length! > 2) ? tmp![0] +" "+ tmp![1] : tmp![0]).toLowerCase()                    
                    // console.log('reg name', tmp, tmp?.length, regName, e.region.toLowerCase())
                    if (regName === e.region.toLowerCase()) {
                        cProjects.push(e)
                    }
                })
                setProjects(cProjects)
            })
            .catch(err => console.log('Error', err))
    };

    const handleProjectTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;

        let data: {'region': string,  'projectTypes'?: string[], 'searchColumns': string[]} = {'region': region?.name!, 'searchColumns': ['region']}
        if (selectedOption !== '') {
            data['projectTypes'] = [selectedOption]
            data.searchColumns.push('projecttype')
        }
        // console.log('Search Data', data)
        http.post(`Search/project/multiple_params`, data)
            .then((res: any) =>  {
                setProjects(res.data);
            })
            .catch((error:any) => {console.log(error);});
    };

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = projects.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(projects.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % projects.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <ProjectItems currentItems={currentItems}/>
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    return (
        <div>
            <div className="row">
                {/* <CategoryFilter categories={categories} handler={handleCategoryChange} /> */}
                <div>&nbsp;</div>
                <ProjectTypeFilter projTypes={projectTypes} handler={handleProjectTypeChange} />
            </div>
             <div className="row" style={{padding: '17px 0'}}>
                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                <PaginatedItems itemsPerPage={20} />
                </>)}
            </div>
        </div>
    );
}
export default RegionProjects;