import { useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import { CategoryCard } from '../../components/cards/CategoryCard';

const MinistryCategories = () => {
    const [categories, setCategories] = useState<ThemeCategoryLink[]>([]);
    // const [categories, setCategories] = useState<ThemeCategoryLink[]>([]);
    const {id} = useParams();
        
    useEffect(() => {
        const fetchCategories = () => {
            // `MinistryCategoryLink/Ministry/${id}`
            return http
                    // .get<ThemeCategoryLink[]>(`ThemeCategoryLink/theme/${id}`)
                    .get<ThemeCategoryLink[]>(`MinistryCategoryLink/Ministry/${id}`)
                    .then((res) => {
                        console.log(`ministry cats | ${id}`, res.data[0]);
                        setCategories(res.data);
                    });
        }

        fetchCategories();
    }, [])

    return (
        <div>
            <div className="row" style={{padding: '20px 0'}}>
                {categories.length > 0 && (                        
                    categories.map((item, index) => (
                        <CategoryCard item={item.category} key={`cui-${index}`} />
                    ))
                )}
            </div>
        </div>
    );
}

export default MinistryCategories;