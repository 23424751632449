import { useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import MetricCard from '../../components/cards/MetricCard';
import { SectionHeaderMin, SectionHeaderMinUC } from '../../components/Utils';
import NoDataNotice from '../../components/NoDataNotice';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import { checkMetricExists } from '../../lib/Funcs';

const CategoryMetrics = () => {
    // const [metrics, setMetrics] = useState<Metric[]>([]);
    const [metrics, setMetrics] = useState<MetricLink[]>([]);
    const [metricsOrdered, setMetricsOrdered] = useState<Record<string, any>>({});
    const {id} = useParams();

    const [items, setItems] = useState<any[]>([]);
    const [dataLen, setDataLen] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    // const [isLoadingPg, setIsLoadingPg] = useState(false);
    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [hasNext, setHasNext] = useState(true);

    const fetchCategories = () => {
        return http
              .get(`/MetricLink/category/${id}`)
              .then((res) => {
                setMetrics(res.data);

                let ordered_arr: Record<string, any> = {};
                res.data.forEach((e: any) => {
                    const key = e.metricType?.name;
                    // const key = e["metricType"]?["name"];
                    if (!ordered_arr[key]) ordered_arr[key] = [];
                    ordered_arr[key].push(e.metric);
                });
                setMetricsOrdered(ordered_arr)
              });
    }
    
    const fetchData = async () => {
        // setIsLoadingPg(true);
        setError(null);
      
        try {
            const response = await http.get(`Paged/MetricLink/category/${id}?Page=${page}&ItemsPerPage=10`,);
            const data = await response.data ;
            let newData = [...items, ...data]
            setDataLen(newData.length)
            setItems(newData)

            let ordered_arr: Record<string, any> = {};
            newData.forEach((e: any) => {
                const key = e.metricType?.name;                   
                if (!ordered_arr[key]) ordered_arr[key] = [];
                // ordered_arr[key].push(e.metric);
                if (!checkMetricExists(ordered_arr[key], e.metric.metricId)) ordered_arr[key].push(e.metric);
            });
            setMetricsOrdered(ordered_arr)
            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            setIsLoading(false);
        }
      };

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div>
            <div className="row">
                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                <InfiniteScroll
                    dataLength={dataLen}
                    next={fetchData}
                    hasMore={hasNext} // Replace with a condition based on your data source
                    loader={<p></p>}
                    endMessage={<p></p>}
                    style={{overflowX: 'hidden', padding: '2px'}}
                >
                    <div className="row">
                        {Object.keys(metricsOrdered).length > 0 ? (
                            Object.keys(metricsOrdered).map((key) => (
                                <>
                                    <div className="row" style={{padding: '20px 10px'}} key={`d1-${key}`}>
                                        <SectionHeaderMinUC title={key}  />
                                    </div>
                                    <div className="row" key={`d2-${key}`}>
                                        {metricsOrdered[key].map((child:any, index:number) => (
                                            <div className="col-md-4" style={{ paddingBottom: '15px'}} key={`p1-g-${index}`}>
                                                <MetricCard item={child} key={`met-${index}`}/>
                                            </div>
                                        ))}
                                    </div>
                                </>                        
                            ))
                        ) : (<><NoDataNotice item='Metrics'/></>)}
                    </div>
                </InfiniteScroll>
                </>)}

                {/* {Object.keys(metricsOrdered).length > 0 ? (
                    Object.keys(metricsOrdered).map((key) => (
                        <>
                            <div className="row" style={{padding: '20px 10px'}}>
                                <SectionHeaderMinUC title={key}  />
                            </div>
                            <div className="row">
                                {metricsOrdered[key].map((child:any, index:number) => (
                                    <div className="col-md-4" style={{ paddingBottom: '15px'}} key={`p1-g-${index}`}>
                                        <MetricCard item={child} />
                                    </div>
                                ))}
                            </div>
                        </>                        
                    ))
                ): (<><NoDataNotice item='Metrics'/></>)} */}
            </div>
        </div>
    );
}
export default CategoryMetrics;