import React, { useEffect, useState } from "react";
// import { HeaderBanner } from "../components/banners/HeaderBanner";
import { NavbarMini } from "../components/NavbarMini";
import { Outlet, useParams } from "react-router-dom";
import '../components/UtilStyles.css';
import http from "../lib/Http";
import axios from "axios";
import { HeaderBannerSector } from "../components/banners/HeaderBannerSector";

const SectorMain: React.FC = () => {
    const {id} = useParams();

    const links: LinkProps[] = [
        {name: 'Metrics', url: `/sector/${id}/metrics`},
        {name: 'Categories', url: `/sector/${id}/categories`},
        {name: 'Photos', url: `/sector/${id}/photos`},
        {name: 'Videos', url: `/sector/${id}/videos`},
        {name: 'Audios', url: `/sector/${id}/audios`},
        {name: 'Infographics', url: `/sector/${id}/reports`},
        {name: 'Articles', url: `/sector/${id}/articles`},
    ]

    const [sector, setSector] = useState<Sector>();
    const [header, setHeader] = useState<string>('');
    const [description, setDescription] = useState('');
    const [mainMetric, setMainMetric] = useState<MetricLink>();

    useEffect(() => {
        const requests = [
            `Themes/${id}`,
            `MetricLink/theme/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((res1, mets) => {
                setSector(res1.data);
                mets.data.map((e: any)=> {
                    if(e.isMainMetric === true) { setMainMetric(e ?? null); return; }
                });
                setDescription(res1.data?.description)
                setHeader(res1.data?.name)
            })
        )
        .catch((error:any) => console.log(error));
    }, []);
    
    return (
        <>
        <HeaderBannerSector id={sector?.themeId!} type="sector" image={sector?.thumbnailUrl} title={header} desc={description} metric={mainMetric?.metric} />
        <NavbarMini navs={links}/>        
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{backgroundColor: '#fcfcfc'}}>
                        <Outlet context={[sector, description]}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SectorMain;