import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
// import 'chart.js/auto';
// import {Doughnut} from 'react-chartjs-2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeholderImg from "../../assets/hospital1.jpg";
import axios from 'axios';
import { getProjectMultimediaPhoto } from '../../lib/Funcs';
import { DateFmt } from "../../components/ItemExtras";
// ChartJS.register(ArcElement);

const tDec = {textDecoration: 'none'}
const tSize = {fontSize: '20px', color: '#000'}

function getCategory(url: string, catName: string, callback: (v:any) => void) {
    http.post(url, {"categoryName": catName})
            .then(response => {callback(response.data); /*console.log('check', response.data)*/})
            .catch(err => console.log('Catc Error', err))
}
function setCatUrl(catId: any) {
    return (catId != null) ? `/Category/${catId}` : '/Categories'
}

const ProjectSingle = () => {
    const [projectLink, setProjectLink] = useState<any>();
    const [cat1, setCat1] = useState()
    const [cat2, setCat2] = useState()
    const [cat3, setCat3] = useState()
    const [relatedProjects, setRelatedProjects] = useState<any[]>([])
    const [projPhotos, setProjPhotos] = useState<any[]>();
    // const [project, setProject] = useState<any>();
    var {id} = useParams();
        
    useEffect(() => {
        const request = [
            `Project/${id}`,
        ].map(url => http.get(url));

        axios.all(request)
            .then(
                axios.spread((proj, plink) => {
                    setProjectLink(proj.data);
                    let projectImages: any[] = getProjectMultimediaPhoto(proj.data?.projectMultimedia);
                    setProjPhotos(projectImages);
                    
                    const populateData = (data: any) => {setCat1(data.categoryId)}
                    getCategory('Category/name', proj.data.category1, populateData)
                    getCategory('Category/name', proj.data.category2, (data: any) => {setCat2(data.categoryId)})
                    getCategory('Category/name', proj.data.category3, (data: any) => {setCat3(data.categoryId)})

                    // http.get(`Project/parentProject/${proj.data.projectId}`)
                    http.get(`Project/parentProject/${proj.data.parentProjectId}`)
                        .then(r => {
                            // sort current project out of list
                            let relProj:any[] = []
                            r.data.map((d: any) => {
                                if (d.projectId != proj.data.projectId) relProj.push(d)
                            })
                            setRelatedProjects(relProj)
                        })
                        .catch(err => console.log('ProjErr', err))
                    // setProject(proj.data)
                    // console.log(proj.data, proj.data.inherited)
                    // console.log(proj.data, projectLink.inherited)
                })
            )
            .catch(err => console.log('Err', err));
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            
            {projectLink && (
                <>
                <div>&nbsp;</div>
                <h3 className='page-header font-wc-semi'>{projectLink.name}</h3>
                <div className="row" style={{paddingTop: '20px'}}>
                    {projPhotos!.length > 0 && projPhotos?.slice(0, 2).map((imgUrl: any, idx: number) => (
                        <>
                            <div className={(projPhotos!.length > 1) ? 'col-6': 'col-12'} key={`kt-${idx}`}>
                                <LazyLoadImage
                                    src={imgUrl.url} 
                                    placeholderSrc={placeholderImg}
                                    effect='blur'
                                    alt="..."
                                    className={"proj-img"}
                                />
                            </div>
                        </>
                    ))}
                </div>
                <div className="row" style={{paddingTop: '20px'}}>
                    <div className="col-md-12" style={{ paddingBottom: '25px' }}>
                        <div className="card metric-st card-shadow" style={{padding: '8px'}}>
                            {/* <h3>{projectLink.projectType} <span style={{color: '#888'}}>-</span> {projectLink.description}</h3> */}
                            <h3>{projectLink.projectType} <span style={{color: '#888'}}>-</span> {projectLink.projectDetails}</h3>
                            <p style={{color: '#888'}}><i className="fa fa-map-marker" style={{color: 'red'}}>&nbsp;</i>&nbsp;{projectLink.region},&nbsp;&nbsp;{projectLink.mmda},&nbsp;&nbsp;{projectLink.location}</p>
                            <p><span style={{color: '#888'}}>Beneficiaries:&nbsp;</span>&nbsp;<strong style={{color: '#000'}}>{projectLink.beneficiaries}</strong></p>
                            <p><span style={{color: '#888'}}>Inherited&nbsp;Project:&nbsp;</span>&nbsp;<strong style={{color: '#fff'}} className="badge bg-success">{projectLink.inherited != null ? projectLink.inherited : "N/A"}</strong></p>
                        </div>
                    </div>
                </div>
                <div className="row" style={{paddingTop: '20px'}}>
                    <div className="col-md-6" style={{ paddingBottom: '25px' }}>
                        <div className="card card-shadow">
                            <div className="card-body">
                                {/* <h3><span style={{color: '#888'}}>Project&nbsp;Status: </span>{projectLink.percentageCompleted != null ? `${projectLink.percentageCompleted}% ${projectLink.projectStatus}` : 'N/A'}</h3> */}
                                <h3><span style={{color: '#888'}}>Project&nbsp;Status: </span>{projectLink.percentageCompleted != null ? `${projectLink.percentageCompleted}% ${"Completed"}` : 'N/A'}</h3>
                                <div style={{clear: 'both'}}>&nbsp;</div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h3>{projectLink.commencementDate}<br/><span style={{color: '#888', fontSize: '20px'}}>Start Date</span></h3>
                                        {/* <h3>{projectLink.commencementDate}</h3>
                                        <p style={{color: '#888'}}>Start Date</p> */}
                                    </div>
                                    <div className='col-md-6'>
                                        <div style={{textAlign: 'right'}}>
                                            {/* <h3>{DateFmt(projectLink.completionDate)}<br/><span style={{color: '#888', fontSize: '20px'}}>End Date</span></h3>                  */}
                                            <h3>{projectLink.completionDate}<br/><span style={{color: '#888', fontSize: '20px'}}>End Date</span></h3>                 
                                            {/* <h3>2023/04/17<br/><span style={{color: '#888', fontSize: '20px'}}>End Date</span></h3>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={{ paddingBottom: '25px' }}>
                        <div className="card metric-st card-shadow">
                            <div className="card-body cat-badge" >
                                <h3>{projectLink.ministry}&nbsp;</h3>
                                <div style={{clear: 'both'}}>&nbsp;</div>                                
                                <h3 style={{fontSize: '22px', lineHeight: '30px'}}>Categories:&nbsp;
                                    <a style={tDec} href={setCatUrl(cat1)} className="badge bg-danger">{projectLink.category1}</a> <a href={setCatUrl(cat2)} style={tDec} className="badge bg-danger">{projectLink.category2}</a> <a href={setCatUrl(cat3)} style={tDec} className="badge bg-danger">{projectLink.category3}</a></h3>
                                    {/* <span className="badge bg-success">{projectLink.category1}</span> <span className="badge bg-warning">{projectLink.category2}</span> <span className="badge bg-danger">{projectLink.category3}</span></h3> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{paddingTop: '20px'}}>
                    <div className="col-md-12" style={{ paddingBottom: '25px' }}>
                        <div className="card metric-st card-shadow" style={{padding: '8px'}}>
                            <h3><span style={{color: '#888', fontSize: '20px'}}>Related&nbsp;Projects</span></h3>
                            {relatedProjects.length > 0 && (
                                <ol style={{fontSize: '24px', textDecoration: 'none'}}>
                                    {relatedProjects.map(item => (
                                        <li ><a href={`/project/${item.projectId}`} style={{...tDec, ...tSize}}>{item.name}</a></li>
                                    ))}
                                </ol>
                            )}
                        </div>
                    </div>
                </div>                
                <div className="row" style={{paddingTop: '20px'}}>&nbsp;</div>
                </>
            )}
        </div>
    </>;
}
export default ProjectSingle;