import ReactPlayer from "react-player";

export default function ProjectSourceItems({ currentItems, routePrefix }: {currentItems: Project[], routePrefix: string}) {
    return (
      <>
        <table className='table table-striped table-bordered'>
            <colgroup>
                <col style={{width: '2%'}} />
                <col style={{width: '60%'}} />
                <col style={{width: '10%'}} />
                <col style={{width: '20%'}} />
                <col style={{width: '8%'}} />
            </colgroup>
            <thead>
                <tr className="table-primary1" style={{backgroundColor: '#58b1e8', color: '#ffffff'}}>
                    <th>#</th>
                    <th>Project Name</th>
                    <td>Region</td>
                    <td>Location</td>
                    <td>Status</td>
                </tr>
            </thead>
            <tbody>
                {currentItems.map((item, i) => (
                <tr>
                    <th>{++i}</th>
                    <th><a href={`/project/${item.projectId}`} style={{textDecoration: 'none'}}>{item.name}</a></th>
                    <td>{item.region}</td>
                    <td>{item.location}</td>
                    <td>{item.projectStatus}</td>
                </tr>
                ))}
            </tbody>
        </table>
      </>
    );
}