import { Link } from 'react-router-dom';
import errorImage from '../assets/leaky_error.png';

const ErrorPage = () => {
    return <>        
        <div className="container" style={{padding: '15px 0'}}>
            <img src={errorImage} style={{height: '1calc(100vh - 250px)'}}/>
            <h2 style={{textAlign:"center"}}>Sorry, we had a leak in our boat</h2>
            <div>&nbsp;</div>
            <p style={{textAlign:"center"}}>
              <Link to="/" style={{}} className="btn btn-outline-info">Go to Home </Link>
            </p>
        </div> 
        {/* <div className="container" style={{padding: '15px 0'}}>
            <div className='row'>
                <div className='col-6'>
                    <img src={errorImage}  alt="Page not found image" style={{height: '1calc(100vh - 250px)'}}/>
                </div>
                <div className='col-6' style={{border: 'none'}}>
                    <div style={{position: 'relative', border: '1px solid green'}}>
                        <div style={{position: 'absolute', bottom: '1px', border: '1px solid red'}}>
                            <h2 style={{textAlign:"center"}}>Sorry, we had a leak in our boat</h2>
                            <div>&nbsp;</div>
                            <p style={{textAlign:"center"}}>
                                <Link to="/" style={{}} className="btn btn-outline-info">Go to Home </Link>
                            </p>
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </div>

            </div>
        </div>  */}
    </>;
}
export default ErrorPage;