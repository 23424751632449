import React from 'react';
import './App.css';
import { HeaderTop } from './components/headers/HeaderTop';
import { Header } from './components/headers/Header';
import Main from './MainRoute';
import Footer from './components/Footer';
import Layout from './Layout';
import { HeaderNewDisabled } from './components/headers/HeaderNewDisabled';
import AuthProvider from './pages/auth/AuthProvider';

const logoItem = {
  padding: 0, paddingTop: '12px'
}

function App() {
  
  return (
    <>    
      <div>
        <HeaderTop />
        <Header />
        {/* <HeaderNewDisabled /> */}
        <Layout contentPanel={<Main />} />        
        <Footer />
      </div>
    </>
  )
}

export default App;
