import './MetricCard.css'
import { MetricCountUp } from '../metric/MetricCountUp';
import { RWebShare } from 'react-web-share';

const MetricCardRegion = ({item, link}:{item: RegionalMasterMetric, link?: string}) => {
    const mlink = (link != null) ? link : `/region/metrics/${item.metricId}`;
    // const socialLink = `${process.env.REACT_APP_API_URL ?? ''}/region/metrics/${item.metricId}`;
    const socialLink = `${process.env.REACT_APP_BASE_URL ?? ''}region/metrics/${item.metricId}`;
    
    return (
        <div className="card metric-st card-shadow">
            <div className="card-header c-title" style={{fontSize: '18px', textTransform: 'uppercase', border: 'none'}}>
                {/* {item.name} */}
                <div className='row'>
                    <div className='col-md-9'>
                        {item.name}
                    </div>
                    <div className='col-md-1'>
                        <RWebShare
                            data={{
                                text: "Share - PerformanceTracker",
                                url: socialLink,
                                title: "PerformanceTracker",
                            }}
                            onClick={() =>
                                console.log("shared successfully!")
                            }
                        >
                            <button className='btn btn-outline-primary btn-sm'><i className="fa fa-share-alt" aria-hidden="true"></i></button>
                        </RWebShare>
                    </div>
                </div>
            </div>
            <div className="card-body" style={{textAlign: 'center'}}>
                {(item.percentage !== null) ? (
                    <a href={mlink} style={{textDecoration: 'none'}}>
                        <p style={{padding: '0px', margin: '0', fontSize: '22px', border: 'none' }}>
                            {
                                (item.direction.toLowerCase() === 'increase') ? 
                                <i className="fa fa-caret-up" style={{color: 'blue'}} aria-hidden="true">&nbsp;</i>
                                :<i className="fa fa-caret-down" style={{color: 'blue'}} aria-hidden="true">&nbsp;</i>                                            
                            }
                            <span style={{color: (item.direction.toLowerCase() === 'increase')? 'blue' : 'blue'}}>{item.percentage}%</span>&nbsp;
                            {item.comparison && (<span>&nbsp;{item.comparison}</span>)}
                        </p>
                    </a>
                ):(<p style={{ margin: '0' }}>&nbsp;</p>)}

                <p style={{padding: '0', fontSize: '30px', margin: '0', }}>
                    {item.measure != null && (<>
                        <a href={mlink} style={{textDecoration: 'none', color: '#000000', fontSize: '70px'}} >

                            {item.measure.indexOf(':') > 0 ? 
                                <>{item.measure.split(':')[0]}:<MetricCountUp measure={item.measure.split(':')[1]} /></>                                     
                                : (item.measure.indexOf('.') > 0) 
                                ? <><MetricCountUp measure={item.measure.split('.')[0]}/>.<MetricCountUp measure={item.measure.split('.')[1]} /></> :
                                <><MetricCountUp measure={item.measure} /></>}
                        </a>
                        {(item.unitOfMeasure === 'NA')? '': item.unitOfMeasure}                        
                    </>)}
                </p>
                <div style={{height: '60px', overflow: 'hidden',}}>
                    <p style={{fontSize: '20px'}} className="txt-lite"> {item.description} {item.year}</p>
                </div>
                {/* Source link/button */}
                <div style={{paddingTop: '5px'}}>
                    <a style={{width: '100%'}} href={mlink} className="btn btn-outline-primary btn-sm">{(item.sources) ? 'Source: '+item.sources : 'Source'}</a>
                    {/* <a style={{width: '100%'}} href={scLink} className="btn btn-outline-primary btn-sm">{(item.sources) ? item.sources : 'Source'}</a> */}
                </div>
            </div>
        </div>
    )
}

export default MetricCardRegion;