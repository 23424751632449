import moment from "moment"

const pstyle = {paddingLeft: '15px', position: 'relative'};

const ItemDate = ({date, /*cstyle*/}:{date: string, /*cstyle: {}*/}) => {
    // Moment(u.created_at.format('yyyy mm dd hh m'))
    return (
        <div style={{paddingLeft: '15px', position: 'relative'}}>
            <div style={{position: 'absolute', color: 'black', left: '20px', bottom: '2px'}}>
                <p style={{fontSize: '14px', color: '#0aa6b0', height: '3px', lineHeight: '23px'}}>{moment(date).format('DD . MM . YY') }</p>
            </div>
        </div>
    )
}

const ItemDateFluid = ({date, cstyle = pstyle}:{date: string, cstyle: object}) => {
    return (
        <div style={cstyle}>
            <p style={{fontSize: '14px', color: '#0aa6b0', height: '3px', lineHeight: '23px'}}>{moment(date).format('DD . MM . YY') }</p>            
        </div>
    )
}

const DateFmt = ({date}:{date: any}) => {
    return (<>{(date != null || date)   ? ('cool') : '' }</>)
    // return (<>{date != null && moment(date).format('DD . MM . YYYY') }</>)
}

/**
 * Definition for Author and Ceated date
 * @param author 
 * @returns 
 */
const AuthorNDate = ({author, image, date}:{author: string, image: string, date: string}) => {
    return (
        <div style={{paddingLeft: '15px', position: 'relative'}}>
            <img src={image} style={{width: '40px', height: '40px',}} className="rounded-circle" alt="Author image..."/>
            <div style={{position: 'absolute', color: 'black', left: '70px', bottom: '2px'}}>
                <h5 style={{fontSize: '17px', fontWeight: '600', padding: '0', height: '9px', lineHeight: '19px'}}>{author}</h5>
                <p style={{fontSize: '14px', color: '#0aa6b0', height: '3px', lineHeight: '23px'}}>{date}</p>
            </div>
        </div>
    )
}

const ViewMore = ({name, url}:{name: string, url: string}) => {
    return (
        <div className='row'>
            <div className='col-md-9 col-lg-10'></div>
            <div className='col-md-3 col-lg-2'>
                <a style={{fontSize: '25px', textDecoration: 'none', color: '#f00', padding: '0px'}} href={url}>View More {name}</a>
            </div>
        </div>
    )
}



export { ItemDate, ItemDateFluid, AuthorNDate, ViewMore, DateFmt };