// import { Doughnut } from "react-chartjs-2";
import "./ProjectSearchCard.css";
// import placeholderImg from "../../assets/hospital1.jpg";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import { ProjectDoughnut } from "../chart/ProjectDoughnut";

const pNameStl = {
    fontSize: '20px', 
    fontWeight: '700', 
    textDecoration: 'none',
    color: '#000000',
    padding: '0px',
}

export const ProjectSearchCard = ({item}: {item: Project}) => {
    return (<>
        <div className="card-shadow">
            <div className='row pcd-row' style={{position: 'relative'}}>
                {/* <div> */}
                    <div className="col-10 col-md-10 pcd-crd2">
                        <p style={{textTransform: 'uppercase', fontSize: '14px', fontWeight: '700', padding: '0px', margin: '0',}}>{item.projectType}</p> 
                        <a href={`/project/${item.projectId}`} style={pNameStl}>{item?.name}</a>
                        {(item?.description != null) && (<>
                            <p style={{color: '#888888', fontSize: '19px'}}>{item?.description}</p>
                            {/* { truncText(item?.name, 60) } */}
                        </>)}
                        {(item?.location != null) && (<>
                            <p style={{fontSize: '14px', margin: '0px'}}>
                                <i className="fa fa-map-marker" style={{color: 'red'}}>&nbsp;</i>&nbsp;{item?.region},&nbsp;{item?.mmda}&nbsp;- {item?.location}
                            </p>
                        </>)}
                        {(item?.beneficiaries != null) && (<>
                            <p style={{fontSize: '14px', marginTop: '0px', padding: '0px'}}><span style={{color: '#888'}}>Beneficiaries:</span> {item?.beneficiaries}</p>
                        </>)}
                    </div>
                    <div className="col-2  col-md-2">
                        <div style={{}}>
                            <ProjectDoughnut percentageCompleted={item.percentageCompleted} showLg={true}/>
                            <p style={{border: 'none', fontSize: '14px', fontWeight: 'bold', textAlign: 'center'}}>{item.percentageCompleted ?? '0'}%</p>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </div>
        <div>&nbsp;</div>
    </>)
}

// export default ProjectSearchCard;