import { ChangeEvent, useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import ProjectCard from '../../components/cards/ProjectCard';
import axios from 'axios';
// import { getProjMultimediaById } from '../../lib/FindHelpers';
import { extractProjectTypes, getProjectMultimediaPhoto } from '../../lib/Funcs';
import { ProjectTypeFilter } from '../../components/filters/ProjectTypeFilter';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import ProjectItems from '../../components/pagination/ProjectItems';
import ReactPaginate from 'react-paginate';

const MinistryProjects = () => {
    const {id} = useParams();
    const [projects, setProjects] = useState<any[]>([]);
    const [projectTypes, setProjectTypes] = useState<string[]>([]);
    const [ministry, setMinistry] = useState<Ministry>();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const fetchData = () => {
            // requests
            const requests = [
                `Project/ministry/${id}`,
                `Ministry/${id}`,
            ].map(url => http.get(url));

            axios.all(requests)
                .then(
                    axios.spread((projs, mins) => {
                        let ptypes = extractProjectTypes(projs.data)
                        setProjectTypes(ptypes)
                        setProjects(projs.data);
                        setMinistry(mins.data);
                        setIsLoading(false)
                    })
                )
                .catch(err => console.log(err))

        }
        fetchData();
    }, [])

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;

        let data: {'ministry': string,  'projectTypes'?: string[], 'searchColumns': string[]} = {'ministry': ministry?.name!, 'searchColumns': ['ministry']}
        if (selectedOption !== '') {
            data['projectTypes'] = [selectedOption]
            data.searchColumns.push('projecttype')
        }
        
        http.post(`Search/project/multiple_params`, data)
            .then((res: any) =>  {
                setProjects(res.data);
            })
            .catch((error:any) => {console.log(error);});
    };

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = projects.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(projects.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % projects.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <ProjectItems currentItems={currentItems}/>
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    return (
        <div>
            <div>&nbsp;</div>
            <ProjectTypeFilter projTypes={projectTypes} handler={handleSelectChange} />
            <div className="row" style={{padding: '20px 0px'}}>
                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                    <PaginatedItems itemsPerPage={20} />
                </>)}
            </div>
        </div>
    );
}

export default MinistryProjects;