// import { HeaderBanner } from '../components/HeaderBanner';
import banner from '../assets/banners/about_banner.png';
import placeholderImg from '../assets/media_banner.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './About.css';

const About = () => {
    return <>
        {/* <HeaderBanner image={banner} title={''} /> */}
        <div className="my-cnt" style={{'border': 'none'}}>
            <LazyLoadImage 
                src={banner} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                className='abt-img'
            />
            <div className='o-head'>
                <h3>{''}</h3>
            </div>
        </div>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                <div style={{padding: '50px 0px 10px 10px'}}>
                    <h3 style={{textTransform: 'uppercase'}}>About performance tracker</h3>
                </div>
                <div>
                    <p>
                    Welcome to Performance Tracker! Our platform is designed to give Ghanaian citizens easy access to valuable information on the performance of the government of Ghana since 2017. 
                    <br/>Our achievement records come from 30 ministries, organized to show projects undertaken by government; as well as data on the outcome, impact, and beneficiaries of government interventions, 
                    enabling users to assess the effectiveness of these interventions. 
                    We believe showcasing our achievements can enhance transparency and accountability and build public trust in the government's ability to deliver on its promises.

                    </p>
                    <p>
                    You will find a wide range of content, including articles, videos, audio, pictures, infographics, and metrics related to the government's performance. This information can be used by researchers, journalists, and policymakers to assess the effectiveness of government programs and identify areas where more work needs to be done.  

                    </p>
                    <p>
                    In conclusion, Performance Tracker is an essential resource for anyone interested in tracking the progress of the government since 2017. Our platform reflects the government's commitment to transparency, accountability, and the well-being of its citizens. We believe that our achievements define our resolve, and we invite you to explore our platform to learn more about our government's performance and its impact on our nation.
                    </p>
                </div>
                <div style={{paddingBottom: '90px'}}>&nbsp;</div>                
            </div>
        </div>
    </>;
}
export default About;