import React, { useState } from "react";
import './NavbarMini.css';
import { NavLink } from "react-router-dom";

export const NavbarMini = ({navs}:{navs: LinkProps[]}) => {

  const [selMenuItem, setSelMenuItem] = useState(null);
  
    return (
      <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light mini-nav">
        <div className="container">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#subHeader" aria-controls="subHeader" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="subHeader">
            {/* <li className="nav-item nav-items" style={{listStyle: 'none'}}>
              <a className="nav-link nav-links ucase min-lnk" href={navs[0].url.substring(0, navs[0].url.lastIndexOf('/'))}><i className="fa fa-home"></i></a>
            </li> */}
            {(navs.length > 0) && 
              <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                {navs.map((menuItem, index) => (
                  <li className="nav-item nav-items" key={index}>
                    {/* <a className="nav-link nav-links ucase min-lnk" href={menuItem.url} onClick={_handleClick(menuItem)}>{menuItem.name}</a> */}
                    <NavLink 
                      to={menuItem.url}
                      className={({ isActive }) => (isActive ? "link-active nav-link nav-links ucase min-lnk" : "nav-link nav-links ucase min-lnk")}
                    >{menuItem.name}</NavLink>
                  </li>
                ))}              
              </ul>
            }
          </div>
        </div>
      </nav>
      </>
    );
}