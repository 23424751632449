import '../../components/UtilStyles.css';                
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Audio } from '../utils/CustomTypes';
import http from '../../lib/Http';
import { ArticleCard } from '../../components/cards/ArticleCard';
import NoDataNotice from '../../components/NoDataNotice';

const CategoryArticles = () => {
    const [articles, setArticles] = useState<ArticleLink[]>([]);
    const {id} = useParams();
    
    useEffect(() => {
        http.get<ArticleLink[]>(`ArticleLink/category/${id}`,)
              .then((res: any) => {
                setArticles(res.data);
                console.log(res.data)
              });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc', marginTop: '20px'}}>
            <div className="row" style={{marginBottom: '20px', paddingTop: '10px'}}>
                {articles.length > 0 ?(                        
                    articles.map((item, index) => (
                        <div className="col-md-4" key={`psk-${index}`} style={{paddingBottom: '25px'}}>
                            <ArticleCard item={item.article} />
                        </div>
                    ))
                ): (<><NoDataNotice item='Articles'/></>)}
            </div>
        </div>        
    </>;
}

export default CategoryArticles;