import React, { useEffect, useState } from "react";
// import { HeaderBanner } from "../components/banners/HeaderBanner";
import { NavbarMini } from "../components/NavbarMini";
import { Outlet, useParams } from "react-router-dom";
import '../components/UtilStyles.css';
import http from "../lib/Http";
import axios from "axios";
// import { HeaderBannerSector } from "../components/banners/HeaderBannerSector";
import { HeaderBannerMore } from "../components/banners/HeaderBannerMore";

const MinistryMain: React.FC = () => {
    const {id} = useParams();

    const links: LinkProps[] = [
        {name: 'Metrics', url: `/ministry/${id}/metrics`},
        {name: 'Projects', url: `/ministry/${id}/projects`},
        {name: 'Photos', url: `/ministry/${id}/photos`},
        {name: 'Videos', url: `/ministry/${id}/videos`},
        {name: 'Audios', url: `/ministry/${id}/audios`},
        {name: 'Infographics', url: `/ministry/${id}/reports`},
        {name: 'Articles', url: `/ministry/${id}/articles`},
        {name: 'Project Summary', url: `/ministry/${id}/projsummary`},
        {name: 'Map', url: `/ministry/${id}/projectsmap`},
    ]

    const [ministry, setMinistry] = useState<Ministry>();
    const [header, setHeader] = useState<string>('');
    const [description, setDescription] = useState('');
    const [mainMetric, setMainMetric] = useState<MetricLink>();

    useEffect(() => {
        const requests = [
            `Ministry/${id}`,
            `Ministry/metrics/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((res1, mets) => {
                setMinistry(res1.data);
                // setMainMetric(mets.data[0] ?? null);
                setDescription(res1.data?.description)
                setHeader(res1.data?.name)
            })
        )
        .catch((error:any) => console.log(error));
    }, []);
    
    return (
        <>
        {/* <HeaderBannerSector image={sector?.thumbnailUrl} title={header.replace(/ /g, '&nbsp;')} desc={description} /> */}
        <HeaderBannerMore image={ministry?.thumbnailUrl} title={header} desc={description} metric={mainMetric?.metric} />
        <NavbarMini navs={links}/>        
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{backgroundColor: '#fcfcfc'}}>
                        <Outlet context={[ministry, description]}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default MinistryMain;