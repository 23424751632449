import moment from 'moment';
import './ArticleCard.css';
import ModalImage from "@avidian/react-modal-image";
// import ModalImage from "react-modal-image";

export const PhotoCard = ({item}: {item: Photo}) => {
    return (
        <>
        <div className="card acard" style={{border: 'none', paddingBottom:'15px'}}>
            <ModalImage
                small={item.thumbnailUrl}
                medium={item.thumbnailUrl}
                alt={item.name}                
            />
            <div className="card-body acard-bd" style={{}}>
                <div style={{ margin: '0', paddingBottom: '60px',}}><a className="sub-hdr" href={'#'}>{item.name}</a></div>
            </div>
        </div>
        </>
    )
}