import { LazyLoadImage } from "react-lazy-load-image-component"
import './CarouselParts.css'

const CarouseItem = ({idx, thumbnailUrl, placeholderImg, title, desc}: {idx: number, thumbnailUrl: string, placeholderImg: string, title: string, desc: string}) => {
    return (<>
        <div key={`gc-${idx}`} className={idx === 0 ?"carousel-item active" : "carousel-item"} >
            <LazyLoadImage 
                src={thumbnailUrl ?? ""} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                className={"c-img proj-img"}
            />                               
            {/* <div className="carousel-caption d-none d-md-block d-flex cat-caption" style={{position: 'absolute', top: '220px', width: '58%', border: 'solid 1px violet'}}> */}
            {/* <div className="carousel-caption d-none d-md-block d-flex cat-caption1" style={{position: 'relative', left: '0px', top: '-300px', width: '58%', border: 'none'}}> */}
            <div className="carousel-caption d-none d-md-block d-flex" style={{top: '68%', width: '58%', border: 'none'}}>
                {/* <div style={{position: 'relative', width: '80%', left: '-170px' }}> */}
                <div style={{position: 'relative', width: '80%', left: '-240px' }}>
                    <div style={{backgroundColor: 'rgba(2,2,2, 0.5)', padding: '5px 7px', borderRadius: '0px 7px 7px 0px'}}>
                        <div style={{ width: '70%', position: 'relative', left: '30%'}}>
                            <h3 className="font-wc-semi" style={{fontSize: '27px', textAlign: 'left', lineHeight: 'normal'}}>{title}</h3>
                            <p style={{textAlign: 'left', fontSize: '20px', lineHeight: 'normal'}}>{desc}</p>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div>&nbsp;</div>
            </div>
        </div>
    </>)
}

const CarouselSliderButton = ({idx, carsId, }: {idx: number, carsId: string}) => {
    return (<>
        <button type="button" data-bs-target={carsId} data-bs-slide-to={`${idx}`} className={idx === 0 ?"active" : ""} aria-current="true" key={`d-btn-${idx}`}></button>
    </>)
}

const CarouselControlButton = ({carsId, idx}: {idx?: number, carsId: string}) => {
    return (<>
        <button className="carousel-control-prev" type="button" data-bs-target={carsId} data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden" >Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target={carsId} data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </>)
}

export {CarouseItem, CarouselSliderButton, CarouselControlButton}

