import '../../components/UtilStyles.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../lib/Http';
import { InfographicCard } from '../../components/cards/InfographicCard';
import NoDataNotice from '../../components/NoDataNotice';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

const CategoryReports = () => {
    const [reports, setReports] = useState<InfographicLink[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const {id} = useParams();

    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [isLoadingPg, setIsLoadingPg] = useState(false);
    const [dataLen, setDataLen] = useState(0);
    const [hasNext, setHasNext] = useState(true);

    const fetchData = async () => {
        setIsLoadingPg(true);
        setError(null);
      
        try {
            const response = await http.get(`InfographicLink/category/paged/${id}?Page=${page}&ItemsPerPage=9`,);
            const data = await response.data ;
            let newData = [...reports, ...data]
            setDataLen(newData.length)

            setReports(newData);
            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            setIsLoadingPg(false);
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        fetchData()

    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div>&nbsp;</div>
            <div className='row'>

                {isLoading ? (<>
                        <LoadingSpinner />
                    </>) : (<>
                    <InfiniteScroll
                        dataLength={dataLen}
                        next={fetchData}
                        hasMore={hasNext}
                        loader={<p></p>}
                        endMessage={<p>&nbsp;</p>}
                        style={{overflowX: 'hidden', padding: '2px'}}
                    >
                        {reports.length > 0 ? (  
                            <div className='row'>
                            {reports.map((item, index) => (
                                <div className="col-md-4" style={{border: 'none', paddingBottom: '25px'}} key={`ic-${index}`}>
                                    <InfographicCard item={item.infographic} />
                                </div>
                            ))}
                            </div>
                        ): (<><NoDataNotice item='Infographics'/></>)}
                    </InfiniteScroll>
                </>)}

            </div>
        </div>        
    </>;
}
export default CategoryReports;