import '../../components/UtilStyles.css';
import ReactPlayer from 'react-player';
import http from '../../lib/Http';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import NoDataNotice from '../../components/NoDataNotice';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

const RegionAudios = () => {
    const {id} = useParams();
    const [audios, setAudios] = useState<AudioLink[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        http.get(`AudioLink/region/${id}`)
            .then((res) => {
                setAudios(res.data);
                setIsLoading(false)
            });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className='row' style={{padding: '10px 0px'}}>
            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
            {audios.length > 0 ? (                                        
                audios.map((item, index) => (
                    <div className="col-md-4">
                        <div className="card" style={{ border: 'none'}} key={index}>
                            <ReactPlayer url={item.audio.audioUrl} height={'240px'} width={'100%'}/>
                        </div>
                    </div>
                ))
            ) : (<><NoDataNotice item='Audios' /></>)}
            </>)}
            <div>&nbsp;</div>
            </div>
        </div>
    </>;
}
export default RegionAudios;