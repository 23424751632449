import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import NoDataNotice from '../../components/NoDataNotice';
import { PhotoCard } from '../../components/cards/PhotoCard';
import NoDataNotice from '../../components/NoDataNotice';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

// const photos: Photo[] = [
//     {thumbnailUrl: freeshs, name: 'FREE SHS', description: 'What\'s the great impart?', createdAt: '23/12/2022'},
//     {thumbnailUrl: hosp, name: 'HOSPITAL PROJECTS', description: '32 major Hospital project ongoing in Ashanti.', createdAt: '23/12/2022'},
//     {thumbnailUrl: irrg, name: 'IRRIGATION PROJECTS', description: 'Life transformig initiatives.', createdAt: '23/12/2022'},
//     {thumbnailUrl: hosp, name: 'HOSPITAL PROJECTS', description: '32 major Hospital project ongoing in Ashanti.', createdAt: '23/12/2022'},
//     {thumbnailUrl: irrg, name: 'IRRIGATION PROJECTS', description: 'Life transformig initiatives.', createdAt: '23/12/2022'},
// ];

const MinistryPhotos = () => {

    const [photos, setPhotos] = useState<PhotoLink[]>([]);
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [dataLen, setDataLen] = useState(0);
    const [hasNext, setHasNext] = useState(true);

    const fetchData = async () => {
        setError(null);
      
        try {
            const response = await http.get(`PhotoLink/ministry/paged/${id}?Page=${page}&ItemsPerPage=10`);
            const data = await response.data ;
            let newData = [...photos, ...data]
            setDataLen(newData.length)

            setPhotos(newData);
            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        fetchData()
        
        // http
        //     .get<PhotoLink[]>(`PhotoLink/ministry/${id}`)
        //     .then((res) => {
        //         // console.log('photos result', res.data);
        //         setPhotos(res.data);
        //     });
    }, [])

    return <>
        <div className="row" style={{padding: '20px 0'}}>
            
            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
                <InfiniteScroll
                    dataLength={dataLen}
                    next={fetchData}
                    hasMore={hasNext}
                    loader={<p></p>}
                    endMessage={<p>&nbsp;</p>}
                    // style={{overflowX: 'hidden', padding: '2px'}}
                    style={{overflow: 'hidden', padding: '2px'}}
                >
                    {photos.length > 0 ? (
                    <>
                    <div className="row">

                        {photos.map((item, index) => (
                            <div className="col-md-4" key={`ic-${index}`}> 
                                <PhotoCard item={item.photo} />
                            </div>
                        ))}
                        </div>
                    </>
                    ): (<><NoDataNotice item='Videos'/></>)}
                </InfiniteScroll>
            </>)}
        </div>
    </>;
}

export default MinistryPhotos;