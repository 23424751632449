// import { Doughnut } from "react-chartjs-2";
import "./ProjectCard.css";
import placeholderImg from "../../assets/blank1_sm.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ProjectDoughnut } from "../chart/ProjectDoughnut";
import { truncText } from "../Utils";

const ProjectCard = ({item, image}: {item: Project, image: string}) => {
    return (<>
        <div className="card-shadow">
            <div className='row pcd-rowp' style={{padding: '10px'}}>
                <div className='col-6 col-md-6 col-xl-6'>
                    <LazyLoadImage
                        src={image ?? placeholderImg} 
                        placeholderSrc={placeholderImg}
                        effect='blur'
                        alt="..."
                        className={"img-fluid pcd-img"}
                    />
                </div>
                <div className='col-6 col-md-6 col-xl-6'>
                    <div className='row' style={{padding: '3px 3px 10px 0px'}}>
                        <div style={{padding: '3px 3px 10px 0px'}}>
                            <div className="row">
                                
                                <div className="col-md-9 col-lg-8" style={{borderRight: '1px dashed #dddddd'}}>
                                    {(item.projectType != null) && (<>
                                        <p style={{marginBottom: '0px'}} className="pcd-ptype">{item.projectType}</p>
                                    </>)}
                                    <a href={`/project/${item.projectId}`} style={{}} className="pcd-name"
                                    >{ truncText(item?.name, 60) }</a>
                                </div>
                                <div className="col-md-3 col-lg-4" style={{ border: 'none', maxHeight: '200px'}}>
                                    <ProjectDoughnut percentageCompleted={item.percentageCompleted} w="100%" h="70%" />
                                    <p style={{border: 'none', 
                                        fontSize: '11px', fontWeight: '900', 
                                        textAlign: 'center', paddingTop: '0px', 
                                        margin: '0px'}}
                                    ><span style={{color: '#888888'}}>Status</span>: {item.percentageCompleted ?? '0'}%</p>
                                </div>
                            </div>                            
                            <div style={{padding: '0px'}}>
                                {(item?.location != null) && (<>
                                    <p className="pcd-loc" style={{padding: '0px'}}>
                                        <i className="fa fa-map-marker" style={{color: 'red'}}>&nbsp;</i>&nbsp;{item?.region},&nbsp;{item?.mmda}&nbsp;-&nbsp;{item?.location}
                                    </p>
                                </>)}
                                {(item?.beneficiaries != null) && (<>
                                    <p className="pcd-ben"  style={{}}><span className="ben-pfx">Beneficiaries:</span> {item?.beneficiaries}</p>
                                </>)}
                            </div>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ProjectCard;