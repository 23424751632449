import { useState } from 'react';
import { HomeAboutRow } from '../../components/homepage/HomeAboutRow';
import { useAuth } from './AuthProvider';


const Login = () => {
  
  const auth = useAuth();
  const [input, setInput] = useState({
    username: "",
    password: "",
  });
  
  const handleSubmitEvent = (e: any) => {
    e.preventDefault();
    console.log('input details', input)
    if (input.username !== "" && input.password !== "") {
      // make API call
      auth.login(input);
      return;
    }
    alert("please provide a valid input");
  };
  
  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="container" style={{padding: '20px 0px 10px 0px'}}>
            <>
            {/* <div className="row" style={{marginBottom: '20px', paddingTop: '10px'}}>
              <div className="col-md-3">&nbsp;</div>
              <div className="col-md-6" style={{padding: '25px'}}>
                  <div className="card" style={{border: '1px solid #ddd'}}> 
                  <div style={{border: 'none'}}>
                      <p style={{textAlign: 'center', fontSize: '60px'}}><strong>Login...</strong></p>
                    </div>                                   
                  </div>
              </div>                    
            </div> */}

            <div className="row" style={{marginBottom: '20px', paddingTop: '10px'}}>
                {/* <div className='offset-3 col' style={{border: "1px solid #4444"}}> */}
                <div className="col">&nbsp;</div>
                <div className='col' style={{border: "1px solid #4444", paddingBottom: "10px"}}>
                  <h3>Login</h3>

                  <form onSubmit={handleSubmitEvent}>
                    <div className="form-control">
                      {/* <label htmlFor="username" className='form-label'>Username:</label> */}
                      <input
                        type="text"
                        id="username"
                        name="username"
                        className='form-control'
                        placeholder="Username"
                        aria-describedby="user-name"
                        aria-invalid="false"
                        style={{border: "1px solid #888"}}
                        onChange={handleInput}
                      />
                      <div id="user-name" className="sr-only">
                        Please enter a valid username. It must contain at least 6 characters.
                      </div>
                    </div>
                    <div className="form-control">
                      {/* <label htmlFor="password" className='form-label'>Password:</label> */}
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className='form-control'
                        aria-describedby="user-password"
                        aria-invalid="false"
                        placeholder="Password"
                        style={{border: "1px solid #888"}}
                        onChange={handleInput}
                      />
                      <div id="user-password" className="sr-only">
                        your password should be more than 6 character
                      </div>
                    </div>
                    <div className="d-grid" style={{padding: "0 12px"}}>
                      <button className="btn btn-submit btn-outline-success">Submit</button>
                    </div>
                  </form>
                </div>
                <div className="col">&nbsp;</div>
                    
            </div>
            </>
        
        </div>
      {/* <HomeAboutRow /> */}
    </>
  );
}
export default Login;