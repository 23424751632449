import { HeaderBanner } from '../../components/banners/HeaderBanner';
import { useEffect, useState } from 'react';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import { SectionHeaderMin } from '../../components/Utils';
import NoDataNotice from '../../components/NoDataNotice';
import ReactPaginate from 'react-paginate';
import ProjDetailItems from '../../components/pagination/ProjDetailItems';

const AllProjectDetail = () => {
    const {id} = useParams();
    const {pno} = useParams();
    // const [header, setHeader] = useState<any | null>('');
    // const [headerImg, setHeaderImg] = useState<any | null>('');
    const [cProj, setCProjects] = useState<Project[]>([]);
    const [uProj, setUProjects] = useState<Project[]>([]);
    const [projType, setUProjType] = useState<String>('N/A');
    const [isLoading, setIsLoading] = useState(true);
    console.log('Project detail')

    useEffect(() => {
        const requests = [
            // `Project/summary/category/${id}`,
            `Project/summary/projects/all`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((pSum, ) => {
                let tmpPno: number = (parseInt(pno!) -1)
                let currProject = pSum.data[tmpPno]
                setCProjects(currProject['completedProjectList'])
                setUProjects(currProject['unCompletedProjectList'])
                let pType = (currProject['completedProjectList'][0]) 
                    ? currProject['completedProjectList'][0]['projectType'] : (currProject['unCompletedProjectList'][0]) 
                    ? currProject['unCompletedProjectList'][0]['projectType'] : 'N/A'
                    // console.log('Project Type', pType)
                    
                setUProjType(pType)                
                setIsLoading(false)
            })
        )
        .catch((error:any) => console.log(error));
    }, [])

    function PaginatedItemsCP({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = cProj.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(cProj.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % cProj.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <ProjDetailItems currentItems={currentItems} />
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    function PaginatedItemsUP({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = uProj.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(uProj.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % uProj.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <ProjDetailItems currentItems={currentItems} />
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    return <>
        {/* <HeaderBanner image={headerImg} title={header} /> */}
        <div className="container" style={{backgroundColor: '#fcfcfc', paddingTop: '25px'}}>
            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
                {(cProj.length > 0 || uProj.length > 0)  && (
                    <>
                <div className="row">
                    <SectionHeaderMin title={`${projType}`}  />
                </div>
                
                <div className="row">                        
                    <div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item btn-outline-info">
                                <a className="nav-link active" data-bs-toggle="tab" aria-current="page" href="#cproj" data-bs-target="#cproj"  style={{padding: '10px 50px'}}>
                                    <i className='fa fa-home'>&nbsp;</i>Completed&nbsp;Projects
                                </a>
                            </li>
                            <li className="nav-item btn-outline-info">
                                <a className="nav-link" data-bs-toggle="tab" aria-current="page" href="#uproj" data-bs-target="#uproj"  style={{padding: '10px 50px'}}>
                                <i className='fa fa-list'>&nbsp;</i>Uncompleted&nbsp;Projects
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="tabContent">
                            <div className="tab-pane fade show active" id="cproj" role="tabpanel" aria-labelledby="mins-tab">
                                <div className='container' style={{padding: '20px 20px'}}>
                                    {cProj.length > 0 ? (<>
                                        <PaginatedItemsCP itemsPerPage={20} />
                                    </>): (<><NoDataNotice item='Projects'/></>)}
                                </div>
                            </div>
                            <div className="tab-pane fade show" id="uproj" role="tabpanel" aria-labelledby="home-tab">
                                <div className='container' style={{padding: '20px 20px'}}>
                                    <div className='row'>
                                        {(uProj.length > 0) ? (<>
                                            <PaginatedItemsUP itemsPerPage={20} />
                                        </>): (<><NoDataNotice item='Projects'/></>)}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                    </>
                )}
            </>)}
        </div>
        <div style={{clear: 'both'}}>&nbsp;</div>
        <div style={{clear: 'both'}}>&nbsp;</div>
    </>;
}

export default AllProjectDetail;