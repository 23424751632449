import { HeaderBanner } from '../../components/banners/HeaderBanner';
import { useEffect, useState } from 'react';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import { SectionHeaderBlack, SectionHeaderMin } from '../../components/Utils';
import ProjSummaryItems from '../../components/pagination/ProjSummaryItems';
import ReactPaginate from 'react-paginate';
import NoDataNotice from '../../components/NoDataNotice';

const MmdaProjectSummary = () => {
    const {id} = useParams();
    const [header, setHeader] = useState<any | null>('');
    const [headerImg, setHeaderImg] = useState<any | null>('');
    const [ministry, setMinistry] = useState<Ministry>();
    const [projSummary, setProjSummary] = useState<ProjectSummary[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const requests = [
            `Project/summary/mmda/${id}`,
            `Mmda/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((pSum, mins,) => {
                setProjSummary(pSum.data)
                setMinistry(mins.data)
                
                setIsLoading(false)
            })
        )
        .catch((error:any) => console.log(error));
    }, [])

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = projSummary.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(projSummary.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % projSummary.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            {projSummary.length > 0 ? (
                <ProjSummaryItems currentItems={currentItems} routePrefix={`/mmda/${id}`} offSet={itemOffset}/>
            ): (<><NoDataNotice item='Projects Summary'/></>)}

            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    return <>
        {/* <HeaderBanner image={headerImg} title={header} /> */}
        <div className="container" style={{backgroundColor: '#fcfcfc', paddingTop:'25px'}}>
            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
                {projSummary.length > 0 ? (
                    <>
                        <div className="row">
                            <SectionHeaderMin title={`Project Summary: ${ministry?.name}`}  />
                        </div>
                        <div className="row">
                            <PaginatedItems itemsPerPage={20} />
                            {/* <table className='table table-striped table-bordered'>
                                <colgroup>
                                    <col style={{width: '5%'}} />
                                    <col style={{width: '65%'}} />
                                    <col style={{width: '10%'}} />
                                    <col style={{width: '10%'}} />
                                    <col style={{width: '10%'}} />
                                </colgroup>
                                <thead>
                                    <tr className="table-default" style={{backgroundColor: '#58b1e8', color: '#ffffff'}}>
                                        <th>#</th>
                                        <th>Project Type</th>
                                        <th>Completed</th>
                                        <th>Uncompleted</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {projSummary.map((e, idx) => (<>
                                    <tr key={`ktr-${idx}`}>
                                        <td>{idx + 1}</td>
                                        <td><a style={{textDecoration: 'none'}} href={`/mmda/${id}/projectno/${idx +1}/projdetail/`}>{e.key}</a></td>
                                        <td>{e.completedProjects}</td>
                                        <td>{e.unCompletedProjects}</td>
                                        <td>{e.totalProjects}</td>
                                    </tr>
                                </>))}
                                </tbody>    
                            </table> */}
                        </div>
                    </>
                ): (<><NoDataNotice item='Project Summaries'/></>)}
            </>)}
        </div>
        <div style={{clear: 'both'}}>&nbsp;</div>
        <div style={{clear: 'both'}}>&nbsp;</div>
    </>;
}

export default MmdaProjectSummary;