import ModalImage from "@avidian/react-modal-image";
import { CSSProperties } from "react";

const descStyle: CSSProperties = {
    color: '#888888 !important',
    textDecoration: 'none',
    fontSize: '20px',
    height: '58px',
    overflow: 'hidden',
    wordWrap: 'break-word',
}

export const InfographicCard = ({item}: {item: Infographic}) => {
    return (
        <>
        <div className="card" style={{border: 'none'}}>
            <ModalImage
                small={item.fileUrl}
                large={item.fileUrl}
                medium={item.fileUrl}
                alt={item.name}
                className='pop-modal'
                // hideZoom={true}
                imageBackgroundColor='#000000'
            />
            <div className="card-body" style={{backgroundColor: '#ffffff', padding: '5px'}}>
                <div style={{ margin: '0', padding: '0px',}}><h3 className="sub-hdr" >{item.name}</h3></div>
                <p className="card-text-info" style={descStyle}>{item.description}</p>
            </div>
            <div className='art-overlay'>
            </div>
        </div>
        </>
    )
}