import moment from "moment";
import { ChangeEvent } from 'react';

// const ArrayChunk = ({items, cstyle}:{date: string, cstyle: object}) => {
let CHUNK_NO = 4;

const createNavList = (tmpList: Category[], ctype: string, addFirstChar: boolean = false) => {
    let alphas: string[] = [];
    let links:LinkData[] = [];
    let kPfx = ctype.slice(0, 3);
    tmpList.forEach((e : Category) => {
        // Add first chars to dynamic nav list
        const fChar = e.name.charAt(0);
        if (!alphas.includes(fChar) && addFirstChar) {
          alphas.push(fChar);
          links.push({name: fChar, url: '#', ky:`ct-${fChar}`, hdr: '1'},);
        }
        links.push({name: e.name, url: `/${ctype}/${e.categoryId}`, ky:`${kPfx}-${e.categoryId}`});
    });

    return links;
}

const createNavListMmda = (tmpList: Category[], ctype: string, addFirstChar: boolean = false) => {
    let alphas: string[] = [];
    let links:LinkData[] = [];
    let kPfx = ctype.slice(0, 3);
    
    tmpList.forEach((e : Category) => {
        // Add first chars to dynamic nav list
        const fChar = e.name.charAt(0);
        if (!alphas.includes(fChar) && addFirstChar) {
          alphas.push(fChar);
          links.push({name: fChar, url: '#', ky:`ct-${fChar}`, hdr: '1'},);
        }
        links.push({name: e.name, url: `/${ctype}/${e.categoryId}`, ky:`${kPfx}-${e.categoryId}`});
    });

    return links;
}

const createCardNavList = (tmpList: ThemeCategoryLink[], ctype: string, addFirstChar: boolean = false) => {
    tmpList.sort((a, b) => +a.themeId - +b.themeId);
    // tmpList.sort((a, b) => (a.name < b.name ? -1 : 1));
    var myLinks: CatLinks[] = [];
    var tmp: LinkCardData[] = [];
    var thIds: string[] = [];

    var cnt = Number(0);
    tmpList.forEach((e : ThemeCategoryLink, x: number) => {
        var themeId = e.themeId;
        // var themeId = e.;
        cnt++;
        
        if (!thIds.includes(themeId)) {
            thIds.push(themeId);
            if (tmp.length > 0) {
                var prevTheme = thIds[thIds.length -2];
                tmp = tmp.sort((a, b) => (a.cat.name < b.cat.name ? -1 : 1))
                myLinks.push({theme: prevTheme, data: tmp})
                tmp = [];
            }
        }
        tmp.push({cat: e.category, url: `/${ctype}/${e.category.categoryId}`, ky:`ct-${themeId}`, img: e.category.thumbnailUrl},);
        // tmp.push({name: e.category.name, description: e.category.description, url: `/${ctype}/${e.category.categoryId}`, ky:`ct-${themeId}`, img: e.category.thumbnailUrl},);

        //handle last items
        if (tmpList.length == cnt) {
            tmp = tmp.sort((a, b) => (a.cat.name < b.cat.name ? -1 : 1))
            myLinks.push({theme: themeId, data: tmp})
        }
    });
    return myLinks;
}

const createCardNavNewList = (tmpList: ThemeCategoryLink[], ctype: string, addFirstChar: boolean = false, allSectors: Sector[]) => {
    tmpList.sort((a, b) => +a.themeId - +b.themeId);
    // tmpList.sort((a, b) => (a.name < b.name ? -1 : 1));
    var myLinks: CatLinks[] = [];
    var tmp: LinkCardData[] = [];
    var thIds: string[] = [];
    var thNames: string[] = [];

    var cnt = Number(0);
    tmpList.forEach((e : ThemeCategoryLink, x: number) => {
        var themeId = e.themeId;
        var themeName = findSectorName(allSectors, e.themeId);
        cnt++;
        
        if (!thNames.includes(themeName)) {
            thIds.push(themeId);
            thNames.push(themeName);
            if (tmp.length > 0) {
                var prevTheme = thNames[thNames.length -2];
                var prevId = thIds[thIds.length -2];
                tmp = tmp.sort((a, b) => (a.cat.name < b.cat.name ? -1 : 1))
                myLinks.push({theme: prevTheme, themeId: prevId, data: tmp,})
                tmp = [];
            }
        }
        tmp.push({cat: e.category, url: `/${ctype}/${e.category.categoryId}`, ky:`ct-${themeId}`, img: e.category.thumbnailUrl},);
        // tmp.push({name: e.category.name, description: e.category.description, url: `/${ctype}/${e.category.categoryId}`, ky:`ct-${themeId}`, img: e.category.thumbnailUrl},);

        //handle last items
        if (tmpList.length == cnt) {
            tmp = tmp.sort((a, b) => (a.cat.name < b.cat.name ? -1 : 1))
            myLinks.push({theme: themeName, themeId: themeId, data: tmp})
        }
    });
    return myLinks;
}

// function findSector(sectors:Sector[], sectorId: string): Sector|undefined {
function findSector(sectors:Sector[], sectorId: string): Sector {
    const sector = sectors.find((obj) => {
        return obj.themeId === sectorId;
    }) as Sector;
    return sector;
}

function findSectorName(sectors:Sector[], sectorId: string): string {
    const sector = sectors.find((obj) => {
        return obj.themeId === sectorId;
    })?.name;
    return sector || '_Other';
}

const createEventYearList = (tmpList: EventData[], keyField: string, addFirstChar: boolean = false) => {
    tmpList.sort((a, b) => moment(a.eventDate).format('YYYYMMDD')  < moment(b.eventDate).format('YYYYMMDD') ? 1 : -1)
    
    var kmArr: KeyArrayMap[] = [];
    var tmp: EventData[] = [];
    var refIds: string[] = [];

    var cnt = Number(0);
    tmpList.forEach((e: EventData, x: number) => {
        var refId = e.eventDate;
        var refId = (refId.replace(/ /g, '').split(','))[1]
        cnt++;
        
        if (!refIds.includes(refId)) {
            refIds.push(refId);
            if (tmp.length > 0) {
                var prevRef = refIds[refIds.length -2];
                kmArr.push({mref: prevRef, data: tmp})
                tmp = [];
            }
        }
        tmp.push(e,);

        //handle last items
        if (tmpList.length == cnt) {
            kmArr.push({mref: refId, data: tmp})
        }
    });
    return kmArr;
}

const createSectorNavList = (tmpList: Sector[], ctype: string, addFirstChar: boolean = false) => {
    let alphas: string[] = [];
    let links:LinkData[] = [];
    let kPfx = ctype.slice(0, 3);
    
    tmpList.forEach((e : Sector) => {
        // Add first chars to dynamic nav list
        const fChar = e.name.charAt(0);
        if (!alphas.includes(fChar) && addFirstChar) {
          alphas.push(fChar);
          links.push({name: fChar, url: '#', ky:`ct-${fChar}`, hdr: '1'},);
        }
        links.push({name: e.name, url: `/${ctype}/${e.themeId}`, ky:`${kPfx}-${e.themeId}`});
    });

    return links;
}

/**
 * Check whether item at index exists in the array of objects.
 * @param pId item (value) to check for
 * @param idxName index vakue to check on
 * @param objList array of objects
 * @returns 
 */
const ItemExistsInArray = ((pId: string, idxName: string, objList: any) => {
    return objList.some(function(item: any) {
        return item[idxName] === pId;
        // return item.projectId === pId;
    })
});

const ArrayChunk = (arr: LinkData[]) => {
    // let rem = (arr.length % CHUNK_NO);
    let chunkSize = Math.floor((arr.length / CHUNK_NO));

    var groups = arr.map((e,i) => {
        return i%chunkSize===0 ? arr.slice(i,i+chunkSize) : null;
    })
    .filter(function(e){ return e; });

    return groups;
}

const ArrayChunkProc = (arr: LinkData[]) => {

    var CHUNKNO = 4;
    // let rem = (arr.length % CHUNK_NO);
    let chunkSz = Math.floor((arr.length / CHUNKNO));

    var groups = arr.map((e,i) => {
        return i%chunkSz===0 ? arr.slice(i,i+chunkSz) : null;
    })
    .filter(function(e){ return e; });

    // process chunks
    const mList:any = {};
    groups.forEach((data: LinkData[] | null, ix: number) => {
        mList[`tmpCol${ix+1}`] = data;
    });

    // rename keys for use in arrangement
    let tmpCol1:LinkData[] = mList['tmpCol1'];
    let tmpCol2:LinkData[] = mList['tmpCol2'];
    let tmpCol3:LinkData[] = mList['tmpCol3'];
    let tmpCol4:LinkData[] = mList['tmpCol4'];
    let tmpCol5:LinkData[] = mList['tmpCol5'];

    let tmpFinal:LinkData[]  = [];
    tmpCol1.forEach((data: LinkData, ix: number) => {
        tmpFinal.push(data);
        tmpFinal.push(tmpCol2[ix]);
        tmpFinal.push(tmpCol3[ix]);
        tmpFinal.push(tmpCol4[ix]);
        if (tmpCol5[ix] !== undefined) {
            // tmpFinal.push(tmpCol5[ix]);
        }
    });

    return tmpFinal;
}

const ArrayChunkProcAdv = (arr: LinkData[], chunkNo: number) => {
    // var CHUNKNO = 4;
    let chunkSz = Math.floor((arr.length / chunkNo));
    var groups = arr.map((e,i) => {
        return i%chunkSz===0 ? arr.slice(i,i+chunkSz) : null;
    })
    .filter(function(e){ return e; });

    // process chunks
    const mList:any = {};
    groups.forEach((data: LinkData[] | null, ix: number) => {
        mList[`tmpCol${ix+1}`] = data;
    });

    // rename keys for use in arrangement
    let tmpCol1:LinkData[] = mList['tmpCol1'];
    let tmpCol2:LinkData[] = mList['tmpCol2'];
    let tmpCol3:LinkData[] = mList['tmpCol3'];
    let tmpCol4:LinkData[] = mList['tmpCol4'];

    let tmpFinal:LinkData[]  = [];
    tmpCol1.forEach((data: LinkData, ix: number) => {
        tmpFinal.push(data);
        tmpFinal.push(tmpCol2[ix]);
        tmpFinal.push(tmpCol3[ix]);
        if (tmpCol4 !== undefined && tmpCol4[ix] !== undefined) {
            tmpFinal.push(tmpCol4[ix]);
        }
    });

    return tmpFinal;
}

/**
 * Get iamges from project multimedia attached to a project
 * @param obj 
 * @returns 
 */
function getProjectMultimediaPhoto(obj: ProjectMultimedia[]) {
    let images: {url: string, name: string, description: string}[] = [];
    obj.map((item) => {
        if (item.photo?.thumbnailUrl != null) {
            images.push({url: item.photo?.thumbnailUrl, name: item.photo?.name, description: item.photo?.description});
        }
    })
    return images;
}

const getOptionDataAttribute = (event: ChangeEvent<HTMLSelectElement>, key: string) => {
    const idx = event.target.selectedIndex;
    const option = event.target.querySelectorAll('option')[idx];
    const val = option.getAttribute(`data-${key}`);
    return val;
}

function getBannerMetricLink(mets:MetricLink[]) {
    let m: Metric | null = null;
    mets.map((e: any) => {
        if (e.isMainMetric && e.isMainMetric === true) {
            m = e; return;
        }
    })
    return m;
}

/**
 * Get keys to be used in fetching data
 * @param objectType object type name
 * @returns 
 */
const GetGeneralEntityKeys = (objectType: string, item: any = null) => {
    let val:any[] = [];
    switch(objectType) { 
        case 'article': { 
            val = ['title', 'articleUrl'];
            break;
        } 
        case 'category': { 
            val = ['name', 'categoryId'];
            break;
        } 
        case 'project': { 
            val = ['name', 'projectId'];
            break;
        } 
        case 'video': { 
            val = ['name', 'media/videos'];
            break;
        } 
        case 'audio': { 
            val = ['name', 'audioUrl'];
            break;
        } 
        case 'photo': {
            val = ['name', 'media/photos'];
            break;
        } 
        case 'upload': {
            val = ['name', 'fileUrl'];
            break;
        } 
        case 'freeText': {
            val = (item !== null && !['#', ''].includes(item.url)) ? ['name', 'url'] : ['name', 'freeTrextId'];
            break;
        } 
        default: {
            val = ['name', 'photoUrl'];
            break;
        }
    }
    return val;
}
// upload, freeText

let cdat = {
    'article': [],
    'category': [],
    'project': [],
    'video': [],
    'audio': [],
    'photo': [],
    'upload': [],
    'freeText': [],
}

const FilterEntityTypes = (objectType: string, item: any = null, dat: any) => {
    let val:any[] = [];
    switch(objectType) { 
        case 'article': { 
            val = ['title', 'articleUrl'];
            dat['web'].push(item);
            break;
        } 
        case 'web': { 
            val = ['name', 'categoryId'];
            dat['category'].push(item);
            break;
        } 
        case 'project': { 
            val = ['name', 'projectId'];
            dat['project'].push(item);
            break;
        } 
        // case 'video': { 
        //     val = ['name', 'media/videos'];
        //     dat['video'].push(item);
        //     break;
        // } 
        // case 'audio': { 
        //     val = ['name', 'audioUrl'];
        //     dat['audio'].push(item);
        //     break;
        // } 
        case 'photo': {
            val = ['name', 'media/photos'];
            dat['document'].push(item);
            break;
        } 
        case 'upload': {
            val = ['name', 'fileUrl'];
            dat['document'].push(item);
            break;
        } 
        case 'freeText': {
            dat['web'].push(item);
            val = (!['#', ''].includes(item.url)) ? ['name', 'url'] : ['name', 'freeTrextId'];
            // val = (item !== null && !['#', ''].includes(item.url)) ? ['name', 'url'] : ['name', 'freeTrextId'];
            break;
        } 
        default: {
            val = ['name', 'photoUrl'];
            break;
        }
    }
    return val;
}
// upload, freeText

function filterSourcesOrTrends(data:CurrentTrend[], keyArr: any[]) {
    const trendLinks: any[] = [];
    let propKeys = ['project', 'video', 'audio', 'photo', 'article', 'category', 'upload', 'freeText',];

    if (!(data.length > 0)) return trendLinks;        
    data.map((item: CurrentTrend) => {
        Object.entries(item).map(([key, value]) => {
            if (propKeys.includes(key) && value != null) {
                trendLinks.push(value)
                keyArr.push(key);
            }
        })
    })
    return trendLinks;
}

function getFreetextURL(dtype: string, item: any): string {
    const url = (dtype === 'freeTextId') ? '#' : `${item.url}`
    return url
}

const extractProjectTypes = (dList: any[]) => {
    let pTypes: string[] = []
    dList.map((p: any) => {
        if (! (pTypes.includes(p.projectType)) ) {
            pTypes.push(p.projectType)
        }
    })
    return pTypes;
}

function extractChartType(displayDat: any): string {
    const chkDat = displayDat?.metric?.displayType
    let defType:string = 'None'
    if(chkDat) {
        if(chkDat.includes('Pie')) defType = 'Pie'
        if(chkDat.includes('Bar')) defType = 'Bar'
        if(chkDat.includes('Line')) defType = 'Line'
        if(chkDat.includes('Table')) defType = 'Table'
    }    
    return defType
}

// function checkForId(data: Data[], idToCheck: number): boolean {
function checkMetricExists(data: any[], idToCheck: number): boolean {
    return data.some((obj) => obj.metricId === idToCheck);
}

export { createNavList, createCardNavList, createSectorNavList, ArrayChunk, ArrayChunkProc, ArrayChunkProcAdv, 
        createEventYearList, createCardNavNewList, findSector, ItemExistsInArray,
        getProjectMultimediaPhoto, getOptionDataAttribute, getBannerMetricLink
        , GetGeneralEntityKeys, filterSourcesOrTrends, getFreetextURL
        ,createNavListMmda, FilterEntityTypes, extractProjectTypes, extractChartType, checkMetricExists
}