import { HeaderBanner } from '../../components/banners/HeaderBanner';
import { useEffect, useState } from 'react';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FilterEntityTypes, filterSourcesOrTrends } from '../../lib/Funcs';
import NoDataNotice from '../../components/NoDataNotice';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import ProjectSourceItems from '../../components/pagination/ProjectSourceItems';
import ReactPaginate from 'react-paginate';

const RegionalMetricSourceMore = () => {
    const {id} = useParams();
    const [metricLink, setMetricLink] = useState<MetricLink>()

    const [header, setHeader] = useState<any | null>('');
    const [headerImg, setHeaderImg] = useState<any | null>('');
    const [metricUnit, setMetricUnit] = useState<string>('');
    const [webSources, setWebSources] = useState<SourceLink[]>([]);
    const [docSources, setDocSources] = useState<SourceLink[]>([]);
    const [childMetrics, setChildMetrics] = useState<RegionalChildMetric[]>([])
    const [isLoading, setIsLoading] = useState(true);

    const [sourceArr, setSourceArr] = useState<{
        web: any[], project: Project[], document: any[], 
    }>({'web': [], 'project': [], 'document': [], });

    useEffect(() => {
        const requests = [
            `RegionalChildMetric/parent/RegionalChild/${id}`,
            `RegionalMasterMetric/${id}`,
            `RegionalMasterMetricSource/RegionalMasterMetric/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(

            axios.spread((chMetrics, masterMet, sources) => {
                setChildMetrics(chMetrics.data)
                if (chMetrics.data.length > 0) {
                    const metData: MetricLink = {
                        isMain: false,
                        metric: masterMet.data,
                        themeId: '0',
                        metricLinkId: '',
                        metricId: 'regionalChildMetricId!'
                    }                    
                    setMetricLink(metData);
                }
                setMetricUnit(masterMet.data?.unitOfMeasure)

                let keys:any[] = [];
                var filtered_srcs = filterSourcesOrTrends(sources.data, keys)
                
                let cdat = {'web': [], 'project': [], 'document': [], 'photo': [], }
                filtered_srcs.map((item, idx) => {
                    const mkeys = FilterEntityTypes(keys[idx], item, cdat)
                })
                setSourceArr(cdat)

                let wsList: SourceLink[] = [];
                cdat['web'].map((witem:any, idx) => {
                    let mURL = '#'
                    let name = witem.name
                    let new_tab = true
                    let push = false
                    if (witem.articleId) {
                        mURL = witem.articleUrl
                        name = witem.description
                        push = true
                    } else {
                        mURL = (!witem.url || witem.url == '#' || witem.url == '')? '#' : witem.url
                        new_tab = (!witem.url || witem.url == '#' || witem.url == '')? new_tab = false : true
                        if(!(!witem.url || witem.url == '#' || witem.url == '')) {
                            push = true
                        }
                    }
                    if (push) wsList.push({name: name, url: mURL, newtab: new_tab});
                })
                setWebSources(wsList)

                let docList: SourceLink[] = [];
                cdat['document'].map((ditem:any, idx) => {
                    let mURL = '#'
                    let name = ditem.name
                    let new_tab = true
                    if (ditem.photoId) {
                        mURL = `/media/photos/${ditem['photoId']}`
                        name = ditem.description
                        new_tab = false
                    } 
                    if (ditem.uploadId) {
                        mURL = `${ditem['fileUrl']}`
                    } 
                    docList.push({name: name, url: mURL, newtab: new_tab});
                })
                setDocSources(docList);

                let newDocList: SourceLink[] = [];
                cdat['web'].map((witem:any, idx) => {
                    let mURL = '#', name = witem.name, push = false
                    if (!witem.articleId) {
                        if((!witem.url || witem.url == '#' || witem.url == '')) {
                            push = true
                        }
                        mURL = (!witem.url || witem.url == '#' || witem.url == '')? '#' : witem.url
                    }
                    if (push) newDocList.push({name: name, url: mURL, newtab: false});
                })

                var mergeDocList = [...docList,...newDocList];
                setDocSources(mergeDocList);
                setIsLoading(false)
            })

        )
        .catch((error:any) => console.log(error));
    }, [])

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = sourceArr['project'].slice(itemOffset, endOffset);
        const pageCount = Math.ceil(sourceArr['project'].length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % sourceArr['project'].length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <ProjectSourceItems currentItems={currentItems} routePrefix={`/ministry/${id}`}/>
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    return <>
        {/* <HeaderBanner image={headerImg} title={header} /> */}
        <div className="container" style={{backgroundColor: '#fcfcfc', paddingTop: '25px'}}>
            <div className="row" style={{padding: '20px 0'}}>
                <div className='col-8'>
                    <h3 style={{fontWeight: '700'}}>{metricLink?.metric?.name}</h3>
                    <p style={{color: '#444'}}>{metricLink?.metric.measure} {(metricLink?.metric.unitOfMeasure === 'NA') ? '':metricLink?.metric.unitOfMeasure} {metricLink?.metric.description} {metricLink?.metric.year}</p>
                    <p style={{color: '#888', fontSize: '16px'}}>{metricLink?.metric.remarks}</p>
                </div>
            </div>

            <div className='row'>
            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
                <div>
                    <ul className="nav nav-tabs">
                        <li className="nav-item btn-outline-info">
                            <a className="nav-link active" data-bs-toggle="tab" aria-current="page" href="#proj" data-bs-target="#proj"  style={{padding: '10px 50px'}}>
                                <i className='fa fa-home'>&nbsp;</i>Projects
                            </a>
                        </li>
                        <li className="nav-item btn-outline-info">
                            <a className="nav-link" data-bs-toggle="tab" aria-current="page" href="#doc" data-bs-target="#doc"  style={{padding: '10px 50px'}}>
                            <i className='fa fa-cloud-download'>&nbsp;</i>Documents
                            </a>
                        </li>
                        <li className="nav-item btn-outline-info">
                            <a className="nav-link" data-bs-toggle="tab" aria-current="page" href="#web" data-bs-target="#web"  style={{padding: '10px 50px'}}>
                                <i className='fa fa-globe'>&nbsp;</i>Web&nbsp;Pages
                            </a>
                        </li>                
                    </ul>
                    <div className="tab-content" id="tabContent">
                        <div className="tab-pane fade show active" id="proj" role="tabpanel" aria-labelledby="mins-tab">
                            <div className='container' style={{padding: '20px 20px'}}>
                                {sourceArr['project'].length > 0 ? (<>
                                    <PaginatedItems itemsPerPage={20} />
                                </>): (<><NoDataNotice item='Projects'/></>)}
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="doc" role="tabpanel" aria-labelledby="home-tab">
                            <div className='container' style={{padding: '20px 20px'}}>
                                <div className='row'>
                                    {(docSources.length > 0) ? (<>
                                        {docSources.map((item) => (
                                            <div className='col-md-4'>
                                                {item.url !=='#' ? (<>
                                                <a href={`${item.url}`} style={{textDecoration: 'none'}} target={(item.newtab) ? '_blank': '_self'}>{`${item.name}`}</a>
                                                </>
                                            ):(<>
                                                <label style={{textDecoration: 'none'}}>{`${item.name}`}</label>
                                            </>)}
                                            </div>
                                        ))}
                                    </>): (<><NoDataNotice item='Documents'/></>)}
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade show" id="web" role="tabpanel" aria-labelledby="profile-tab">
                            <div className='container' style={{padding: '20px 20px'}}>
                                <div className='row'>
                                {webSources.length > 0 ? (<>
                                    {webSources.map((item: SourceLink) => (
                                        <div className='col-md-4'>
                                            <a href={`${item.url}`} style={{textDecoration: 'none'}} target={(item.newtab) ? '_blank': '_self'}>{item.name}</a>
                                        </div>
                                    ))}                            
                                </>): (<><NoDataNotice item='Web links'/></>)}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <hr/>
                </>)}

            </div>
        </div>
        <div style={{clear: 'both', padding: '20px 0'}}>&nbsp;</div>
    </>;
}

export default RegionalMetricSourceMore;