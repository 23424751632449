import './HeaderBannerMore.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import placeholderImg from '../../assets/media_banner.jpg';



interface HeaderProps {
    image?: string,
    title: string,
    desc?: string,
    metric?: Metric,
}

export const HeaderBannerMore = (props: HeaderProps) => {
    return <>
        <div className="my-cnt" style={{'border': 'none'}}>
            <div className='ban1-overlay'></div>
            <LazyLoadImage 
                src={props.image} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
            />
            <div className='cat1-header'>
                <h3>{props.title}</h3>
                <p>{props?.desc}</p>
                <div style={{border: 'none', fontStyle: 'italic'}}>{props?.metric && (
                        <p color='#ff0000'>{props?.metric.measure} {(props?.metric.unitOfMeasure !== 'NA') ? props?.metric.unitOfMeasure : '' } {props?.metric.description} in {props?.metric.year}</p>                
                    )}
                </div>
            </div>
        </div> 
    </>;
}