import React, { useEffect, useState } from "react";
// import { HeaderBanner } from "../components/banners/HeaderBanner";
import { NavbarMini } from "../components/NavbarMini";
import { Outlet, useParams } from "react-router-dom";
import '../components/UtilStyles.css';
import http from "../lib/Http";
import axios from "axios";
import { HeaderBannerMore } from "../components/banners/HeaderBannerMore";

const RegionMain: React.FC = () => {
    const {id} = useParams();

    const links: LinkProps[] = [
        {name: 'Projects', url: `/region/${id}/projects`},
        {name: 'Metrics', url: `/region/${id}/metrics`},
        // {name: 'Categories', url: `/region/${id}/categories`},
        {name: 'Photos', url: `/region/${id}/photos`},
        {name: 'Videos', url: `/region/${id}/videos`},
        {name: 'Audios', url: `/region/${id}/audios`},
        {name: 'Infographics', url: `/region/${id}/reports`},
        {name: 'Articles', url: `/region/${id}/articles`},
        {name: 'MMDAs', url: `/region/${id}/mmdas`},
        {name: 'Project Summary', url: `/region/${id}/projsummary`},
        {name: 'Map', url: `/region/${id}/projectsmap`},
    ]

    const [region, setRegion] = useState<Region>();
    const [header, setHeader] = useState<string>('');
    const [description, setDescription] = useState('');
    const [mainMetric, setMainMetric] = useState<MetricLink>();

    useEffect(() => {
        const requests = [
            `Region/${id}`,
            `Ministry/metrics/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((reg, mets) => {
                setRegion(reg.data);
                setDescription(reg.data?.name)
                setHeader(reg.data?.name)
            })
        )
        .catch((error:any) => console.log(error));
    }, []);
    
    return (
        <>
        <HeaderBannerMore image={region?.displayImageUrl} title={header} desc={''} metric={mainMetric?.metric} />
        <NavbarMini navs={links}/>        
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{backgroundColor: '#fcfcfc'}}>
                        <Outlet context={[region, description]}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default RegionMain;