import { useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
// import { getProjMultimediaById } from '../../lib/FindHelpers';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
// import { GoogleMap, useLoadScript, Marker, useJsApiLoader, InfoWindow, } from '@react-google-maps/api';
import { MapContainer, TileLayer, Marker, Popup  } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import "leaflet-defaulticon-compatibility";


const mapStyles = {
  width: '100%',
  height: '100%'
};

const center = {
    lat: 5.5560, // Accra-central latitude
    lng: -0.1969, // Accra-central longitude
};

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const CategoryProjectsMap = () => {
    const {id} = useParams();
    const [projects, setProjects] = useState<Project[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedProj, setSelectedProj] = useState<Project | undefined | null>(null);

    const position = [51.505, -0.09]
    const position2 = [40.505, -100.09] 
    
    useEffect(() => {
        const fetchCategories = () => {
            const requests = [
                `Project/category/${id}`,
            ].map(url => http.get(url));

            axios.all(requests)
                .then(
                    axios.spread((projs, ) => {
                        setProjects(projs.data);
                        setIsLoading(false)
                    })
                )
                .catch(err => console.log(err))

        }
        fetchCategories();
    }, [])

    return (
        <div>
            <div>&nbsp;</div>
            {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                    <div className='row'>                       
                        <div className='col-md-12'>
                            <MapContainer center={[5.5560, -0.1969]} zoom={13} scrollWheelZoom={false} style={mapContainerStyle}>
                            
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[5.5560, -0.1969]}>
                                    <Popup>
                                        This is Accra central!
                                    </Popup>
                                </Marker>

                                {projects.map((proj) => (
                                    <Marker position={[proj.latitude ?? 0, proj.longitude ?? 0]}>
                                        <Popup>
                                            This is Accra central!
                                        </Popup>
                                    </Marker>
                                ))}

                            </MapContainer>                            
                        </div>
                    </div>                                    
                </>)}
                <div>&nbsp;</div>
        </div>
    );
}
export default CategoryProjectsMap;