import '../../components/UtilStyles.css';
// import ReactPlayer from 'react-player';
import http from '../../lib/Http';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { VideoCard } from '../../components/cards/VideoCard';
import NoDataNotice from '../../components/NoDataNotice';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';

const CategoryVideos = () => {
    const {id} = useParams();
    const [videos, setVideos] = useState<VideoLink[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [dataLen, setDataLen] = useState(0);
    const [hasNext, setHasNext] = useState(true);

    const fetchData = async () => {
        setError(null);
      
        try {
            const response = await http.get(`VideoLink/category/paged/${id}?Page=${page}&ItemsPerPage=10`);
            const data = await response.data;
            let newData = [...videos, ...data]
            setDataLen(newData.length)

            setVideos(newData);
            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData()

    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className='row' style={{paddingTop: '20px'}}>

            {isLoading ? (<>
                <LoadingSpinner />            
            </>) : (<>

                <InfiniteScroll
                    dataLength={dataLen}
                    next={fetchData}
                    hasMore={hasNext}
                    loader={<p></p>}
                    endMessage={<p>&nbsp;</p>}
                    style={{overflowX: 'hidden', padding: '2px'}}
                >
                    {videos.length > 0 ? (
                    <>
                    <div className="row">
                        {videos.map((item, index) => (
                            <div className="col-md-4" key={`ic-${index}`}> 
                                <VideoCard item={item.video} />
                            </div>
                        ))}
                    </div>
                    </>
                    ): (<><NoDataNotice item='Videos'/></>)}
                </InfiniteScroll>

            </>)}

            </div> 
        </div>
        <div>&nbsp;</div>
    </>;
}

export default CategoryVideos;