
const NoDataNotice = ({item}: {item: string}) => {
    return (
        <>
            <div className="alert alert-default">
                <p style={{fontSize: '20px'}}>{`Sorry there are no ${item} available...`}</p>
            </div>
        </>
    )
}

export default NoDataNotice;