import { HeaderBanner } from '../../components/banners/HeaderBanner';
import { useEffect, useState } from 'react';
import http from '../../lib/Http';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Line, Bar, Pie } from 'react-chartjs-2'
import { InfographicCard } from '../../components/cards/InfographicCard';
import { extractChartType } from '../../lib/Funcs';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import NoDataNotice from '../../components/NoDataNotice';

const MetricSingle = () => {
    const {id} = useParams();
    // const [event, setEvent] = useState<EventData>()
    const [metricLink, setMetricLink] = useState<MetricLink>()
    const [metricLinks, setMetricLinks] = useState<MetricLink[]>([])
    const [metricInfos, setMetricInfos] = useState<MetricInfographic[]>([])
    const [lineData, setLineData] = useState<number[]>([])
    const [lineLabels, setLineLabels] = useState<string[]>([])
    const [dataLabel, setDataLabel] = useState<string>()

    const [header, setHeader] = useState<any | null>('');
    const [headerImg, setHeaderImg] = useState<any | null>('');
    const [metricUnit, setMetricUnit] = useState<string>('');
    const [useNameDim, setUseNameDim] = useState<boolean>(false);
    // const [sources, setSources] = useState<SourceLink[]>([]);
    const [sourceCount, setSourceCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [chartDisplay, setChartDisplay] = useState('None');
    const [bgColors, setBgColors] = useState<string[]>([]);

    function getComputedLabel(cm: Metric, useName: boolean = false): string {
        return (useName) ? ""+cm.name : ""+cm.year;
    }

    function generateGraphData(mdata: MetricLink[], useName: boolean = false): any[] {
        let ldata: number[] = []
        let llabel: string[] = []
        mdata.slice(0, 1).map((ei: MetricLink) => {            
            ei.metric.childMetrics?.
                sort((a, b) => a.year > b.year ? 1 : -1).
                map(cm => {
                    let label = getComputedLabel(cm, useName)
                    // ('check', useName, label)
                    ldata.push( Number(cm.measure.replaceAll(/,/g, '') ))
                    llabel.push(`${label}`)
            })
        })

        return [llabel, ldata]
    }

    useEffect(() => {
        const requests = [
            `MetricLink/Metric/${id}`,
            `MetricInfographicsLink/metric/${id}`,
            `Metric/${id}`,
            `MetricSource/Metric/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((metrics, infos, met1, sources) => {
                setMetricLinks(metrics.data)
                
                if (metrics.data.length > 0) {
                    setDataLabel(`${metrics.data[0]?.metric?.name}`)

                    const dType = extractChartType(metrics.data[0])
                    setChartDisplay(dType)
                    setMetricLink(metrics.data[0]);
                } else {
                    const metData: MetricLink = {
                        isMain: false,
                        metric: met1.data,
                        themeId: '0',
                        metricLinkId: '',
                        metricId: id!
                    }
                    setMetricLink(metData);
                }
                setMetricLinks(metrics.data)
                let mu = metrics.data[0]?.metric?.childMetrics[0]?.unitOfMeasure;
                setMetricUnit(mu)
                
                let usename_check = (metrics.data[0]?.metric.dimension != null && metrics.data[0]?.metric.dimension.includes('Name'))
                setUseNameDim(usename_check)
                let graphData = generateGraphData(metrics.data, usename_check);
                setLineLabels(graphData[0]);
                setLineData(graphData[1]);
                // console.log('Metrix console', graphData[1], metricLinks)

                let bkColors: string[] = [];
                metrics.data.map((_e:any) => {
                    let randomColor = Math.floor(Math.random()*16777215).toString(16);
                    bkColors.push("#" + randomColor)
                })
                // ('colors', bkColors, graphData)
                setBgColors(bkColors);

                setMetricInfos(infos.data)
                setSourceCount(sources.data.length)
                setIsLoading(false)
            })
        )
        .catch((error:any) => console.log(error));
    }, [])

    const data = {
        labels: lineLabels,
        datasets: [
            {
                label: dataLabel,
                data: lineData,
                fill: false,
                // backgroundColor: 'rgba(0,255,0,0.2)',
                backgroundColor: bgColors,
                borderColor: '#4caf50',
            },
        ],
    }

    const options = {
        plugins: {
          legend: {
            display: false
          }
        }
    }

    return <>
        {/* <HeaderBanner image={headerImg} title={header} /> */}
        <div className="container" style={{backgroundColor: '#fcfcfc', paddingTop: '25px'}}>
        {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
            <div className='row'>
                <div className='col-8'>
                    <div className="row" style={{padding: '20px 0'}}>
                        <h3 style={{fontWeight: '700'}}>{metricLink?.metric?.name}</h3>
                        <p style={{color: '#444', fontSize: '16px'}}>{metricLink?.metric.measure} {(metricLink?.metric.unitOfMeasure === 'NA') ? '':metricLink?.metric.unitOfMeasure} {metricLink?.metric.description} {metricLink?.metric.year}</p>
                        <p style={{color: '#888', fontSize: '16px'}}>{metricLink?.metric.remarks}</p>
                    </div>
                    <div className="row" style={{padding: '10px 0'}}>
                        <div className="row">
                            <div className='col-md-8'>
                                <h4 style={{fontSize: '18px', color: '#444444'}}>Source:&nbsp;{(metricLink?.metric.sources) ? metricLink?.metric.sources : 'Source'}</h4>
                                {/* <h4 style={{fontSize: '18px', color: '#0000ff'}}>Source:&nbsp;{(metricLink?.metric.sources) ? metricLink?.metric.sources : 'Source'}</h4> */}
                            </div>
                            <div className='col-md-4'>
                            {sourceCount > 0 && (
                                <a href={`/metrics/source/${id}`} style={{fontSize: '18px', color: '#0000ff', textDecoration: 'none'}}><i className='fa fa-arrow-circle-o-right'>&nbsp;</i>View Underlining Data</a>
                            )}
                            </div>
                        </div>
                        {metricLinks.length > 0 && lineData.length > 0 ? (
                            <>
                            <table className='table table-striped table-bordered'>
                                <colgroup>
                                    <col style={{width: '40%'}} />
                                    <col style={{width: '60%'}} />
                                </colgroup>
                                <thead>
                                    <tr className="table-default" style={{backgroundColor: '#58b1e8', color: '#ffffff'}}>
                                        <th>{(useNameDim) ? 'Name' : 'Year'}</th>
                                        <th>Metric&nbsp;{metricUnit != '' && metricUnit != 'N/A'  && metricUnit != 'NA' ? `(${metricUnit})`: ''}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {metricLinks.slice(0, 1).map((e, idx) => (<>
                                    {(e.metric.childMetrics != null) && e.metric.childMetrics?.sort((a, b) => a.year > b.year ? 1 : -1).map((eii, ix) => (<>
                                        <tr key={`ktr-${ix}`}>
                                            <td>{getComputedLabel(eii, useNameDim)}</td>
                                            <td>{eii.measure}</td>
                                        </tr>                            
                                    </>))}
                                </>))}
                                </tbody>    
                            </table>
                            </>
                        ):(<>
                            <NoDataNotice item='Child metrics'/>
                        </>)}
                    </div>
                    <div className="row" style={{padding: '20px 0', maxHeight: '670px'}}>
                        {chartDisplay != 'Table' && chartDisplay != 'None' ? (<>
                            <h3 style={{fontSize: '18px', color: 'red', fontWeight: '700'}}>{metricLink?.metric?.name} Chart {metricLink?.metric?.year}</h3>
                        </>) : (<></>)
                        }

                        {chartDisplay == 'Pie' ? (<>
                            <Pie data={data} options={options} />
                        </>)
                        : (chartDisplay == 'Bar') ? (<>
                            <Bar data={data} options={options} />
                        </>) 
                        : (chartDisplay == 'Line') ? (<><Line data={data} options={options} /></>)
                        : (chartDisplay == 'Table') ? (<></>) 
                        : (<></>)
                        }
                    </div>
                    <div style={{clear: 'both'}}>&nbsp;</div>

                </div>
                <div className='col-md-4'>
                    <div className='row' style={{paddingTop: '10px'}}>
                        <h3 style={{fontSize: '18px', color: 'red', fontWeight: '700'}}>Related Infographics</h3>
                        <div>
                            {metricInfos.length > 0 && (                        
                                metricInfos.map((item, index) => (
                                    <div className="row" style={{paddingTop: (index === 0)? '36px':'0'}} key={index}>
                                        <InfographicCard item={item.infographic} key={`icf-${index}`} />
                                    </div>
                                ))
                            )}
                        </div> 
                    </div>                   
                </div>
            </div>
        </>)}

        </div>
    </>;
}

export default MetricSingle;