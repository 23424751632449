import { useEffect, useState } from 'react';
import './SectorAll.css';
import http from '../../lib/Http';
import axios from 'axios';
import LoadingNotice from '../../components/LoadingNotice';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

const SingleCard = ({item}:{item:any}) => {
    
    let mMetric: any;
    item.metricLinks.map((e: any) => {
        if (e.isMainMetric === true) {
            mMetric = e.metric; return;
        }
    })
    // console.log('Sector metrics', item)
    return (
        <div className="col-md-4" style={{border: 'none', padding: '15px 15px'}}>
            <a href={`sector/${item.themeId}`}>
                <div className='theme-content'>
                    <div className="theme-content-overlay"></div>
                    <img src={item.displayImageUrl} alt='...' className='theme-content-image'/>
                    
                    <div className="theme-content-details fadeIn-bottom">
                        <p className="theme-content-text font-wc-book">
                            {mMetric != null && (<>
                                {mMetric?.measure!} {(mMetric.unitOfMeasure === 'NA') ? '':mMetric?.unitOfMeasure} {mMetric?.description} {mMetric?.year}
                            </>)}
                        </p>
                    </div>
                    <div className='text-ctnr'>
                        <h3 className="theme-title font-wc-semi" style={{textDecoration: 'none !important'}}>{item.name}</h3>
                        <p className="theme-desc">{item.description}</p>
                    </div>
                </div>
            </a>
        </div>
    )
}

const SectorAll = () => {
    const [sectors, setSectors] = useState<Sector[]>([])
    const [isLoading, setIsLoading] = useState(true)
            
    useEffect(() => {
        // requests
        const requests = [
            `Themes/metrics/all`,
        ].map((url) => http.get(url));

        const fetchCategories = () => {
            // request
            axios.all(requests).then(
                axios.spread((theme) => {
                    setSectors(theme.data);
                    setIsLoading(false)
                })
            )
            .catch((error:any) => console.log(error));
        }

        fetchCategories();
    }, [])

    return (
        <>        
        <div className='container'>
            <div className="row mr-auto ml-auto text-center" style={{padding: '20px 0'}}>            
                {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                        {sectors.length <= 0 ? (
                            <LoadingNotice item='themes' />
                        ): (sectors.length > 0 && (
                            <>
                            <div className='row'>
                            {sectors
                                    .sort((a, b) => (a.priorityNumber < b.priorityNumber ? -1 : 1))
                                    .map((item, idx) => (<SingleCard item={item} key={`sct-${idx}`}/>)
                            )}
                            </div>
                            </>)
                        )}
                </>)}
            </div>            
        </div>
    </>
    );
}

export default SectorAll;