import { useRef } from "react";
import "./HeaderNew.css";
import logoImg from '../../assets/PT-logo.png';
import logoImg2 from '../../assets/DT-logo2.png';

const logoItem = {
  padding: 0, paddingTop: '12px'
}

export const Header = () => {
  const sectorRef = useRef<HTMLLIElement>(null);
    
  return (
    <nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light" style={{padding: 0}}>
      <div className="container-fluid d-flex justify-content-between">
        <div style={logoItem}>
          <a href="/" style={{textDecoration: 'none', color: '#000000'}} className="navbar-brand">
             <div style={{width: '48%', paddingBottom: '0px'}}><img src={logoImg} alt="DT logo" /></div>
          </a>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2">
                <li className="nav-item nav-items dropdown" ref={sectorRef}>
                  <a className="nav-link nav-links ucase dropdown-toggle" 
                    style={{color: "#fff"}} href={"#"} 
                    id="navbarDropdownMenuLink" 
                    role="button" data-bs-toggle="dropdown" 
                    aria-expanded="false"
                  >our achievements </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/sectors">By Themes</a></li>
                    <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/categories">By Categories</a></li>
                    <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/ministries">By Ministries</a></li>
                    <li><hr className="dropdown-divider"/></li>
                     <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/regions">By Regions</a></li> 
                    <li><hr className="dropdown-divider"/></li>
                    <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/projectsearch">Project Search</a></li>
                    <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/projectsummary">All Projects Summary</a></li>
                  </ul>
                </li>
                <li className="nav-item nav-items dropdown" >
                  <a className="nav-link nav-links ucase dropdown-toggle" 
                    style={{color: "#fff"}} href={"#"} 
                    id="navbarDropdownMedia" 
                    role="button" data-bs-toggle="dropdown" 
                    aria-expanded="false"
                  >Media </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMedia">
                    <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/media/photos">Photos</a></li>
                    <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/media/videos">Videos</a></li>
                    <li className="nav-item nav-items"><a className="dropdown-item nav-links ucase" href="/media/audios">Audios</a></li>
                  </ul>
                </li>
                <li className="nav-item nav-items">
                  <a className="nav-link nav-links ucase" href="/events">Events</a>
                </li>
                <li className="nav-item nav-items">
                  <a className="nav-link nav-links ucase" href="/articles">Articles</a>
                </li>
                {/* <li className="nav-item nav-items">
                  <a className="nav-link nav-links ucase" href="/download">Downloads</a>
                </li> */}
                <li className="nav-item nav-items">
                  <a className="nav-link nav-links ucase" href="/about">About</a>
                </li>
                <li className="nav-item nav-items">
                  <a className="nav-link nav-links ucase" href="/advancesearch">Site&nbsp;&nbsp;Search</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </nav>
  );
}