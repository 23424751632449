import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PhotoCard } from '../../components/cards/PhotoCard';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataNotice from '../../components/NoDataNotice';

const RegionPhotos = () => {

    const [photos, setPhotos] = useState<PhotoLink[]>([]);
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [dataLen, setDataLen] = useState(0);
    const [hasNext, setHasNext] = useState(true);

    const fetchData = async () => {
        setError(null);
      
        try {
            const response = await http.get(`PhotoLink/region/paged/${id}?Page=${page}&ItemsPerPage=9`);
            const data = await response.data ;
            let newData = [...photos, ...data]
            setDataLen(newData.length)

            setPhotos(newData);
            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        fetchData()

        // http
        //     .get<PhotoLink[]>(`PhotoLink/region/${id}`)
        //     .then((res) => {
        //         console.log('result', res.data);
        //         setPhotos(res.data);
        //     });
    }, [])

    return <>
        <div className="row" style={{padding: '20px 0'}}>

            {isLoading ? (<>
                    <LoadingSpinner />
                </>) : (<>
                <InfiniteScroll
                    dataLength={dataLen}
                    next={fetchData}
                    hasMore={hasNext}
                    loader={<p></p>}
                    endMessage={<p></p>}
                    style={{overflow: 'hidden', padding: '2px'}}
                >
                    {photos.length > 0 ? (
                    <>
                    <div className="row">
                        {photos.map((item, index) => (
                            <div className="col-md-4" key={`ic-${index}`}>
                                <PhotoCard item={item.photo} />
                            </div>
                        ))}
                    </div>
                    </>
                    ): (<><NoDataNotice item='Photos'/></>)}
                </InfiniteScroll>
            </>)}

            {/* {photos.length > 0 && (
                photos.map((item, index) => (
                    <div className="col-md-4" key={`p1c-${index}`}>
                        <PhotoCard item={item.photo} />
                    </div>
                ))
            )} */}
        </div>
    </>;
}
export default RegionPhotos;