import '../../components/UtilStyles.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../lib/Http';
import ReactPlayer from 'react-player';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import NoDataNotice from '../../components/NoDataNotice';
import InfiniteScroll from 'react-infinite-scroll-component';

const SectorAudios = () => {
    const [audios, setAudios] = useState<AudioLink[]>([]);
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [dataLen, setDataLen] = useState(0);
    const [hasNext, setHasNext] = useState(true);

    const fetchData = async () => {
        setError(null);
      
        try {
            const response = await http.get(`Paged/AudioLink/theme/paged/${id}?Page=${page}&ItemsPerPage=10`);
            const data = await response.data;
            let newData = [...audios, ...data]
            setDataLen(newData.length)

            setAudios(newData);

            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        // fetchData()

        http
              .get<AudioLink[]>(`AudioLink/theme/${id}`)
              .then((res) => {
                setAudios(res.data);
                setIsLoading(false);
              });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            {/* <div className='row'> */}
            <div className='row' style={{padding: '20px 0 20px 0'}}>

            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>

                {/* <InfiniteScroll
                    dataLength={dataLen}
                    next={fetchData}
                    hasMore={hasNext}
                    loader={<p></p>}
                    endMessage={<p>&nbsp;</p>}
                    style={{overflowX: 'hidden', padding: '2px'}}
                >
                    {audios.length > 0 ? (
                    <>
                        {audios.map((item, index) => (
                            <div className='col-md-4'>
                                <div style={{boxShadow: '2px 5px 7px #444444', marginBottom: '35px'}}>
                                    <ReactPlayer
                                        url={item.audio.audioUrl}
                                        height={'240px'}
                                        width={'100%'}
                                        controls={true}
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                    ): (<><NoDataNotice item='Audios'/></>)}
                </InfiniteScroll> */}

                {(audios.length > 0) ? audios.map((item, idx) => (
                    <>
                    <div className='col-md-4'>
                        <div style={{boxShadow: '2px 5px 7px #444444', marginBottom: '35px'}}>
                            <ReactPlayer
                                url={item.audio.audioUrl}
                                height={'240px'}
                                width={'100%'}
                                controls={true}
                            />
                        </div>
                    </div>
                    </>
                )): (<><NoDataNotice item='Audios'/></>)}
            </>)}
            </div>
        </div>        
    </>;
}
export default SectorAudios;