import { ProjectSearchCard } from "../cards/ProjectSearchCard";

export default function ProjectSearchItems({ currentItems }: {currentItems: any[]}) {
    return (
      <>
        {currentItems.length > 0 && ( currentItems.map((item, index) => (
                <ProjectSearchCard item={item} key={`pcs-${index}`}/>
            ))
        )}
      </>
    );
}