import { MapContainer, Marker, CircleMarker, Popup, TileLayer } from 'react-leaflet';

const mapContainerStyle = {
    width: '100%',
    height: '100vh',
};

export default function OpenMapComponent({projects}:{projects: any[]}) {
    return (<>
        <div className='row'>
            <div className='col-md-12'>
                <MapContainer center={[5.5560, -0.1969]} zoom={7} scrollWheelZoom={true} style={mapContainerStyle}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {/* <Marker position={[5.5560, -0.1969]}>
                        <Popup>Accra central!</Popup>
                    </Marker> */}
                    {/* <CircleMarker center={[5.5560, -0.1969]}>
                        <Popup>
                            This is Accra central!
                        </Popup>
                    </CircleMarker> */}

                    {projects.length > 0 && projects.map((proj, x) => (
                        <>
                        {!!proj.latitude && (
                            <Marker position={[proj.latitude, proj.longitude]} key={`${x}`}>
                                <Popup>
                                    {proj.name}
                                </Popup>
                            </Marker>
                        )}
                        </>
                        // <Marker position={[proj.latitude ?? 0, proj.longitude ?? 0]} key={`${x}`}>
                        //     <Popup>
                        //         {proj.name}
                        //     </Popup>
                        // </Marker>
                    ))}
                </MapContainer>                            
            </div>
        </div>
    </>)
}