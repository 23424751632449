import React, { useEffect, useState } from "react";
import { NavbarMini } from "../components/NavbarMini";
import { Outlet, useParams } from "react-router-dom";
import '../components/UtilStyles.css';
import http from "../lib/Http";
import axios from "axios";
import { HeaderBannerMore } from "../components/banners/HeaderBannerMore";

const MmdaMain: React.FC = () => {
    const {id} = useParams();

    const links: LinkProps[] = [
        {name: 'Projects', url: `/mmda/${id}/projects`},
        {name: 'Metrics', url: `/mmda/${id}/metrics`},
        {name: 'Photos', url: `/mmda/${id}/photos`},

        {name: 'Videos', url: `/mmda/${id}/videos`},
        {name: 'Audios', url: `/mmda/${id}/audios`},
        {name: 'Infographics', url: `/mmda/${id}/reports`},
        {name: 'Articles', url: `/mmda/${id}/articles`},

        {name: 'Project Summary', url: `/mmda/${id}/projsummary`},
        {name: 'Map', url: `/mmda/${id}/projectsmap`},
    ]

    const [mmda, setMmda] = useState<Mmda>();
    const [header, setHeader] = useState<string>('');

    useEffect(() => {
        const requests = [
            `Mmda/${id}`,
            // `Ministry/metrics/${id}`,
        ].map((url) => http.get(url));
        axios.all(requests).then(
            axios.spread((mmda, mets) => {
                setMmda(mmda.data);
                setHeader(mmda.data?.name)
            })
        )
        .catch((error:any) => console.log(error));
    }, []);
    
    return (
        <>
        <HeaderBannerMore title={header} />
        <NavbarMini navs={links}/>        
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{backgroundColor: '#fcfcfc'}}>
                        <Outlet context={[mmda]}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default MmdaMain;