import '../../components/UtilStyles.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Audio } from '../utils/CustomTypes';
import http from '../../lib/Http';
// import moment from 'moment';
import { ArticleCard } from '../../components/cards/ArticleCard';

const MinistryArticles = () => {
    const [articles, setArticles] = useState<ArticleLink[]>([]);
    const {id} = useParams();
    
    useEffect(() => {
        http.get<ArticleLink[]>(`ArticleLink/ministry/${id}`,)
              .then((res) => {
                setArticles(res.data);
              });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc', marginTop: '20px'}}>
            <div className="row" style={{marginBottom: '20px', paddingTop: '10px'}}>
                {articles.length > 0 && (                        
                    articles.map((item, index) => (
                        <div className="col-md-4" key={`psk-${index}`} style={{paddingBottom: '25px'}}>
                            <ArticleCard item={item.article} />
                        </div>
                    ))
                )}
            </div>
        </div>        
    </>;
}
export default MinistryArticles;