import { LazyLoadImage } from "react-lazy-load-image-component";
import { truncText } from "../Utils";
import "./CategoryCard.css";
import placeholderImg from "../../assets/1280/hallstatt.jpg";

const cardDet: React.CSSProperties =  {
    padding: '10px', 
    border: 'none',
    // position: 'relative',
}

const cardBdy: React.CSSProperties =  {
    padding: '10px 0',
    backgroundColor: '#f6f6f7',
}

const MetricBody = ({item}:{item: any}) => {
    return (
        <a href={`/metrics/${item?.metric.metricId}`} style={{textDecoration: 'none'}}>
            <h6 style={{ color: '#e03172', fontSize: '20px' }}>
                {item?.metric.measure} {(item?.metric.unitOfMeasure === 'NA') ? '':item?.metric.unitOfMeasure} {item?.metric.description}  
                &nbsp;&nbsp;{item?.metric.year}
            </h6>
        </a>
    )
}

function getDisplayMetric(item: LinkCardData) {
    let mMetric = null;
    if (item.cat.metricLinks && item.cat.metricLinks.length > 0)
    item.cat.metricLinks.map((e: MetricLink) => {
        if (e.isMain === true) {
            mMetric = e; return;
        }
    })
    return mMetric
}


const CategoryLinkCard = ({item}:{item: LinkCardData}) => {
    let mMetric = getDisplayMetric(item)
    
    return (
        <div className="col-md-4" style={{marginBottom: '15px'}}>
        <div className="card" style={cardDet}>
            <div style={{ position: 'relative' }}>
            <LazyLoadImage 
                src={item.cat.thumbnailUrl ?? ""} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                className={"card-img-top img-fluid cat-card-img-mn"}
            />
            </div>
            <div className="card-body" style={cardBdy}>
                <div className="row" style={{ padding: '5px' }}>
                    <div className="col-md-11" >
                        <a href={`/category/${item.cat.categoryId}`} className='cat-title'>{item.cat.name}</a>
                        <p className="cat-desc2-txt txt-lite">{truncText(item.cat.description, 80)}</p>

                        <div style={{ padding: '15px 0 0 5px', height: '100px' }}>
                            { (!!item.cat.metricLinks) && (<MetricBody item={mMetric} />) }
                        </div>
                    </div>
                    <div className="col-md-1" style={{position: 'relative', }}>
                        <a href={`/category/${item.cat.categoryId}`} 
                            style={{
                                textDecoration: 'none', 
                                fontSize: '30px',  
                                color: '#e03172', 
                                position: 'absolute', 
                                bottom: '45%', 
                                right: '15px',
                                // border: '1px solid red'
                            }}
                        >&gt;</a>
                    </div>
                </div>
            </div>                        
        </div>
        </div>
    )
}

function getSectorCatMetric(item: Category): MetricLink | null {
    let mMetric: MetricLink | null = null;
    
    if (item.metricLinks && item.metricLinks.length > 0)
    item.metricLinks.forEach((e: MetricLink) => {
        if (e.isMain === true) { mMetric = e; return e; }
    })
    return mMetric
}

/**
 * Category card for Sector/Categoy page
 * @param param0 
 * @returns 
 */
const CategoryCard = ({item}:{item: Category}) => {
    let mMetric = getSectorCatMetric(item)

    return (
        <>
        {item != null && (        
            <div className="col-md-4" style={{marginBottom: '15px'}}>
            <div className="card" style={cardDet}>
                <div style={{ position: 'relative' }}>
                <LazyLoadImage 
                    src={item?.thumbnailUrl ?? ""} 
                    placeholderSrc={placeholderImg}
                    effect='blur'
                    alt="..."
                    className={"card-img-top img-fluid cat-card-img-mn"}
                />
                </div>
                <div className="card-body" style={cardBdy}>
                    <div className="row" style={{ padding: '5px' }}>
                        <div className="col-md-11">
                            <a href={`/category/${item.categoryId}`} className='cat-title-txt'>{item.name}</a>
                            <p className="cat-desc2-txt txt-lite">{truncText(item.description, 80)}</p>
                            <br/>
                            <div style={{ padding: '15px 0 0 5px', height: '100px' }}>                            
                            {mMetric && (
                                <a href={`/metrics/${mMetric.metricId}`} style={{textDecoration: 'none'}}>
                                    <h6 style={{ color: '#e03172', fontSize: '20px' }}>
                                        {mMetric.metric.measure} {(mMetric.metric.unitOfMeasure === 'NA') ? '':mMetric.metric.unitOfMeasure} {mMetric.metric.description} {mMetric?.metric.year}
                                    </h6>
                                </a>
                                )
                            }
                            </div>
                        </div>
                        <div className="col-md-1" style={{position: 'relative'}}>
                            <a href={`/category/${item.categoryId}`} 
                                style={{
                                    textDecoration: 'none', 
                                    fontSize: '30px',  
                                    color: '#e03172', 
                                    position: 'absolute', 
                                    bottom: '45%', 
                                    right: '15px'
                                }}
                            >&gt;</a>
                        </div>
                    </div>
                </div>                        
            </div>
            </div>
        )}
        </>
    )
}

export {CategoryCard, CategoryLinkCard }