import './Footer.css';

export default function Footer() {
    return (
    <footer style={{backgroundColor: '#444444'}}>
        <div style={{height: '10px'}}>&nbsp;</div>
        <div className="container mt-0 pb-5">
            <div className="row" style={{textTransform: "uppercase"}}>
                <div className="col-md-6 pt-2 copyright">
                    <p className="text-light">&copy;{(new Date().getFullYear())} Performance tracker. all rights reserved</p>
                </div>
                <div className="col-md-3 pt-2">&nbsp;</div>
                <div className="col-md-3 pt-2">
                    <div className="row">
                        <div className="col-md-6">
                            <a href="/privacypolicy" className="text-decoration-none text-light">PRIVACY POLICY</a>
                        </div>
                        <div className="col-md-6">
                            <a href="#" className="text-decoration-none text-light row">TERMS OF SERVice</a>                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}