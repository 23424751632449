import banner from '../assets/banners/about_banner.png';
import placeholderImg from '../assets/media_banner.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return <>
        {/* <HeaderBanner image={banner} title={''} /> */}
        <div className="my-cnt" style={{'border': 'none'}}>
            <LazyLoadImage 
                src={banner} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                className='abt-img'
            />
            <div className='o-head'>
                <h3>{''}</h3>
            </div>
        </div>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                <div style={{padding: '50px 0px 10px 10px'}}>
                    <h3 style={{textTransform: 'uppercase'}}>Privacy Policy</h3>
                </div>
                <div id="priv">
                    <p>
                    Welcome to government of Ghana Performance Tracker (Ghana Gov Performance Tracker App). At Performance Tracker, we are committed to protecting your privacy. This Privacy Policy outlines the types of information we may collect from you or that you may provide when you use our website, as well as our practices for collecting, using, maintaining, protecting, and disclosing that information.
                        <ol>
                            <li>Information We Collect
                                <ol>
                                    <li>Personal Information: We may collect personal information that can be used to identify you as an individual, such as your name, email address, postal address, phone number, and any other information you provide voluntarily when you interact with the Website. 
                                    </li>
                                    <li>
                                    Non-Personal Information: We may also collect non-personal information about your interaction with the Website, such as your IP address, browser type, referring/exit pages, operating system, and other similar information. 
                                    </li>
                                </ol>
                            </li>
                            <li>Use of Information
                                <ol>
                                    <li>Personal Information: We may use the personal information you provide to us for various purposes, including but not limited to: 
                                        Providing you with the products, services, or information you request. 
                                        Improving our website and enhancing your user experience. 
                                        Communicating with you about updates, promotions, and news regarding the Website. 
                                        Responding to your inquiries and providing customer support. 
                                        Conducting research and analysis to improve our products and services.</li>
                                    <li>
                                        Non-Personal Information: Non-personal information collected may be used for 
                                        statistical analysis, troubleshooting, improving the Website's functionality, and understanding user trends.
                                    </li>
                                </ol>
                            </li>
                            <li>Information Sharing
                                <ol>
                                    <li>Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our website, 
                                        conducting our business, or servicing you, as long as they agree to keep this information confidential. </li>
                                    <li>Legal Requirements: We may also disclose your personal information if required to do so by law or in response to a subpoena, court order, or other legal process. 
                                    </li>
                                </ol>
                            </li>
                            <li>Security<br/>
                            We implement reasonable security measures to protect your personal information from  unauthorized access, disclosure, alteration, or destruction. 
                            However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, 
                            while we  strive to use commercially acceptable means to protect your personal information, 
                                we cannot guarantee its absolute security. 
                            </li>
                            <li>Third-Party Links<br/>
                            Our website may contain links to third-party websites or services that are not operated by us. Once you click on a third-party link, 
                            you will be directed to that third-party’s website. We strongly advise you to review the Privacy Policy of every website you visit. We have no  control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.  
                            </li>
                            <li>Children's Privacy<br/>
                            Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. 
                            If you are under 13, please do not provide any personal information on our website or through any of its features.  
                            </li>
                            <li>Changes to This Privacy Policy<br/>
                            We reserve the right to modify this Privacy Policy at any time, so please review it frequently.  Changes and clarifications will take effect immediately upon their posting on the Website. 
                            If  we make material changes to this Privacy Policy, we will notify you here, by email, or by means of a notice on our homepage.  
                            </li>
                            <li>Contact Us<br/>
                            If you have any questions, concerns, or suggestions regarding this Privacy Policy, please contact us at 233500000628.  
                            By using the Website, you consent to our collection, use, and sharing of your information as described in this Privacy Policy.   
                            </li>

                        </ol>
                    </p>
                </div>
                <div style={{paddingBottom: '0px'}}>&nbsp;</div>                
            </div>

            <div className="row" style={{padding: '20px 0'}}>
                <div style={{padding: '20px 0px 10px 10px'}}>
                    <h3 style={{textTransform: 'uppercase'}}>Terms and Conditions</h3>
                </div>
                <div id="priv">
                    <p>Effective Date: 01/01/2024</p>
                    <p>
                    Welcome to Ghana Gov Performance Tracker (Ghana Gov Performance Tracker App). These Terms and Conditions govern your access to and use of the Website. By accessing or using the Website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, please refrain from accessing or using the Website.
                    
                        <ol>
                            <li>Intellectual Property
                                <ol>
                                    <li>Personal Information: We may collect personal information that can be used to identify you as an individual, such as your name, email address, postal address, phone number, and any other information you provide voluntarily when you interact with the Website. 
                                    </li>
                                    <li>
                                    Non-Personal Information: We may also collect non-personal information about your interaction with the Website, such as your IP address, browser type, referring/exit pages, operating system, and other similar information. 
                                    </li>
                                </ol>
                            </li>
                            <li>Use of Information
                                <ol>
                                    <li>Ownership: The Website and its entire contents, features, and functionality, including but not limited to text, graphics, 
                                        images, logos, and software, are the property of Ghana Gov Performance Tracker or its licensors and are protected by copyright, trademark, and other intellectual property laws.</li>
                                    <li>
                                    Limited License: Ghana Gov Performance Tracker grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Website for personal and non-commercial purposes. 
                                    You may not modify, reproduce, distribute, display, or exploit any part of the Website without prior written permission from Ghana Gov Performance Tracker. 
                                    </li>
                                </ol>
                            </li>
                            <li>User Conduct
                                <ol>
                                    <li>Prohibited Activities: When using the Website, you agree to abide by the following rules: 
                                        <ol>
                                            <li>Do not violate any applicable laws or regulations.</li>
                                            <li>Do not engage in any illegal, harmful, or fraudulent activities.</li>
                                            <li>Do not transmit any viruses, malware, or other harmful code.</li>
                                            <li>Do not interfere with or disrupt the functioning of the Website or its servers.</li>
                                            <li>Do not attempt to gain unauthorized access to any portion of the Website.</li>
                                        </ol>
                                    </li>
                                    <li>User Content: You may have the opportunity to submit, post, or display content on the Website, such as comments or reviews. By submitting content, 
                                        you grant Ghana Gov Performance Tracker Apps a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable right to use, 
                                        reproduce, modify, adapt, publish, translate, distribute, and display such content on the Website.
                                    </li>
                                </ol>
                            </li>
                            <li>Limitation of Liability
                                <ol>            
                                    <li>Disclaimer:The Website and its content are provided on an "as is" and "as available" basis without any warranties of any kind, 
                                        whether express or implied. Ghana Gov Performance Tracker does not warrant that the Website will be error-free, secure, or uninterrupted.
                                    </li>
                                    <li>Limitation of LiabilityIn no event shall Ghana Gov Performance Tracker, its affiliates, or their respective officers, directors, employees, 
                                        or agents be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of the Website.
                                    </li>
                                </ol>
                            </li>
                            <li>Third-Party Links<br/>
                                The Website may contain links to third-party websites or services that are not operated by Ghana Gov Performance Tracker. We provide these links for your convenience, 
                                but we have no control over and assume no responsibility for the content, 
                                privacy policies, or practices of any third-party websites or services. You access and use these third-party websites or services at your own risk.  
                            </li>

                            <li>Modifications to the Terms and Conditions<br/>
                                Ghana Gov Performance Tracker reserves the right to modify or update these Terms and Conditions at any time without prior notice. The updated version will be effective upon posting on the Website. 
                                It is your responsibility to review these Terms and Conditions periodically for any changes.
                            </li>
                            <li>Governing Law and Jurisdiction
                                Ghana Gov Performance Tracker reserves the right to modify or update these Terms and Conditions at any time without prior notice. The updated version will be effective upon posting on the Website. 
                                It is your responsibility to review these Terms and Conditions periodically for any changes.  
                            </li>
                            <li>Contact Us<br/>
                                If you have any questions, concerns, or suggestions regarding these Terms and Conditions, 
                                please contact us at 233500000628. By accessing or using the Website, you agree to be bound by these Terms and Conditions.     
                            </li>
                        </ol>
                    </p>
                </div>
                <div style={{paddingBottom: '10px'}}>&nbsp;</div>                
            </div>
        </div>
    </>;
}
export default PrivacyPolicy;