import React, { useRef, useState } from 'react';
import banner from '../assets/banners/contact-us1.png';
import placeholderImg from '../assets/media_banner.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './About.css';
import { useForm, SubmitHandler } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import http from '../lib/Http';
import axios from 'axios';
import './ContactUs.css';

type FormValues = {
    fullname: string;
    email: string;
    phoneNumber: string;
    subject: string;
    message: string;
    errMessage?: string;
};

const APP_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const APP_SITE_KEY = process.env.REACT_APP_SITE_KEY ?? '';

const VerifyToken = async (token: string|null) => {
    try{
        // let resp = await axios.post(`https://www.google.com/recaptcha/api/siteverify?secret=${APP_SECRET_KEY}&response=${token}`);
        let resp = await axios({
            url: `https://www.google.com/recaptcha/api/siteverify?secret=${APP_SECRET_KEY}&response=${token}`,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        });
        if (resp.status === 200) {
            return {
                success:true,
                message: "Token successfully verified",
                data: resp.data
            };
        }
    } catch(error:any) {
        return {
            success:false,
            message: "Error verifying ReCaptcha token"
        }
    }
}

const ContactUs = () => {
    
    const [errorMessage, setErrorMessage] = useState<string|undefined>('');
    const [msgType, setMsgType] = useState<boolean>(false);
    const [recaptchaValue, setRecaptchaValue] = useState<string|null>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();

    const handleReCaptchaChange = (value: string|null) => {
        setRecaptchaValue(value);
    };

    const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
        // const recaptchaValue = await recaptchaRef?.current?.executeAsync();
        if(recaptchaRef.current){
            
            // const recaptchaValue = recaptchaRef.current?.getValue();
            if (recaptchaValue === null) {
                setErrorMessage('Sorry please check reCAPTCHA to verify you are human');
                setMsgType(false);
                return;
            }

            http.post(`ContactUs/add`, data)
                    .then((res: any) =>  {
                        setErrorMessage('Your message has been sent successfully!');
                        setMsgType(true);
                        (document.getElementById('frmContactUs') as HTMLFormElement).reset()
                        
                    })
                    .catch((error:any) => console.log(error));
        }else{
            setErrorMessage('Sorry you is not verified as human');
            setMsgType(false);
        }
        // timeout notification
        setTimeout(() => {
            setErrorMessage(undefined);
            setMsgType(false);
        }, 5000);
    };

    return <>
        <div className="my-cnt no-border">
            <LazyLoadImage 
                src={banner} 
                placeholderSrc={placeholderImg}
                effect='blur'
                alt="..."
                className='abt-img'
            />
            <div className='o-head'>
                <h3>{''}</h3>
            </div>
        </div>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>

                <div className='col-md-6 offset-md-3'>
                    <div>
                        <div className='page-header'>
                            <h3>Contact Us</h3>
                        </div>
                        <p style={{color: '#999999'}}>Get in touch with us by filling the form below</p>
                    </div>
                    <div className='card'>                        
                        <div className='card-body'>
                            {errorMessage && (
                                <div className={(msgType === true) ? 'alert alert-success': 'alert alert-danger'}>{errorMessage}</div>
                            )}
                            <form className='row g-4' onSubmit={handleSubmit(onSubmit)} id='frmContactUs'>

                                <div className="col-md-6">
                                    <input type="text" className="form-control" {...register("fullname", { required: true })} 
                                        id='fullname' 
                                        placeholder='Full name' 
                                    />
                                    {errors.fullname && <span className='form-text'>This field is required</span>}
                                </div>
                                <div className="col-md-6">
                                    <input type="email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} 
                                        className="form-control" 
                                        id="email" 
                                        placeholder='Email' 
                                    />
                                    {errors.email?.type === "required" && <span className='form-text'>This field is required</span>}
                                    {errors.email?.type === "pattern" && <span className='form-text'>Invalid email format</span>}
                                </div>
                            
                                <div className="col-md-6">
                                    <input 
                                        type="tel" 
                                        {...register("phoneNumber", { required: true, pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i })} 
                                        className="form-control" id="phoneNumber" 
                                        placeholder='Phone Number' 
                                    />
                                    {errors.phoneNumber?.type === "required" && <span className='form-text'>This field is required</span>}
                                    {errors.phoneNumber?.type === "pattern" && <span className='form-text'>Invalid telephone number format</span>}
                                </div>
                                <div className="col-md-6">
                                    <input type="text" {...register("subject", { required: true})}
                                        className="form-control" 
                                        id="subject" 
                                        placeholder='Subject' 
                                    />
                                    {errors.email?.type === "required" && <span className='form-text'>This field is required</span>}
                                    {errors.email?.type === "pattern" && <span className='form-text'>Invalid email format</span>}
                                </div>
                                                                
                                <div className="col-md-12">
                                    {/* <label htmlFor="message" className="form-label" style={label}>Comment</label> */}
                                    <textarea {...register("message", { required: true })} 
                                        className="form-control" 
                                        id="message" 
                                        placeholder='Your message here'
                                    />
                                    {errors.message && <span className='form-text'>This field is required</span>}
                                </div>
                                <div className="col-md-6">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={APP_SITE_KEY}
                                    onChange={handleReCaptchaChange}
                                />
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" className='btn btn-primary'>Submit</button>                                    
                                </div>
                            </form>      

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default ContactUs;