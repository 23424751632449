import { useEffect, useState } from 'react';
import http from '../../lib/Http';
import { PhotoCard } from '../../components/cards/PhotoCard';
import { useParams } from 'react-router-dom';

const PhotoSingle = () => {
    const [photo, setPhoto] = useState<Photo>();
    var {id} = useParams();

    useEffect(() => {
        // http.get<Photo>(`/Photo/${id}`)
        http.get<Photo>(`/Photo/approved/all`)
            .then((res) => {
                setPhoto(res.data);
            });
    }, [])
    
    return <>        
        <div className='row'>
        {            
            photo && (
                <>                
                    <div className="col-md-4"></div>
                    <div className="col-md-4" style={{padding: '15px'}}>
                        <PhotoCard item={photo} key={`iim-ph01`} />
                    </div>
                    <div className="col-md-4"></div>
                </>
            )
        }
        </div>
    </>;
}

export default PhotoSingle;