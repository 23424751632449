import '../../components/UtilStyles.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../lib/Http';
import MetricCard from '../../components/cards/MetricCard';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import { SectionHeaderMinUC } from '../../components/Utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataNotice from '../../components/NoDataNotice';

const MinistryMetrics = () => {
    const [metrics, setMetrics] = useState<Metric[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [metricsOrdered, setMetricsOrdered] = useState<Record<string, any>>({});
    const {id} = useParams();
    const [items, setItems] = useState<any[]>([]);
    // const [isLoadingPg, setIsLoadingPg] = useState(false);
    const [error, setError] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [dataLen, setDataLen] = useState(0);
    const [hasNext, setHasNext] = useState(true);

    const fetchData = async () => {
        // setIsLoadingPg(true);
        setError(null);
      
        try {
            // Metric/ministry/paged/4?Page=1&ItemsPerPage=20
            const response = await http.get(`Metric/ministry/paged/${id}?Page=${page}&ItemsPerPage=10`,);
            const data = await response.data ;
            let newData = [...items, ...data]
            setDataLen(newData.length)

            let ordered_arr: Record<string, any> = {};
            newData.forEach((e: any) => {
                const key = e.ministryMetricType?.name;
                if (!ordered_arr[key]) ordered_arr[key] = [];
                ordered_arr[key].push(e);
            });
            setMetricsOrdered(ordered_arr)
            setItems(newData)

            setPage(prevPage => prevPage + 1);
            setHasNext((data.length > 0) ? true : false)
        } catch (error) {
            setError((error as Error)?.message || 'An error occurred while fetching data.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    
        // http.get(`Metric/ministry/${id}`,)
        //     .then((mets) => {
        //         let metLinks: MetricLink[] = [];
        //         setMetrics(mets.data);
        //         console.log('metrics', mets.data)

        //         let ordered_arr: Record<string, any> = {};
        //         mets.data.forEach((e: any) => {
        //             const key = e.ministryMetricType?.name;
        //             if (!ordered_arr[key]) ordered_arr[key] = [];
        //             ordered_arr[key].push(e);
        //         });
        //         // console.log('ordered_arr', ordered_arr)
        //         setMetricsOrdered(ordered_arr)
        //         setIsLoading(false)
        //     });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div className="row" style={{padding: '20px 0'}}>
                {isLoading ? (<>
                        <div className='row'>
                            <LoadingSpinner />
                        </div>
                    </>) : (<>
                        <InfiniteScroll
                            dataLength={dataLen}
                            next={fetchData}
                            hasMore={hasNext} // Replace with a condition based on your data source
                            loader={<p></p>}
                            endMessage={<p></p>}
                            style={{overflowX: 'hidden', padding: '2px'}}
                        >
                            <div className="row">
                                {Object.keys(metricsOrdered).length > 0 ? (
                                    Object.keys(metricsOrdered).map((key, i) => (
                                        <>
                                            <div className="row" style={{padding: '20px 10px'}} key={`sph-${i}`}>
                                                <SectionHeaderMinUC title={key}  key={`shu-${i}`}/>
                                            </div>
                                            <div className="row"  key={i}>
                                                {metricsOrdered[key].map((child:any, index:number) => (
                                                    <div className="col-md-4" style={{ paddingBottom: '15px'}} key={`p1-g-${index}`}>
                                                        <MetricCard item={child} key={`mc-${index}`}/>
                                                    </div>
                                                ))}
                                            </div>
                                        </>                        
                                    ))
                                ) : (<><NoDataNotice item='Metrics'/></>)}
                            </div>
                        </InfiniteScroll>

                    {metrics.length > 0 && (
                        <>
                            {Object.keys(metricsOrdered).length > 0 && (                        
                                Object.keys(metricsOrdered).map((key) => (
                                    <>
                                        <div className="row" style={{padding: '20px 10px'}}>
                                            <SectionHeaderMinUC title={key}  />
                                        </div>
                                        <div className="row">
                                            {metricsOrdered[key].map((child:any, index:number) => (
                                                <div className="col-md-4" style={{ paddingBottom: '15px'}} key={`p1-g-${index}`}>
                                                    <MetricCard item={child} />
                                                </div>
                                            ))}
                                        </div>
                                    </>                        
                                ))
                            )}
                        </>
                    )}
                </>)}
            </div>
        </div>        
    </>;
}

export default MinistryMetrics;