import '../../components/UtilStyles.css';
import ReactPlayer from 'react-player';
import http from '../../lib/Http';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import NoDataNotice from '../../components/NoDataNotice';

const CategoryAudios = () => {
    const {id} = useParams();
    const [audios, setAudios] = useState<AudioLink[]>([]);

    useEffect(() => {
        http.get(`/AudioLink/category/${id}`)
            .then((res) => {
                setAudios(res.data);
            });
    }, [])

    return <>
        <div className="container" style={{backgroundColor: '#fcfcfc'}}>
            <div>&nbsp;</div>
            <div className='row' style={{padding: '10px 0 20px 0'}}>
            {audios.length > 0 ? (                                        
                audios.map((item, index) => (
                    <div className="col-md-4">
                        <div className="card" style={{ border: 'none'}} key={index}>
                            <ReactPlayer url={item.audio.audioUrl} height={'240px'} width={'100%'}/>
                            <div className="card-body">
                                <p className="card-text" style={{ color: '#999999' }}>{item.audio.description}</p>
                            </div>
                        </div>
                    </div>
                ))
            ): (<><NoDataNotice item='Audios'/></>)}
            </div>
            <div>&nbsp;</div>
        </div>
    </>;
}
export default CategoryAudios;