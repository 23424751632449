import { ChangeEvent } from "react"

export const SectorFilter = ({sectors, handler}: {sectors: Sector[], handler: (event: ChangeEvent<HTMLSelectElement>) => void}) => {
    return (<>
        <div className="row">
            <div>&nbsp;</div>
            <div className="col-md-6">                    
                <div className="input-group input-group-lg mb-3">
                    <span className="input-group-text" style={{height: '100%'}}>Filter by Theme</span>
                    {/* <select className="form-control" id='searchColumn' onChange={handleSelectChange}> */}
                    <select className="form-control" id='searchColumn' onChange={handler} style={{border: '1px solid #888'}}>
                        <option value='' defaultValue={''}>--- Select theme ---</option>
                        {sectors.map((opt, idx) => (
                            <option value={opt.themeId} key={`opt-${idx}`}>{opt.name}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    </>)
}