import { useEffect, useState } from 'react';
import './RegionIntro.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

import { createNavList } from '../../lib/Funcs';
// import { GenerateDynLinks, generateDynLinkRegions } from "../../utils/NavDropdownLink";


const RegionMmda = () => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const [dynLinks, setDynLinks] = useState<LinkData[]>([])
    
    
    useEffect(() => {
        // request list
        const requests = [
            `Mmda/region/${id}`,
        ].map((url) => http.get(url));

        const fetchData = () => {
            let tmpCats:LinkData[] = [];
            // request
            axios.all(requests).then(
                axios.spread(( mmdas ) => {
                    let cList: any[] = []
                    mmdas.data.forEach((e : any) => {
                        cList.push({categoryId: e.mmdaId, name: e.name})
                    });

                    tmpCats = createNavList(cList, 'mmda', false);
                    // var tmpFinal = ArrayChunkProc(tmpCats);
                    setDynLinks(tmpCats)
                    setIsLoading(false);
                })
            )
            .catch((error:any) => console.log(error));
        }

        setIsLoading(true);
        fetchData();
    }, [])

    return (
        <>
        {isLoading ? (<>
            <div className='row'>
                <LoadingSpinner />
            </div>
        </>) : (<>
        
        <div>
            <div className="row" style={{padding: '20px 0'}}>
                {dynLinks
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((item, ix) => (
                        <div className="col-md-3" key={`cn-${item.ky}-${ix}`} style={{padding: '10px 15px'}}>
                            <a className="ccase " href={item.url} style={{textDecoration: 'none'}}>{item.name}</a>
                        </div>
                    ))} 
            </div>
            <div style={{clear: 'both'}}>&nbsp;</div>
        </div>
        </>)}
        </>
    );
}

export default RegionMmda;