import { useEffect, useState, ChangeEvent } from 'react';
import './CategoryAll.css';
import http from '../../lib/Http';
import axios from 'axios';
import { createCardNavNewList, } from '../../lib/Funcs';
import { CategoryLinkCard } from '../../components/cards/CategoryCard';
import { SectionHeaderBlack } from '../../components/Utils';
import { SectorFilter } from '../../components/filters/SectorFilter';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';

const CategoryAll = () => {
    const [categoryCardLinks, setCategoryCardLinks] = useState<CatLinks[]>([])
    const [catCount, setCatCount] = useState(0)
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.value;
        let selCats:any[] = [];
        categories.map((cat) => {
            if(cat.themeId == selectedOption) selCats.push(cat);
        })
        let nCats = (selectedOption === '') ? categories : selCats;
        let finalCats = createCardNavNewList(nCats, 'category', true, sectors);
        setCategoryCardLinks(finalCats);
    };
        
    useEffect(() => {
        const requests = [
            'ThemeCategoryLink/all',
            'Themes/all', 
        ].map((url) => http.get(url));

        const fetchData = () => {
            let tmpCats:CatLinks[] = [];
            
            // request
            axios.all(requests).then(
                axios.spread((cats, themes, ) => {
                    setCatCount(cats.data.length);
                    setCategories(cats.data)
                    tmpCats = createCardNavNewList(cats.data, 'category', true, themes.data);
                    tmpCats.sort((a, b) => (a.theme < b.theme ? -1 : 1));
                    setCategoryCardLinks(tmpCats);
                    setSectors(themes.data);
                    setIsLoading(false);
                })
            )
            .catch((error:any) => console.log(error));
        }

        fetchData();
    }, [])

    return (
        <div className='container'>
            <SectorFilter sectors={sectors} handler={handleSelectChange} />
            <div className="row" style={{padding: '20px 0'}}>


            {isLoading ? (<>
                <div className='row'>
                    <LoadingSpinner />
                </div>
            </>) : (<>
                {catCount > 0 && (categoryCardLinks.length > 0 && (
                    <>                        
                    {
                         categoryCardLinks
                            .map((items, idx) => (
                            <>
                            <SectionHeaderBlack txt={items.theme} link={`/sector/${items.themeId}`} key={`ahk-${idx}`}/>
                            <div className='row' key={`cnt-${idx}`}>
                            {
                                items.data.map((item, x) => (                                    
                                    <CategoryLinkCard item={item} key={`cl2-${x}`} />
                                ))
                            }
                            </div>
                            </>
                        ))
                    }
                    </>)
                )}
            </>)}
            </div>
        </div>
    );
}

export default CategoryAll;