import * as React from 'react';
import {Routes,Route } from 'react-router-dom'
import SectorAudios from './pages/sector/SectorAudios';
import SectorPhotos from './pages/sector/SectorPhotos';
import SectorVideos from './pages/sector/SectorVideos';
import SectorCategories from './pages/sector/SectorCategories';
import SectorMain from './multi-pages/SectorMain';
import ErrorPage from './pages/ErrorPage';
import CategoryMain from './multi-pages/CategoryMain';
import CategoryProjects from './pages/category/CategoryProjects';
import CategoryMetrics from './pages/category/CategoryMetrics';
import CategoryVideos from './pages/category/CategoryVideos';
import Download from './pages/Download';
import About from './pages/About';
import SectorMetrics from './pages/sector/SectorMetrics';
import SectorReports from './pages/sector/SectorReports';
import SectorArticles from './pages/sector/SectorArticles';
import CategoryReports from './pages/category/CategoryReports';
import CategoryAudios from './pages/category/CategoryAudios';
import CategoryPhotos from './pages/category/CategoryPhotos';
import EventSingle from './pages/EventSingle';
import SectorAll from './pages/sector/SectorAll';
import CategoryAll from './pages/category/CategoryAll';
import MinistryAll from './pages/ministry/MinistryAll';
import ProjectSearch from './pages/project/ProjectSearch';

import ProjectAll from './pages/project/ProjectAll';
import MetricSingle from './pages/metric/MetricSingle';
import ContactUs from './pages/ContactUs';

import ProjectSingle from './pages/project/ProjectSingle';
import MinistryMain from './multi-pages/MinistryMain';
import CategoryArticles from './pages/category/CategoryArticles';
import MediaMain from './multi-pages/MediaMain';
import AdvanceSearch from './pages/search/AdvanceSearch';
import RegionAll from './pages/region/RegionAll';
import RegionMain from './multi-pages/RegionMain';
import RegionMmda from './pages/region/RegionMmda';
import RegionReports from './pages/region/RegionReports';
import RegionProjects from './pages/region/RegionProjects';
import RegionArticles from './pages/region/RegionArticles';
import RegionAudios from './pages/region/RegionAudios';
import RegionMetrics from './pages/region/RegionMetrics';
import RegionPhotos from './pages/region/RegionPhotos';
import RegionVideos from './pages/region/RegionVideos';
// import MediaPhotosDirect from './pages/media/MediaPhotosDirect';
import MediaVideosDirect from './pages/media/MediaVideosDirect';
import MediaAudiosDirect from './pages/media/MediaAudiosDirect';
import MinistryMetrics from './pages/ministry/MinistryMetrics';
import VideoSingleDirect from './pages/media/VideoSingleDirect';
import MinistryCategories from './pages/ministry/MinistryCategories';
import MinistryPhotos from './pages/ministry/MinistryPhotos';
import MinistryVideos from './pages/ministry/MinistryVideos';
import MinistryReports from './pages/ministry/MinistryReports';
import MinistryArticles from './pages/ministry/MinistryArticles';
import MinistryAudios from './pages/ministry/MinistryAudios';
import RegionCategories from './pages/region/RegionCategories';
import MinistryProjects from './pages/ministry/MinistryProjects';
import PhotoSingle from './pages/media/PhotoSingle';
import MmdaProjects from './pages/mmda/MmdaProjects';

import RegionalMetricSingle from './pages/metric/RegionalMetricSingle';
import MmdaMain from './multi-pages/MmdaMain';
import MetricSourceMore from './pages/metric/MetricSourceMore';
import RegionalMetricSourceMore from './pages/metric/RegionalMetricSourceMore';
import MmdaPhotos from './pages/mmda/MmdaPhotos';
import PhotoAll from './pages/media/PhotoAll';
import MinProjectSummary from './pages/ministry/ProjectSummary';
import MmdaProjectSummary from './pages/mmda/ProjectSummary';
import RegProjectSummary from './pages/region/ProjectSummary';
import RegProjectDetail from './pages/region/ProjectDetail';
import MmdaProjectDetail from './pages/mmda/ProjectDetail';
import MinProjectDetail from './pages/ministry/ProjectDetail';
import CatProjectSummary from './pages/category/ProjectSummary';
import CatProjectDetail from './pages/category/ProjectDetail';
import SectorCategoriesB4 from './pages/sector/SectorCategoriesB4';
import CategoryProjectsMap from './pages/category/CategoryProjectsMap';
import MinistryProjectsMap from './pages/ministry/MinistryProjectsMap';
import RegionProjectsMap from './pages/region/RegionProjectsMap';
import AllProjectSummary from './pages/project/AllProjectSummary';
import MmdaProjectsMap from './pages/mmda/MmdaProjectsMap';
import AllProjectDetail from './pages/project/ProjectDetail';
import MmdaArticles from './pages/mmda/MmdaArticles';
import MmdaVideos from './pages/mmda/MmdaVideos';
import MmdaAudios from './pages/mmda/MmdaAudios';
import MmdaReports from './pages/mmda/MmdaReports';
import MmdaMetrics from './pages/mmda/MmdaMetrics';
import HomeComingSoon from './pages/HomeComingSoon';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AcceptPhoneno from './pages/account/AcceptPhoneno';
import AcceptOtp from './pages/account/AcceptOtp';
import Login from './pages/auth/Login';
import PrivateRoute from './pages/auth/PrivateRoute';


const Home = React.lazy(() => import('./pages/Home'));
const Event = React.lazy(() => import('./pages/Events'));
const Article = React.lazy(() => import('./pages/Articles'));
const Loading = () => <p>Loading ...</p>;

const Main = () => {
    return (
        <React.Suspense fallback={<Loading />}>
            <Routes>
                <Route path='/login' element={<Login/>} />

                {/* <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<Home />} />
                    <Route path="/alpha" element={<Home />} />
                </Route> */}

                {/* <Route path='/' element={<HomeComingSoon/>} /> */}
                <Route path='/alpha' element={<Home/>} />
                <Route path='/' element={<Home/>} />
                <Route path='/events' element={<Event/>} />
                <Route path='/events/:id' element={<EventSingle />} />
                <Route path='/metrics/:id' element={<MetricSingle />} />
                <Route path='/metrics/source/:id' element={<MetricSourceMore />} />
                <Route path='/region/metrics/:id' element={<RegionalMetricSingle />} />
                <Route path='/region/metrics/source/:id' element={<RegionalMetricSourceMore />} />
                <Route path='/articles' element={<Article/>} />
                <Route path='/download' element={<Download/>} />
                <Route path='/about' element={<About/>} />
                <Route path='/privacypolicy' element={<PrivacyPolicy/>} />
                <Route path='/contactus' element={<ContactUs/>} />
                <Route path='/project/:id' element={<ProjectSingle />} />
                {/* Media paths */}
                <Route path='/media/photos' element={<PhotoAll />} />
                <Route path='/media/photos/:id' element={<PhotoSingle />} />
                <Route path='/media/videos' element={<MediaVideosDirect />} />
                <Route path='/media/videos/:id' element={<VideoSingleDirect />} />
                <Route path='/media/audios' element={<MediaAudiosDirect />} />
                <Route path='/media' element={<MediaMain headerName={'Media'} />}>
                {/* <Route path='/media/photos' element={<MediaPhotosDirect />} /> */}
                </Route>
                {/* Sector paths */}
                <Route path='/sector/:id' element={<SectorMain />}>
                    {/* <Route path='/sector/:id' element={<SectorIntro />} /> */}
                    <Route path='/sector/:id' element={<SectorMetrics />} />
                    <Route path='/sector/:id/metrics' element={<SectorMetrics />} />
                    <Route path='/sector/:id/categories' element={<SectorCategoriesB4 />} />
                    {/* <Route path='/sector/:id/categories' element={<SectorCategories />} /> */}
                    <Route path='/sector/:id/photos' element={<SectorPhotos />} />
                    <Route path='/sector/:id/videos' element={<SectorVideos />} />
                    <Route path='/sector/:id/audios' element={<SectorAudios />} />
                    <Route path='/sector/:id/reports' element={<SectorReports />} />
                    <Route path='/sector/:id/articles' element={<SectorArticles />} />
                </Route>
                {/* Category paths */}
                <Route path='/category/:id' element={<CategoryMain />}>
                    {/* <Route path='/category/:id' element={<CategoryIntro />} /> */}
                    {/* <Route path='/category/:id' element={<CategoryProjects />} /> */}
                    <Route path='/category/:id' element={<CategoryMetrics />} />
                    <Route path='/category/:id/projects' element={<CategoryProjects />} />
                    <Route path='/category/:id/projectsmap' element={<CategoryProjectsMap />} />
                    <Route path='/category/:id/articles' element={<CategoryArticles />} />
                    <Route path='/category/:id/photos' element={<CategoryPhotos />} />
                    <Route path='/category/:id/videos' element={<CategoryVideos />} />
                    <Route path='/category/:id/audios' element={<CategoryAudios />} />
                    <Route path='/category/:id/metrics' element={<CategoryMetrics />} />
                    <Route path='/category/:id/reports' element={<CategoryReports />} />

                    <Route path='/category/:id/projsummary' element={<CatProjectSummary />} />
                    <Route path='/category/:id/projectno/:pno/projdetail' element={<CatProjectDetail />} />
                </Route>
                {/* Ministry paths */}
                <Route path='/ministry/:id' element={<MinistryMain />}>
                    {/* <Route path='/ministry/:id' element={<MinistryIntro />} /> */}
                    <Route path='/ministry/:id' element={<MinistryMetrics />} />
                    <Route path='/ministry/:id/metrics' element={<MinistryMetrics />} />
                    <Route path='/ministry/:id/projects' element={<MinistryProjects />} />
                    <Route path='/ministry/:id/projectsmap' element={<MinistryProjectsMap />} />
                    <Route path='/ministry/:id/categories' element={<MinistryCategories />} />
                    <Route path='/ministry/:id/photos' element={<MinistryPhotos />} />
                    <Route path='/ministry/:id/videos' element={<MinistryVideos />} />
                    <Route path='/ministry/:id/audios' element={<MinistryAudios />} />
                    <Route path='/ministry/:id/reports' element={<MinistryReports />} />
                    <Route path='/ministry/:id/articles' element={<MinistryArticles />} />
                    <Route path='/ministry/:id/projsummary' element={<MinProjectSummary />} />
                    <Route path='/ministry/:id/projectno/:pno/projdetail' element={<MinProjectDetail />} />
                </Route>
                                                
                <Route path="*" element={<ErrorPage />} />
                {/* Region paths */}
                <Route path='/region/:id' element={<RegionMain />}>
                    {/* <Route path='/region/:id' element={<RegionIntro />} /> */}
                    <Route path='/region/:id' element={<RegionProjects />} />
                    <Route path='/region/:id/projects' element={<RegionProjects />} />
                    <Route path='/region/:id/projectsmap' element={<RegionProjectsMap />} />
                    <Route path='/region/:id/categories' element={<RegionCategories />} />
                    <Route path='/region/:id/articles' element={<RegionArticles />} />
                    <Route path='/region/:id/photos' element={<RegionPhotos />} />
                    <Route path='/region/:id/videos' element={<RegionVideos />} />
                    <Route path='/region/:id/audios' element={<RegionAudios />} />
                    <Route path='/region/:id/metrics' element={<RegionMetrics />} />
                    <Route path='/region/:id/reports' element={<RegionReports />} />
                    <Route path='/region/:id/mmdas' element={<RegionMmda />} />
                    <Route path='/region/:id/projsummary' element={<RegProjectSummary />} />
                    {/* <Route path='/region/:id/projdetail' element={<RegProjectDetail />} /> */}
                    <Route path='/region/:id/projectno/:pno/projdetail' element={<RegProjectDetail />} />
                </Route>
                <Route path='/mmda/:id' element={<MmdaMain />}>
                    <Route path='/mmda/:id' element={<MmdaProjects />} />
                    <Route path='/mmda/:id/projects' element={<MmdaProjects />} />
                    <Route path='/mmda/:id/projectsmap' element={<MmdaProjectsMap />} />
                    <Route path='/mmda/:id/photos' element={<MmdaPhotos />} />
                    <Route path='/mmda/:id/videos' element={<MmdaVideos />} />
                    <Route path='/mmda/:id/audios' element={<MmdaAudios />} />
                    <Route path='/mmda/:id/metrics' element={<MmdaMetrics />} />
                    <Route path='/mmda/:id/reports' element={<MmdaReports />} />
                    <Route path='/mmda/:id/articles' element={<MmdaArticles />} />
                    <Route path='/mmda/:id/projsummary' element={<MmdaProjectSummary />} />
                    <Route path='/mmda/:id/projectno/:pno/projdetail' element={<MmdaProjectDetail />} />
                </Route>
                {/* Other paths */}
                <Route path='/sectors' element={<SectorAll />} />
                <Route path='/categories' element={<CategoryAll />} />
                <Route path='/regions' element={<RegionAll />} />
                <Route path='/ministries' element={<MinistryAll />} />
                <Route path='/projectsearch' element={<ProjectSearch />} />
                <Route path='/projectsummary' element={<AllProjectSummary />} />
                <Route path='/projectno/:pno/projdetail' element={<AllProjectDetail />} />
                <Route path='/advancesearch' element={<AdvanceSearch />} />
                <Route path='/projects' element={<ProjectAll />} />
                <Route path='/regions' element={<RegionAll />} />
                <Route path='/account/acceptphoneno' element={<AcceptPhoneno />} />
                <Route path='/account/acceptotp' element={<AcceptOtp />} />
            </Routes>
        </React.Suspense>
    );
}
export default Main;