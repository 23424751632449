import { useEffect, useState } from 'react';
import http from '../../lib/Http';
import { PhotoCard } from '../../components/cards/PhotoCard';
import { useParams } from 'react-router-dom';
import PhotoItems from '../../components/pagination/PhotoItems';
import ReactPaginate from 'react-paginate';

const PhotoAll = () => {
    const [photos, setPhotos] = useState<Photo[]>([]);
    var {id} = useParams();

    function PaginatedItems({ itemsPerPage }: {itemsPerPage: any}) {
        const [itemOffset, setItemOffset] = useState(0);
        const endOffset = itemOffset + itemsPerPage;
        const currentItems = photos.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(photos.length / itemsPerPage);
      
        // Invoke when user click to request another page.
        const handlePageClick = (event: any) => {
          const newOffset = (event.selected * itemsPerPage) % photos.length;
          setItemOffset(newOffset);
        };
      
        return (
          <>
            <PhotoItems currentItems={currentItems} />
            <div  className='row'>
              <nav aria-label="Page navigation example">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  className='pagination'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'

                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                />
              </nav>
            </div>
          </>
        );
    }

    useEffect(() => {
        // http.get<Photo>(`/Photo/${id}`)
        http.get<Photo[]>(`/Photo/approved/all`)
            .then((res) => {
                setPhotos(res.data);
            });
    }, [])
    
    return <>        
        <div className='container'>
        <div className='row'>
            <PaginatedItems itemsPerPage={12} />
        </div>
        </div>
    </>;
}

export default PhotoAll;