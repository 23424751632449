import { useEffect, useState } from 'react';
import '../../components/UtilStyles.css';
import http from '../../lib/Http';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../components/loaders/LoadingSpinner';
import OpenMapComponent from '../../components/OpenMapComponent';

const MmdaProjectsMap = () => {
    const {id} = useParams();
    const [projects, setProjects] = useState<Project[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [selectedProj, setSelectedProj] = useState<Project | undefined | null>(null)
    
    useEffect(() => {
        const fetchCategories = () => {
            const requests = [
                `Project/mmda/${id}`,
            ].map(url => http.get(url));

            axios.all(requests)
                .then(
                    axios.spread((projs, ) => {
                        setProjects(projs.data);                        
                        setIsLoading(false)
                    })
                )
                .catch(err => console.log(err))
        }
        fetchCategories();
    }, [])

    return (
        <div>
            <div>&nbsp;</div>
            {isLoading ? (<>
                    <div className='row'>
                        <LoadingSpinner />
                    </div>
                </>) : (<>
                    <OpenMapComponent projects={projects} />
                </>)}
                <div>&nbsp;</div>
        </div>
    );
}
export default MmdaProjectsMap;